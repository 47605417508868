import React from 'react'
import Animated2 from '../v2/components/Animated/Animated2'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import bg9 from '../assets/kurz2024/bg9.png'
import k4img4 from '../assets/kurz2024/k4img4.png'
import k4img5 from '../assets/kurz2024/k4img5.png'
import fonts from '../styles/fonts'
import Box from '@mui/material/Box'
import animation2lottie from '../assets/kurz2024/EXPORT/K04/K04_03/K04-03-00.json'
import animation3lottie from '../assets/kurz2024/EXPORT/K04/K04_04/K04-04-01-Smaltovňa.json'
import Animated3 from '../v2/components/Animated/Animated3'
import Strip2 from '../components/Strip2/Strip2'

import Animated3Nav2 from '../v2/components/Animated/Animated3Nav2'

import voiceover15sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.4.1.mp3'
import voiceover15en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.1.mp3'

import k4img0402 from '../assets/kurz2024/k4img0402.png'
import k4img0402main from '../assets/kurz2024/k4img0402main.png'
import k4img0402secondary from '../assets/kurz2024/k4img0402secondary.png'
import k4img0402float1 from '../assets/kurz2024/k4img0402float1.png'
import k4img0402float2 from '../assets/kurz2024/k4img0402float2.png'
import k4img0402float3 from '../assets/kurz2024/k4img0402float3.png'
import voiceover16sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.4.2.mp3'
import voiceover16en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.2.mp3'

import k4img0403 from '../assets/kurz2024/k4img0403.png'
import k4img0403main from '../assets/kurz2024/k4img0403main.png'
import k4img0403secondary from '../assets/kurz2024/k4img0403secondary.png'
import k4img0403float1 from '../assets/kurz2024/k4img0403float1.png'
import k4img0403float2 from '../assets/kurz2024/k4img0403float2.png'
import k4img0403float3 from '../assets/kurz2024/k4img0403float3.png'
import voiceover17sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.4.3.mp3'
import voiceover17en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.3.mp3'

import k4img0404 from '../assets/kurz2024/k4img0404.png'
import k4img0404main from '../assets/kurz2024/k4img0404main.png'
import k4img0404secondary from '../assets/kurz2024/k4img0404secondary.png'
import k4img0404float1 from '../assets/kurz2024/k4img0404float1.png'
import k4img0404float2 from '../assets/kurz2024/k4img0404float2.png'
import k4img0404float3 from '../assets/kurz2024/k4img0404float3.png'
import voiceover18sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.4.4.mp3'
import voiceover18en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.4.mp3'

import k4img0405 from '../assets/kurz2024/k4img0405.png'
import k4img0405main from '../assets/kurz2024/k4img0405main.png'
import k4img0405secondary from '../assets/kurz2024/k4img0405secondary.png'
import k4img0405float1 from '../assets/kurz2024/k4img0405float1.png'
import k4img0405float2 from '../assets/kurz2024/k4img0405float2.png'
import voiceover19sk from '../assets/kurz2024/VOICEOVER/oprava 4_4_5 a 4_4_6 SK.mp3'
import voiceover19en from '../assets/kurz2024/VOICEOVER/oprava 4_4_5 a 4_4_6 ENG.mp3'

import k0403trigger01img1 from '../assets/kurz2024/k0403trigger01img1.png'
import k0403trigger01img2 from '../assets/kurz2024/k0403trigger01img2.png'
import k0403trigger01img3 from '../assets/kurz2024/k0403trigger01img3.png'
import k0403trigger01secondaryImg1 from '../assets/kurz2024/k0403trigger01secondaryImg1.png'
import k0403trigger01secondaryImg2 from '../assets/kurz2024/k0403trigger01secondaryImg2.png'
import k0403trigger01voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.3.1.mp3'
import k0403trigger01voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.3.1.mp3'

import k0403trigger02img1 from '../assets/kurz2024/k0403trigger02img1.png'
import k0403trigger02img2 from '../assets/kurz2024/k0403trigger02img2.png'
import k0403trigger02img3 from '../assets/kurz2024/k0403trigger02img3.png'
import k0403trigger02secondaryImg1 from '../assets/kurz2024/k0403trigger02secondaryImg1.png'
import k0403trigger02secondaryImg2 from '../assets/kurz2024/k0403trigger02secondaryImg2.png'
import k0403trigger02voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.3.2.mp3'
import k0403trigger02voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.3.2.mp3'

import k0403trigger03img1 from '../assets/kurz2024/k0403trigger03img1.png'
import k0403trigger03img2 from '../assets/kurz2024/k0403trigger03img2.png'
import k0403trigger03img3 from '../assets/kurz2024/k0403trigger03img3.png'
import k0403trigger03voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.3.3.mp3'
import k0403trigger03voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.3.3.mp3'

const Text = ({ children, secondColor }) => {
  return (
    <Box
      sx={{
        ...fonts.Urbanist['32px']({
          color: '#fff',
          fontWeight: '500',
          lineHeight: 40,
        }),
        '& b': {
          color: secondColor,
        },
      }}
    >
      {children}
    </Box>
  )
}

const Text2 = ({ children, secondColor = '#6936E5', sx }) => {
  return (
    <Box
      sx={{
        ...fonts.Urbanist['36px']({
          color: '#000',
          fontWeight: '500',
          lineHeight: 40,
        }),
        '& b': {
          color: secondColor,
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

const title2 = {
  sk: <>BUDOVA IKEA - VIEDEŇ - RAKÚSKO</>,
  en: <>IKEA building - Vienna - Austria</>,
}

const triggers2 = [
  {
    id: 'triggers2-1',
    top: '54%',
    left: '25%',
    photos: [k0403trigger01img1, k0403trigger01img2, k0403trigger01img3],
    sources: [
      {
        sk: 'zdroj: www1.pluska.sk',
        en: 'source: www1.pluska.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
    ],
    voiceover: {
      sk: k0403trigger01voiceoverSK,
      en: k0403trigger01voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0403trigger01secondaryImg1}
              sx={{
                width: '6vw',
              }}
            />

            <Box
              component={'img'}
              src={k0403trigger01secondaryImg2}
              sx={{
                width: '6vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '480px',
            }}
          >
            Mesto Košice sa stalo v roku 2014{' '}
            <b>
              Hlavným európskym mestom kultúry a pri tej príležitosti sa v
              Košiciach
            </b>{' '}
            zrekonštruovalo viacero budov a objektov na kultúrne účely.{' '}
            <b>
              Jednou z nich bol aj komplex bývalých kasární, ktoré dlhé roky
              chátral.
            </b>
          </Text2>
        </>
      ),
      en: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0403trigger01secondaryImg1}
              sx={{
                width: '6vw',
              }}
            />

            <Box
              component={'img'}
              src={k0403trigger01secondaryImg2}
              sx={{
                width: '6vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '480px',
            }}
          >
            The city of Košice became{' '}
            <b>
              European Capital of Culture in 2014 and on that occasion a number
              of buildings and objects were renovated in Košice
            </b>{' '}
            for cultural purposes.{' '}
            <b>
              One of them was the complex of former barracks, which had been
              dilapidated for many years.
            </b>
          </Text2>
        </>
      ),
    },
  },
  {
    id: 'triggers2-2',
    top: '38%',
    left: '42%',
    photos: [k0403trigger02img1, k0403trigger02img2, k0403trigger02img3],
    sources: [
      {
        sk: 'zdroj: arkatelier.sk',
        en: 'source: arkatelier.sk',
      },
      {
        sk: 'zdroj: www.doprastav.sk',
        en: 'source: www.doprastav.sk',
      },
      {
        sk: 'zdroj: www.doprastav.sk',
        en: 'source: www.doprastav.sk',
      },
    ],
    voiceover: {
      sk: k0403trigger02voiceoverSK,
      en: k0403trigger02voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0403trigger02secondaryImg1}
              sx={{
                width: '4vw',
              }}
            />

            <Box
              component={'img'}
              src={k0403trigger02secondaryImg2}
              sx={{
                width: '4vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '500px',
            }}
          >
            <b>
              Mesto vypísalo <br /> architektonickú súťaž <br /> a vybralo
              najlepší <br />
              architektonický návrh <br />
            </b>{' '}
            a podľa neho sa kasárne zrekonštruovali. Keďže išlo <br /> o
            pamiatkovo chránené <br /> budovy, rekonštrukcia <br /> prebiehala
            pod prísnym <br />
            dohľadom pamiatkarov.
          </Text2>
        </>
      ),
      en: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0403trigger02secondaryImg1}
              sx={{
                width: '6vw',
              }}
            />

            <Box
              component={'img'}
              src={k0403trigger02secondaryImg2}
              sx={{
                width: '6vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '500px',
            }}
          >
            <b>
              The city launched an architectural competition and selected the
              best architectural design
            </b>{' '}
            and the barracks were reconstructed according to it. As these were
            listed buildings, the reconstruction was carried out under the
            strict supervision of the conservation officers.
          </Text2>
        </>
      ),
    },
  },
  {
    id: 'triggers2-3',
    top: '52%',
    left: '64.4%',
    photos: [k0403trigger03img1, k0403trigger03img2, k0403trigger03img3],
    sources: [
      {
        sk: 'zdroj: www.kosiceregion.com',
        en: 'source: www.kosiceregion.com',
      },
      {
        sk: 'zdroj: www.teraz.sk',
        en: 'source: www.teraz.sk',
      },
      {
        sk: 'zdroj: www.teraz.sk',
        en: 'source: www.teraz.sk',
      },
    ],
    voiceover: {
      sk: k0403trigger03voiceoverSK,
      en: k0403trigger03voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <Text2
          sx={{
            maxWidth: '490px',
          }}
        >
          <b>
            Zrekonštruovali sa nielen budovy, ale aj okolité územie, vznikol
            park a námestie,
          </b>{' '}
          ktoré Košičania využívajú na rôzne kultúrne akcie. Kultur Park je
          príkladom toho, ako sa dajú{' '}
          <b>
            staré historické a nefunkčné budovy pekne obnoviť a prinavrátiť do
            nich nový život.
          </b>{' '}
          Kultur park získal mnohé architektonické ocenenia na Slovensku a
          dokonca aj v zahraničí.
        </Text2>
      ),
      en: (
        <Text2
          sx={{
            maxWidth: '490px',
          }}
        >
          <b>
            Not only the buildings were renovated, but also the surrounding
            area, a park and a square
          </b>{' '}
          were created, which is used by the inhabitants of Košice for various
          cultural events. Kultur Park is an example of how
          <b>
            old historical and dysfunctional buildings can be beautifully
            restored and given a new lease of life.
          </b>{' '}
          Kultur Park has won many architectural awards in Slovakia and even
          abroad.
        </Text2>
      ),
    },
  },
]

const chapter4 = {
  url: '/chapter-4_2',
  name: 'chapter4_2',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 3,
        bg: bg9,
        img: k4img4,
        imgWidth: '35%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span>Kulturpark, </span> Košice <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architekt Erakli Eristavi a kolektív
              </Box>
            </>
          ),
          en: (
            <>
              <span>Kulturpark, </span> Košice <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architect Erakli Eristavi and team
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Strip2,
      props: {
        header: {
          sk: (
            <>
              Kulturpark, Košice <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                architekt Erakli Eristavi a kolektív
              </Box>
            </>
          ),
          en: (
            <>
              Kulturpark, Košice <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                architect Erakli Eristavi and team
              </Box>
            </>
          ),
        },
        animationLottie: animation2lottie,
        triggers: triggers2,
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 3,
        bg: bg9,
        img: k4img5,
        imgWidth: '35%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span>Smaltovňa, </span> Lučenec <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                ateliér GutGut
              </Box>
            </>
          ),
          en: (
            <>
              <span>Enamel factory, </span> Lučenec <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                GutGut studio
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Animated2,
      props: {
        animationLottie: animation3lottie,
        voiceover: {
          sk: voiceover15sk,
          en: voiceover15en,
        },
        text: {
          sk: 'Súčasná podoba bývalej starej smaltovne v Lučenci je ukážkou príkladnej konverzie bývalého priemyselného areálu na plnohodnotnú súčasť širšieho centra mesta Lučenec.',
          en: 'The current appearance of the former old enamel factory in Lučenec is an example of an exemplary conversion of a former industrial site into a fully-fledged part of the wider Lučenec town centre.',
        },
        header: {
          sk: (
            <>
              Smaltovňa, Lučenec <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                ateliér GutGut
              </Box>
            </>
          ),
          en: (
            <>
              Enamel factory Lučenec <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                GutGut studio
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav2,
        activeSlide: 0,
        bg: k4img0402,
        mainImg: k4img0402main,
        secondaryImg: k4img0402secondary,
        floatingImages: [
          {
            img: k4img0402float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0402float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0402float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '19.–20. storočie',
        voiceover: {
          sk: voiceover16sk,
          en: voiceover16en,
        },
        description: {
          sk: 'Vnútroblok smaltovne',
          en: 'The inner block of the enamel plant',
        },
        source: {
          sk: 'zdroj: www.smaltovnalucenec.sk',
          en: 'source: www.smaltovnalucenec.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#EECB85'}>
              Bývalá Rakottyaova smaltovňa bola postavená na prelome 19. a v
              prvej polovici 20. storočia a 
              <b>
                stala sa významným producentom v regióne aj v celom
                Rakúsko-Uhorsku.
              </b>
            </Text>
          ),
          en: (
            <Text secondColor={'#EECB85'}>
              The former Rakottya enamel factory was built at the turn of the
              19th century and in the first half of the 20th century and{' '}
              <b>
                became an important producer in the region and throughout the
                Austro-Hungarian Empire.
              </b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav2,
        activeSlide: 1,
        bg: k4img0403,
        mainImg: k4img0403main,
        secondaryImg: k4img0403secondary,
        floatingImages: [
          {
            img: k4img0403float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0403float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0403float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '',
        voiceover: {
          sk: voiceover17sk,
          en: voiceover17en,
        },
        description: {
          sk: 'Rakottyaova smaltovňa',
          en: "Rakottya's Enamel Factory",
        },
        source: {
          sk: 'zdroj: www.smaltovnalucenec.sk',
          en: 'source: www.smaltovnalucenec.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#EECB85'}>
              Smaltovaný riad so znakom leva exportovala do celej Európy. Pre
              svoje mesto bola dôležitá z dvoch hľadísk. Z jednej strany ako
              objekt, ktorý dotváral panorámu mesta a z druhej strany ako{' '}
              <b>popredný zamestnávateľ, kde pracovalo vyše 1000 robotníkov.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#EECB85'}>
              It exported enamelware with the lion emblem to the whole of
              Europe. It was important to its city in two respects. On the one
              hand as a building that completed the cityscape and on the other
              hand as a <b>leading employer, employing over 1,000 workers.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav2,
        activeSlide: 2,
        bg: k4img0404,
        mainImg: k4img0404main,
        secondaryImg: k4img0404secondary,
        floatingImages: [
          {
            img: k4img0404float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0404float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0404float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '',
        voiceover: {
          sk: voiceover18sk,
          en: voiceover18en,
        },
        description: {
          sk: 'Rekonštruovaná časť smaltovne',
          en: 'Reconstructed part of the enamel factory',
        },
        source: {
          sk: 'zdroj: www.smaltovnalucenec.sk',
          en: 'source: www.smaltovnalucenec.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#4EF993'}>
              Aj keď už areál dlho neslúžil pôvodnej funkcii, názov Smaltovňa
              používajú Lučenčania dodnes. A vyzerá, že ho používať ešte dlho
              budú. Chátrajúci priemyselný{' '}
              <b>
                areál prešiel konverziou a ožíva v spojení s pôvodným menom.
                Výrobu v areáli nahradil kreatívny priemysel.
              </b>
            </Text>
          ),
          en: (
            <Text secondColor={'#4EF993'}>
              Although the site has not served its original function for a long
              time, the name Enamel factory is still used by the people of
              Lučenec to this day. And it seems that they will continue to use
              it for a long time to come. The dilapidated industrial site has
              undergone conversion and is reviving in connection with its
              original name. Production in the{' '}
              <b>area has been replaced by creative industry.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav2,
        showArrows: true,
        backButtonSrc: '/chapter-4_2?slide=3',
        activeSlide: 3,
        bg: k4img0405,
        mainImg: k4img0405main,
        secondaryImg: k4img0405secondary,
        floatingImages: [
          {
            img: k4img0405float1,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0405float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '',
        voiceover: {
          sk: voiceover19sk,
          en: voiceover19en,
        },
        description: {
          sk: 'Interiér smaltovne',
          en: 'The interior of the enamel shop',
        },
        source: {
          sk: 'zdroj: www.smaltovnalucenec.sk',
          en: 'source: www.smaltovnalucenec.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#4EF993'}>
              <b>V priestoroch bývalej smaltovne sú dnes firemné priestory,</b>{' '}
              coworking, reštaurácia, a priestory pre rôzne kultúrne
              a spoločenské eventy. Ateliéru GutGut sa podarilo zachovať
              pestrosť a rôznorodosť jednotlivých historických vrstiev.
              <b>
                Výsledkom je integrácia dnešného aj minulého do celistvého a
                fungujúceho riešenia.
              </b>
            </Text>
          ),
          en: (
            <Text secondColor={'#4EF993'}>
              <b>The former enamel factory now houses corporate offices,</b>{' '}
              coworking space, a restaurant, and spaces for various cultural and
              social events. The GutGut studio has managed to preserve the
              variety and diversity of the individual historical layers.{' '}
              <b>
                The result is an integration of the present and the past into a
                coherent and functioning solution.
              </b>
            </Text>
          ),
        },
      },
    },
  ],
}

export default chapter4
