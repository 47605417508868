import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../Slider/SliderWrapper'
import SlickSlider from 'react-slick'
import img1 from '../../v2/assets/PROTOTYP/KVIZ/KVIZ-0TAZKA-8.jpg'
import img2 from '../../v2/assets/PROTOTYP/KVIZ/KVIZ-0TAZKA-11.jpg'
import img3 from '../../v2/assets/PROTOTYP/KVIZ/KVIZ-0TAZKA-14.jpg'
import { useQuizContext } from '../../contexts/QuizContext'
import correctVideo from '../../v2/assets/PROTOTYP/KVIZ/quiz-correct.webm'
import incorrectVideo from '../../v2/assets/PROTOTYP/KVIZ/quiz-wrong.webm'
import correctVideoSafari from '../../v2/assets/PROTOTYP/KVIZ/quiz-correct.mov'
import incorrectVideoSafari from '../../v2/assets/PROTOTYP/KVIZ/quiz-wrong.mov'
import lottieIncorrect from '../../assets/kurz2024/EXPORT/K06 Kvíz/shape nespravne.json'
import ReactPlayer from 'react-player'
import useSound from 'use-sound'
import correctSound from '../../assets/_Sfx/__Compress/Q_CorrectAnswer.mp3'
import incorrectSound from '../../assets/_Sfx/__Compress/Q_IncorrectAnswer.mp3'
import ButtonMenu from '../../components/Buttons/ButtonMenu'
import { isSafari } from 'react-device-detect'
import quizImg1 from '../../assets/kurz2024/quiz1.png'
import quizImg2 from '../../assets/kurz2024/quiz2.png'
import quizImg3 from '../../assets/kurz2024/quiz3.png'
import quizImg4 from '../../assets/kurz2024/quiz4.png'
import quizImg5 from '../../assets/kurz2024/quiz5.png'
import quizImg6 from '../../assets/kurz2024/quiz6.png'
import quizImg7 from '../../assets/kurz2024/quiz7.png'
import quizImg8 from '../../assets/kurz2024/quiz8.png'
import quizImg9 from '../../assets/kurz2024/quiz9.png'
import quizImg10 from '../../assets/kurz2024/quiz10.png'
import quizImg11 from '../../assets/kurz2024/quiz11.png'
import quizImg12 from '../../assets/kurz2024/quiz12.png'
import QuizQuestion2 from './QuizQuestion2'
import quizProgress from '../../assets/kurz2024/quiz-progress.svg'
import quizDot from '../../assets/kurz2024/quiz-dot.svg'
import Lottie from 'react-lottie'

const _correctVideo = isSafari ? correctVideoSafari : correctVideo
const _incorrectVideo = isSafari ? incorrectVideoSafari : incorrectVideo

const Quiz = ({ language, goToNextSlide }) => {
  const [playCorrect] = useSound(correctSound, {
    interrupt: true,
  })
  const [playIncorrect] = useSound(incorrectSound, {
    interrupt: true,
  })

  const { setCorrectAnswers } = useQuizContext()
  const sliderRef = useRef()
  const [activeSlide, setActiveSlide] = useState(0)
  const [answers, setAnswers] = useState({})
  const [url, setUrl] = useState(_correctVideo)
  const [isPlaying, setIsPlaying] = useState(false)
  // const [isLast, setIsLast] = useState(false)
  const [isShownLottie, setIsShownLottie] = useState(false)

  const questions = [
    {
      img: quizImg1,
      question: {
        sk: (
          <>
            1. Kedy hovoríme o konverzii <br /> budovy?
          </>
        ),
        en: (
          <>
            1. When do we talk about converting <br /> a building?
          </>
        ),
      },
      options: {
        sk: [
          'O konverzii hovoríme vtedy, keď ide o opravu budovy v konzervatívnom štýle.',
          <>
            O konverziu ide vtedy, ak sa historická budova, ktorá už prestávala
            slúžiť svojmu účelu a chátra, obnoví, pričom sa úplne zmení jej
            funkčná náplň.
          </>,
          'Konverzia budovy znamená, že sa k pôvodnej historickej časti pristavia kovové konštrukcie.',
        ],
        en: [
          'We speak of conversion when the building is renovated in a conservative style.',
          'Conversion is when a historic building that has outlived its purpose and is falling into disrepair is restored with a complete change in its function.',
          'Conversion of a building means that metal structures are added to the original historic section.',
        ],
      },
      rightAnswer: [1],
    },
    {
      img: quizImg2,
      question: {
        sk: '2. Ako sa volá pôvodný autor  budovy, ktorú vidíte na fotke?',
        en: '2. What is the name of the original author  of the building you see in the photo?',
      },
      options: {
        sk: ['Daniel Jurkovič', 'Dušan Jurkovič', 'Juraj Durkovič'],
        en: ['Daniel Jurkovic', 'Dusan Jurkovic', 'Juraj Durkovič'],
      },
      rightAnswer: [1],
    },
    {
      img: quizImg3,
      question: {
        sk: '3. Čoho príkladom je budova Pradiarne 1900?',
        en: '3. What is the Spinning mill 1900 building an example of?',
      },
      options: {
        sk: [
          'Je príkladom adaptácie budovy',
          'Je príkladom rekonštrukcie budovy',
          'Je príkladom konverzie budovy',
        ],
        en: [
          'It is an example of building adaptation',
          'It is an example of the building reconstruction',
          'It is an example of a building conversion',
        ],
      },
      rightAnswer: [2],
    },
    {
      img: quizImg4,
      question: {
        sk: '4. Čo znamená pojem rekonštrukcia budov?',
        en: '4. What does the term building reconstruction mean?',
      },
      options: {
        sk: [
          'O rekonštrukcii hovoríme vtedy, ak budovu uvedieme do pôvodného stavu, s  cieľom odstrániť účinky jej opotrebovania, pričom budova bude ďalej slúžiť svojmu pôvodnému účelu.',
          'Rekonštrukcia znamená likvidácia starej budovy bez ďalších stavebných prác.',
          'Rekonštrukcia je výstavba novej budovy na miesto starej.',
        ],
        en: [
          'We speak of reconstruction when we bring a building back to its original state in order to remove the effects of its wear and tear, while the building continues to serve its original purpose.',
          'Reconstruction means the disposal of the old building without further construction work.',
          'Reconstruction is the construction of a new building to replace the old one.',
        ],
      },
      rightAnswer: [0],
    },
    {
      img: quizImg5,
      question: {
        sk: '5. Na aké účely sa využíva Smaltovňa Lučenec po konverzii?',
        en: '5. For what purposes is Enamel factory Lučenec used after the conversion?',
      },
      options: {
        sk: [
          'Ako múzeum smaltovaného riadu so znakom leva.',
          'Firemné priestory, coworking, reštaurácia, a priestory pre rôzne kultúrne a spoločenské eventy.',
          ' Sídlo radnice a primátora mesta.',
        ],
        en: [
          'As a museum of enamelware with the lion emblem.',
          'Business premises, coworking, restaurant, and premises for various cultural and social events.',
          'The seat of the City Council and the Mayor.',
        ],
      },
      rightAnswer: [1],
    },
    {
      img: quizImg6,
      question: {
        sk: '6. Na čo pôvodne slúžila budova Spilka v Bratislave?',
        en: '6. What was the Spilka building in Bratislava originally used for?',
      },
      options: {
        sk: [
          'Bol to areál pivovaru Stein.',
          'Boli to obchodné priestory.',
          'Bol to priemyseľný areál.',
        ],
        en: [
          'It was the site of the Stein brewery.',
          'They were commercial premises.',
          'It was an industrial area.',
        ],
      },
      rightAnswer: [0],
    },
    {
      img: quizImg7,
      question: {
        sk: '7. Koľko veží ma budova Skladu 7',
        en: '7. How many towers are in the Warehouse 7 building',
      },
      options: {
        sk: ['2', '4', '3'],
        en: ['2', '4', '3'],
      },
      image: img1,
      rightAnswer: [2],
    },
    {
      img: quizImg8,
      question: {
        sk: '8. Ako sa volá významný slovenský architekt, ktorý navrhol pôvodnú budovu SNG v Bratislave?',
        en: '8. What is the name of the prominent Slovak architect who designed the original SNG building in Bratislava?',
      },
      options: {
        sk: ['Ferdinand Milučký', 'Vladimír Dedeček', 'Emil Belluš'],
        en: ['Ferdinand Milucky', 'Vladimír Dedeček', 'Emil Belluš'],
      },
      rightAnswer: [1],
    },
    {
      img: quizImg9,
      question: {
        sk: '9. Ako sa volá budova na obrázku?',
        en: '9. What is the name of the building in the picture?',
      },
      options: {
        sk: [
          'Centre George Pompideu v Paríži',
          'Tržnica Carreau du Temple v Paríži',
          'Halles de Paris',
        ],
        en: [
          'Centre George Pompideu in Paris',
          'Carreau du Temple market in Paris',
          'Halles de Paris',
        ],
      },
      rightAnswer: [1],
    },
    {
      img: quizImg10,
      question: {
        sk: '10. Čo je modernizácia budovy?',
        en: '10. What is a building modernisation?',
      },
      options: {
        sk: [
          'Modernizácia je zmena exteriéru budovy bez úprav vnútorných priestorov.',
          'Modernizácia je pridanie nových podlaží k existujúcej budove.',
          'Modernizácia znamená, že časť stavebných konštrukcií a vybavenia budov sa nahrádza modernejšími stavebnými konštrukciami a technológiami.',
        ],
        en: [
          'Modernisation is a change to the exterior of a building without alterations to the interior spaces.',
          'Modernisation is the addition of new floors to an existing building.',
          'Modernisation means that part of the building structure and equipment is replaced by more modern building structures and technologies.',
        ],
      },
      rightAnswer: [2],
    },
    {
      img: quizImg11,
      question: {
        sk: '11. Kto stojí za rekonštrukciou technologicko-kultúrneho parku Lavrion v Grécku?',
        en: '11. Who is behind the reconstruction of the Lavrion Technological and Cultural Park in Greece?',
      },
      options: {
        sk: [
          'Národná technická univerzita v Athénach',
          'Architektonické štúdiou Milou Architecture',
          'Ministerstvo kultúry',
        ],
        en: [
          'National Technical University of Athens',
          'Architectural studio Milou Architecture',
          'Ministry of Culture',
        ],
      },
      image: img2,
      rightAnswer: [0],
    },
    {
      img: quizImg12,
      question: {
        sk: '12. Na čo slúžila pôvodná budova galérie Tate Modern v Londýne?',
        en: '12. What was the original Tate Modern gallery building in London used for?',
      },
      options: {
        sk: ['Elektráreň', 'Vodáreň', 'Baňa'],
        en: ['Power Plant', 'Waterworks', 'Mine'],
      },
      rightAnswer: [0],
    },
  ]

  const isLast = activeSlide === questions.length - 1

  const settings = {
    dots: false,
    fade: true,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    draggable: false,
    touchMove: false,
    initialSlide: 0,
    afterChange: setActiveSlide,
  }

  const onAnswer = (index, answer) => {
    setAnswers((prevState) => ({
      ...prevState,
      [index]: answer,
    }))

    const isCorrect = questions[index].rightAnswer.includes(answer)

    if (isCorrect) {
      setUrl(isCorrect ? _correctVideo : _incorrectVideo)

      setIsPlaying(true)
    } else {
      setIsShownLottie(true)
      playIncorrect()

      setTimeout(() => {
        if (isLast) {
          const correctAnswers = questions.filter((question, index) => {
            return question.rightAnswer.includes(answers[index])
          })

          setCorrectAnswers(correctAnswers.length)
          setAnswers({})
          setActiveSlide(0)
          // setIsLast(false)
          setIsShownLottie(false)
          sliderRef.current?.slickGoTo(0)
          goToNextSlide()
        } else {
          sliderRef.current?.slickNext()
          setIsShownLottie(false)
        }
      }, 2000)
    }

    // isCorrect ? playCorrect() : playIncorrect()
  }

  useEffect(() => {
    if (Number.isInteger(answers[questions.length - 1])) {
      // setIsLast(true)
    }
  }, [answers])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieIncorrect,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <SliderWrapper
      sx={{
        background: 'linear-gradient(76deg, #6936C5 3.42%, #5C2A93 97.76%)',
      }}
    >
      <ButtonMenu />

      {isShownLottie && (
        <Box
          sx={{
            pointerEvents: 'none',
            '& div': {
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100% !important',
              height: '100% !important',
              zIndex: '20',
            },
            '& svg': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              translate: '-50% -50%',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          }}
        >
          <Lottie options={defaultOptions} />
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          height: '100%',
          '& > div': {
            width: '100% !important',
            height: '100% !important',
          },
          '& video': {
            objectFit: 'cover',
          },
          zIndex: '20',
          position: 'absolute',
          top: '0',
          left: '0',
          pointerEvents: 'none',
        }}
      >
        <ReactPlayer
          url={url}
          playing={isPlaying}
          onEnded={() => {
            if (isLast) {
              const correctAnswers = questions.filter((question, index) => {
                return question.rightAnswer.includes(answers[index])
              })

              setCorrectAnswers(correctAnswers.length)
              setAnswers({})
              setActiveSlide(0)
              // setIsLast(false)
              setIsPlaying(false)
              sliderRef.current?.slickGoTo(0)
              goToNextSlide()
              return
            } else {
              sliderRef.current?.slickNext()
              setIsPlaying(false)
            }
          }}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>

      <Box
        sx={{
          height: '100%',
        }}
      >
        <SlickSlider ref={sliderRef} {...settings}>
          {questions.map((question, index) => (
            <QuizQuestion2
              key={question.question[language]}
              index={index}
              question={question}
              onClick={onAnswer}
              language={language}
              activeSlide={activeSlide}
            />
          ))}
        </SlickSlider>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: '0',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '37vw',
          }}
        >
          <Box
            component={'img'}
            src={quizProgress}
            sx={{
              width: '100%',
            }}
          />

          <Box
            component={'img'}
            src={quizDot}
            sx={{
              position: 'absolute',
              width: '4.92%',
              left: `calc(4.64% + 7.8% * ${activeSlide})`,
              top: '50%',
              translate: `0 calc(-50% - 1px)`,
              transition: '0.3s',
            }}
          />
        </Box>
      </Box>

      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: 'flex',*/}
      {/*    alignItems: 'center',*/}
      {/*    justifyContent: 'center',*/}
      {/*    gap: '0.625%',*/}
      {/*    position: 'absolute',*/}
      {/*    bottom: '5.5%',*/}
      {/*    left: '0',*/}
      {/*    width: '100%',*/}
      {/*    flexWrap: 'wrap',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {questions.map((question, index) => (*/}
      {/*    <Box*/}
      {/*      key={question.question[language]}*/}
      {/*      sx={{*/}
      {/*        width: '2.6%',*/}
      {/*        aspectRatio: '1/1',*/}
      {/*        position: 'relative',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {index === activeSlide ? (*/}
      {/*        <svg*/}
      {/*          xmlns="http://www.w3.org/2000/svg"*/}
      {/*          viewBox="0 0 50 50"*/}
      {/*          fill="none"*/}
      {/*        >*/}
      {/*          <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)" />*/}
      {/*        </svg>*/}
      {/*      ) : Number.isInteger(answers[index]) ? (*/}
      {/*        question.rightAnswer.includes(answers[index]) ? (*/}
      {/*          <svg*/}
      {/*            xmlns="http://www.w3.org/2000/svg"*/}
      {/*            viewBox="0 0 50 50"*/}
      {/*            fill="none"*/}
      {/*          >*/}
      {/*            <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)" />*/}
      {/*            <circle cx="25" cy="25" r="12.5" fill="#3AD3A4" />*/}
      {/*          </svg>*/}
      {/*        ) : (*/}
      {/*          <svg*/}
      {/*            xmlns="http://www.w3.org/2000/svg"*/}
      {/*            viewBox="0 0 50 50"*/}
      {/*            fill="none"*/}
      {/*          >*/}
      {/*            <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)" />*/}
      {/*            <path*/}
      {/*              d="M34.9998 18.3331L31.6665 15L24.9994 21.6667L18.3335 15.0012L15.0002 18.3343L21.6661 24.9999L15 31.6657L18.3333 34.9988L24.9994 28.333L31.6667 35L35 31.6669L28.3327 24.9999L34.9998 18.3331Z"*/}
      {/*              fill="#3AD3A4"*/}
      {/*            />*/}
      {/*          </svg>*/}
      {/*        )*/}
      {/*      ) : (*/}
      {/*        <svg*/}
      {/*          xmlns="http://www.w3.org/2000/svg"*/}
      {/*          viewBox="0 0 50 50"*/}
      {/*          fill="none"*/}
      {/*        >*/}
      {/*          <circle cx="25" cy="25" r="25" fill="rgba(32, 97, 91, 0.8)" />*/}
      {/*        </svg>*/}
      {/*      )}*/}
      {/*    </Box>*/}
      {/*  ))}*/}
      {/*</Box>*/}

      {/*<Typography*/}
      {/*  sx={{*/}
      {/*    position: 'absolute',*/}
      {/*    bottom: '7.5%',*/}
      {/*    right: '30px',*/}
      {/*    ...fonts.Rubik['24px']({*/}
      {/*      color: '#3AD3A4',*/}
      {/*      fontWeight: '600',*/}
      {/*    }),*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {activeSlide + 1} / {questions.length}*/}
      {/*</Typography>*/}
    </SliderWrapper>
  )
}

export default Quiz
