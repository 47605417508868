import React from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../../../components/Slider/SliderWrapper'
import fonts from '../../../styles/fonts'
import background from '../../../assets/kurz2024/bg3.png'
import BackgroundWave from '../BackgroundWave/BackgroundWave'
import ButtonLeft from '../../../components/Buttons/ButtonLeft'
import ButtonRight from '../../../components/Buttons/ButtonRight'
import popup1 from '../../../assets/kurz2024/popup1.png'

const textSK = (
  <Box
    sx={{
      textAlign: 'center',
      color: '#000',
      ...fonts.Urbanist['32px']({
        fontWeight: '500',
        lineHeight: 40,
      }),
    }}
  >
    <Box
      component={'b'}
      sx={{
        ...fonts.Urbanist['40px']({
          fontWeight: '800',
        }),
      }}
    >
      Milé žiačky, milí žiaci,
    </Box>{' '}
    <br />
    <br />{' '}
    <b style={{ color: '#6936E5' }}>
      aj tento rok sme pre Vás pripravili nový e-kurz v rámci projektu DETI
      A ARCHITEKTÚRA.
    </b>{' '}
    Minulý rok sme sa bližšie pozreli na tému Zelená architektúra, zelené
    stavebníctvo. Išlo o oblasť, ktorá sa priamo dotýka udržateľnosti
    v architektúre a stavebníctve.{' '}
    <b style={{ color: '#6936E5' }}>
      A na udržateľnosť sme sa rozhodli nadviazať aj tento rok, keďže jedným zo
      zaujímavých rozmerov je aj to, aby sme nestavali iba nové budovy, ale aby
      sme využívali potenciál už existujúcich budov.
    </b>{' '}
    Tie už síce prestali slúžiť svojmu účelu a sú často opustené a zanedbané,
    ale stále majú potenciál na obnovu a následnú konverziu či adaptáciu pre
    súčasnosť. <br />
    <br />{' '}
    <b style={{ color: '#6936E5' }}>
      Preto sme zvolili tento rok tému „Zo starého nové“,
    </b>{' '}
    lebo ako na Slovensku tak aj vo svete, je veľa príkladov, kedy takéto budovy
    nezbúrali, ale ich rozumne a kvalitne zrekonštruovali a dnes sú využívané
    často na úplne iný účel ako v minulosti.{' '}
    <b style={{ color: '#6936E5' }}>
      A my Vám v našom e-kurze viaceré z nich predstavíme.
    </b>
  </Box>
)

const textEN = (
  <Box
    sx={{
      textAlign: 'center',
      color: '#000',
      ...fonts.Urbanist['32px']({
        fontWeight: '500',
        lineHeight: 40,
      }),
    }}
  >
    <Box
      component={'b'}
      sx={{
        ...fonts.Urbanist['40px']({
          fontWeight: '800',
        }),
      }}
    >
      Dear pupils,
    </Box>{' '}
    <br />
    <br />{' '}
    <b style={{ color: '#6936E5' }}>
      This year again, we have prepared for you a new e-course within the
      project CHILDREN AND ARCHITECTURE.
    </b>{' '}
    Last year we took a closer look at the topic Green Architecture, Green
    Construction. This was an area that directly concerns sustainability in
    architecture and construction.{' '}
    <b style={{ color: '#6936E5' }}>
      And we decided to follow up on sustainability this year as well, as one of
      the interesting dimensions is not only to build new buildings, but also to
      use the potential of existing buildings.
    </b>{' '}
    They may have outlived their purpose and are often abandoned and neglected,
    but they still have the potential to be restored and then converted or
    adapted for the present. <br />
    <br />{' '}
    <b style={{ color: '#6936E5' }}>
      That is why this year we have chosen the theme "From old to new",
    </b>{' '}
    because both in Slovakia and in the world, there are many examples where
    such buildings were not demolished, but have been sensibly and well
    reconstructed and today they are often used for a completely different
    purpose than in the past.{' '}
    <b style={{ color: '#6936E5' }}>
      In our e-course we will introduce you to several of them.
    </b>
  </Box>
)

const Intro = ({ language, goToPrevSlide, goToNextSlide }) => {
  const text = {
    sk: textSK,
    en: textEN,
  }[language]

  return (
    <SliderWrapper flexCenter>
      <BackgroundWave
        background={background}
        sx={{
          background: 'linear-gradient(90deg, #6936C5 0%, #5C2A93 100%)',
        }}
      />

      <ButtonLeft isShown onClick={goToPrevSlide} />

      <ButtonRight isShown onClick={goToNextSlide} />

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '83vw',
        }}
      >
        <Box
          component={'img'}
          src={popup1}
          sx={{
            width: '100%',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            padding: '4vh 5vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {language === 'sk' ? textSK : textEN}
        </Box>
      </Box>
    </SliderWrapper>
  )
}

export default Intro
