import React, { createContext, useContext, useState } from 'react'

const QuizContext = createContext({})

const QuizContextProvider = ({ children }) => {
  const [correctAnswers, setCorrectAnswers] = useState()

  return (
    <QuizContext.Provider
      value={{
        correctAnswers,
        setCorrectAnswers,
      }}
    >
      {children}
    </QuizContext.Provider>
  )
}

const useQuizContext = () => useContext(QuizContext)

export { QuizContextProvider, useQuizContext }
