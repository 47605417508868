import React, { useEffect, useRef, useState } from 'react'
import SliderWrapper from '../Slider/SliderWrapper'
import StripGlasses from './StripGlasses'
import StripSwitch from './StripSwitch'
import Box from '@mui/material/Box'
import StripImages from './StripImages'
import StripTriggers from './StripTriggers'
import Modal from '../Modal/Modal'
import Modal2 from '../Modal2/Modal'
import ModalWrapper from '../Modal/ModalWrapper'
import ModalWrapper2 from '../Modal2/ModalWrapper'
import useSound from 'use-sound'
import stripesSound from '../../assets/kurz2024/EXPORT/pás hudba v pozadi.mp3'
import magicSound from '../../assets/_Sfx/__Compress/Magic.mp3'
import ButtonLeft from '../../components/Buttons/ButtonLeft'
import ButtonRight from '../../components/Buttons/ButtonRight'
import ButtonMenu from '../../components/Buttons/ButtonMenu'
import AdditionalStudy from '../../v2/components/AdditionalStudy/AdditionalStudy'
import Lottie from 'react-lottie'
import explain4 from '../../assets/kurz2024/explain4.png'
import explain4EN from '../../assets/kurz2024/explain4EN.png'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import ButtonClose from '../Buttons/ButtonClose'

const Strip = ({
  isActive,
  language,
  images,
  triggers,
  animations,
  chapter,
  goToPrevSlide,
  goToNextSlide,
  scrollHandler,
  title,
  additionalStudy,
  isStrip2,
}) => {
  const [isSwitched, setIsSwitched] = useState(false)
  const [isShownModal, setIsShownModal] = useState(false)
  const [isShownOverlay, setIsShownOverlay] = useState(false)
  const [text, setText] = useState()
  const parentRef = useRef()
  const buttonLeftRef = useRef()
  const buttonRightRef = useRef()
  const cloudsRef = useRef()
  const parallax1 = useRef()
  const parallax2 = useRef()
  const modalRef = useRef()

  const [isLoaded, setIsLoaded] = useState(false)
  const [playVoiceover, { stop }] = useSound(stripesSound, {
    loop: true,
    interrupt: true,
    onload: () => setIsLoaded(true),
    volume: 0.1,
  })

  const [playMagic, { stop: stopMagic }] = useSound(magicSound, {
    interrupt: true,
    volume: 0.1,
  })

  const onPlay = () => {
    playVoiceover()
  }

  const onStop = () => {
    stop()
    stopMagic()

    modalRef.current?.onStop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()

        setIsShownModal(false)

        if (isSwitched) {
          playMagic()
        }
      } else {
        onStop()
        stopMagic()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    onScroll()
  }, [title, language])

  useEffect(() => {
    isSwitched ? playMagic() : stopMagic()
  }, [isSwitched])

  const shouldScroll = useRef(false)
  const isActiveScroll = useRef(false)

  const scrollLeft = () => {
    if (!shouldScroll.current) {
      return
    }

    parentRef.current.scrollBy({
      left: -7,
    })

    requestAnimationFrame(scrollLeft)
  }

  const scrollRight = () => {
    if (!shouldScroll.current) {
      return
    }

    parentRef.current.scrollBy({
      left: 7,
    })

    requestAnimationFrame(scrollRight)
  }

  const addListeners = () => {
    buttonLeftRef.current.addEventListener('mouseover', () => {
      if (isActiveScroll.current) {
        return
      }

      isActiveScroll.current = true
      shouldScroll.current = true
      scrollLeft()
    })
    buttonRightRef.current.addEventListener('mouseover', () => {
      if (isActiveScroll.current) {
        return
      }

      isActiveScroll.current = true
      shouldScroll.current = true
      scrollRight()
    })

    buttonLeftRef.current.addEventListener('mouseleave', () => {
      isActiveScroll.current = false
      shouldScroll.current = false
    })
    buttonRightRef.current.addEventListener('mouseleave', () => {
      isActiveScroll.current = false
      shouldScroll.current = false
    })
  }

  useEffect(() => {
    addListeners()
    onScroll()
  }, [])

  const onScroll = () => {
    const percentage =
      (parentRef.current.scrollLeft /
        (parentRef.current.scrollWidth - parentRef.current.offsetWidth)) *
      100

    if (cloudsRef.current) {
      cloudsRef.current.style.transform = `translateX(-${percentage * 1.5}px)`
    }

    if (parallax1.current) {
      parallax1.current.style.transform = `translateX(-${percentage * 1.5}px)`
    }

    if (parallax2.current) {
      parallax2.current.style.transform = `translateX(-${percentage * 1.5}px)`
    }

    if (scrollHandler) {
      return setText(scrollHandler(percentage, language))
    }
  }

  const onButtonLeftClick = () => {
    const percentage =
      (parentRef.current.scrollLeft /
        (parentRef.current.scrollWidth - parentRef.current.offsetWidth)) *
      100

    if (percentage < 10) {
      return goToPrevSlide()
    }
  }

  const onButtonRightClick = () => {
    const percentage =
      (parentRef.current.scrollLeft /
        (parentRef.current.scrollWidth - parentRef.current.offsetWidth)) *
      100

    if (percentage > 90) {
      return goToNextSlide()
    }
  }

  const ModalWrapperComponent = isStrip2 ? ModalWrapper2 : ModalWrapper
  const ModalComponent = isStrip2 ? Modal2 : Modal

  return (
    <SliderWrapper flexCenter>
      <ButtonMenu
        variant={1}
        sx={{
          zIndex: '20',
        }}
      />

      <ButtonLeft
        variant={1}
        buttonRef={buttonLeftRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonLeftClick}
      />

      <ButtonRight
        variant={1}
        buttonRef={buttonRightRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonRightClick}
      />

      <Box
        component={'img'}
        src={language === 'sk' ? explain4 : explain4EN}
        sx={{
          position: 'absolute',
          top: '22px',
          right: '22px',
          width: '15.6vw',
          zIndex: '20',
        }}
      />

      {/*<Header*/}
      {/*  text={text}*/}
      {/*  sx={{*/}
      {/*    minWidth: 'unset',*/}
      {/*    opacity: !Boolean(isShownModal) ? '1' : '0',*/}
      {/*    pointerEvents: Boolean(isShownModal) ? 'none' : 'all',*/}
      {/*    transition: 'opacity 500ms ease 0s',*/}
      {/*  }}*/}
      {/*/>*/}

      <StripGlasses isSwitched={isSwitched} />

      {/*<StripSwitch*/}
      {/*  chapter={chapter}*/}
      {/*  isSwitched={isSwitched}*/}
      {/*  setIsSwitched={setIsSwitched}*/}
      {/*/>*/}

      <ModalWrapperComponent
        isShownModal={isShownModal}
        sx={{
          backdropFilter: isStrip2 ? 'blur(8.75px)' : undefined,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: isStrip2
              ? '#4EF993'
              : 'linear-gradient(76deg, #6936C5 3.42%, #5C2A93 97.76%)',
            opacity: isStrip2 ? '0.6' : '0.3',
          }}
        />

        {Boolean(isShownModal) && (
          <ModalComponent
            key={isShownModal.id}
            modalRef={modalRef}
            language={language}
            chapter={chapter}
            isShownModal={isShownModal}
            setIsShownModal={setIsShownModal}
            text={text}
            ModalNavComponent={isShownModal?.ModalNavComponent}
            setIsShownOverlay={setIsShownOverlay}
          />
        )}
      </ModalWrapperComponent>

      <Box
        ref={parentRef}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          position: 'relative',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        onScroll={onScroll}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <StripImages
            isSwitched={isSwitched}
            images={images}
            cloudsRef={cloudsRef}
            parallax1={parallax1}
            parallax2={parallax2}
          />

          {/*{animations &&*/}
          {/*  animations.map((animation) => (*/}
          {/*    <Box*/}
          {/*      sx={{*/}
          {/*        position: 'absolute',*/}
          {/*        top: '0',*/}
          {/*        left: '0',*/}
          {/*        height: '100%',*/}
          {/*        zIndex: '4',*/}
          {/*        ...animation.sx,*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <ReactPlayer*/}
          {/*        playing*/}
          {/*        loop*/}
          {/*        muted*/}
          {/*        url={animation.animation}*/}
          {/*        width={'auto'}*/}
          {/*        height={'100%'}*/}
          {/*      />*/}
          {/*    </Box>*/}
          {/*  ))}*/}

          {animations &&
            animations.map((animation) => (
              <Box
                sx={{
                  pointerEvents: 'none',
                  '& div': {
                    zIndex: '4',
                    position: 'absolute',
                    top: '3.1%',
                    left: '27.36%',
                    width: '21% !important',
                    height: '100% !important',
                  },
                  '& svg': {
                    position: 'absolute',
                    width: '100%',
                  },
                }}
              >
                {/*<ReactPlayer url={animation[language]} playing={isActive} loop muted />*/}
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animation.animation,
                  }}
                  // isStopped={!isActive}
                  // isPaused={!isActive}
                />
              </Box>
            ))}

          <StripTriggers
            chapter={chapter}
            triggers={triggers}
            setIsShownModal={setIsShownModal}
          />
        </Box>
      </Box>

      {additionalStudy && (
        <AdditionalStudy
          additionalStudy={additionalStudy}
          language={language}
        />
      )}

      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          opacity: isShownOverlay ? '1' : '0',
          pointerEvents: !isShownOverlay ? 'none' : 'all',
          transition: 'opacity 500ms ease 0s',
          background:
            'linear-gradient(76deg, rgba(105, 54, 197, 0.95) 3.42%, rgba(92, 42, 147, 0.95) 97.76%)',
          zIndex: '40',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ButtonClose
          sx={{
            zIndex: '24',
            opacity: isShownOverlay ? '1' : '0',
            pointerEvents: !isShownOverlay ? 'none' : 'all',
            transition: 'opacity 500ms ease 0s',
          }}
          onClick={() => setIsShownOverlay(undefined)}
        />

        <Typography
          sx={{
            ...fonts.Urbanist['48px']({
              color: '#fff',
              fontWeight: '500',
              lineHeight: 57,
            }),
            textAlign: 'center',
            maxWidth: '75%',
            '& b': {
              color: '#fff',
            },
          }}
        >
          {isShownOverlay?.text && isShownOverlay?.text[language]}
        </Typography>
      </Box>
    </SliderWrapper>
  )
}

export default Strip
