import React from 'react'
import StripTrigger from './StripTrigger'

const StripTriggers = ({ chapter, triggers, setIsShownModal }) => {
  return triggers.map((trigger, index) => (
    <StripTrigger
      key={trigger.id}
      {...trigger}
      index={index}
      chapter={chapter}
      onClick={() => setIsShownModal(trigger)}
    />
  ))
}

export default StripTriggers
