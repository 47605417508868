import React from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../../../components/Slider/SliderWrapper'
import Typography from '@mui/material/Typography'
import fonts from '../../../styles/fonts'
import background from '../../../assets/kurz2024/bg1.png'
import detiAArchitektura from '../../../assets/kurz2024/detiaarchitektura.png'
import detiAArchitekturaEn from '../../../assets/kurz2024/detiaarchitektura.png'
import BackgroundWave from '../BackgroundWave/BackgroundWave'
import Button from '../../../components/Buttons/Button1'
import Logo from '../Logo/Logo'

const Intro2 = ({ language, goToPrevSlide, goToNextSlide }) => {
  return (
    <SliderWrapper flexCenter>
      <BackgroundWave
        background={background}
        sx={{
          background: 'linear-gradient(90deg, #6936C5 0%, #5C2A93 100%)',
        }}
      />

      <Logo />

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '1',
          gap: '40px',
        }}
      >
        <Box
          component={'img'}
          src={language === 'sk' ? detiAArchitektura : detiAArchitekturaEn}
          sx={{
            width: '17%',
          }}
        />

        <Typography
          sx={{
            margin: '5vh 0',
            ...fonts.Urbanist['150px']({
              color: '#fff',
              fontWeight: '700',
            }),
            textAlign: 'center',
          }}
        >
          {language === 'sk' ? (
            <>
              Zo starého{' '}
              <Box
                component={'span'}
                sx={{
                  position: 'relative',
                  color: '#000',
                }}
              >
                nové
                <Box
                  sx={{
                    position: 'absolute',
                    width: '112%',
                    height: '85%',
                    background: '#FF00FF',
                    top: '50%',
                    left: '50%',
                    translate: '-50% -50%',
                    zIndex: '-1',
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              From old to{' '}
              <Box
                component={'span'}
                sx={{
                  position: 'relative',
                  color: '#000',
                }}
              >
                new
                <Box
                  sx={{
                    position: 'absolute',
                    width: '112%',
                    height: '85%',
                    background: '#FF00FF',
                    top: '50%',
                    left: '50%',
                    translate: '-50% -50%',
                    zIndex: '-1',
                  }}
                />
              </Box>
            </>
          )}
        </Typography>

        <Button
          text={language === 'sk' ? 'Spusti kurz' : 'Continue'}
          onClick={goToNextSlide}
        ></Button>
      </Box>
    </SliderWrapper>
  )
}

export default Intro2
