import strip1Background from '../assets/kurz2024/EXPORT/K05 pás/4 Londýn/londyn pas exportAsset 15-100.jpg'

import trigger1voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.4.1.mp3'
import trigger1voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.4.1.mp3'
import k050105trigger01img1 from '../assets/kurz2024/k050105trigger01img1.png'
import k050105trigger01img2 from '../assets/kurz2024/k050105trigger01img2.png'
import k050105trigger01img3 from '../assets/kurz2024/k050105trigger01img3.png'
import k050105trigger01img4 from '../assets/kurz2024/k050105trigger01img4.png'

import trigger2voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.4.2.mp3'
import trigger2voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.4.2.mp3'
import k050105trigger02img1 from '../assets/kurz2024/k050105trigger02img1.png'
import k050105trigger02img2 from '../assets/kurz2024/k050105trigger02img2.png'
import k050105trigger02img3 from '../assets/kurz2024/k050105trigger02img3.png'
import k050105trigger02img4 from '../assets/kurz2024/k050105trigger02img4.png'

import ModalNav3 from '../components/Modal/ModalNav3'

const stripk0505Images = [
  {
    image: strip1Background,
    sx: {
      zIndex: '3',
      position: 'relative',
    },
  },
]

const stripk0505Triggers = [
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers1',
    top: '28.5%',
    left: '32%',
    images: [
      {
        src: k050105trigger01img1,
        sx: {
          width: '55%',
        },
      },
      {
        src: k050105trigger01img2,
        sx: {
          width: '38%',
        },
      },
      {
        src: k050105trigger01img3,
        sx: {
          width: '40%',
        },
      },
      {
        isLeftAnimation: true,
        src: k050105trigger01img4,
        sx: {
          width: '55%',
          transform: 'translateX(-60%)',
        },
      },
    ],
    source: {
      sk: 'zdroj: herzogdemeuron.com',
      en: 'source: herzogdemeuron.com',
    },
    voiceover: {
      sk: trigger1voiceoverSK,
      en: trigger1voiceoverEN,
    },
    text: {
      sk: 'Ako poslednú v poradí v tejto časti e-kurzu sme sa rozhodli predstaviť známu londýnsku galériu súčasného umenia Tate Modern. Ide o veľmi pekný príklad premeny/konverzie bývalej elektrárne na južnom brehu Temže, ktorá bola od roku 1981 zatvorená a nevyužívaná. Angličania ju však nezbúrali, pretože pôvodná elektráreň mala zaujímavú architektúru. Rozhodli sa jej dať novú náplň. Švajčiarski architekti z firmy Herzog & de Meuron upravili jej priestory pre potreby galérie.',
      en: 'As the last in the sequence in this part of the e-course, we decided to introduce the famous London contemporary art gallery Tate Modern. It is a very nice example of converting a former power station on the south bank of the Thames, which has been closed and unused since 1981. However, the English did not demolish it because the original power station had interesting architecture. They decided to give it a new lease of life. Swiss architects from the firm Herzog & de Meuron adapted its premises to the needs of a gallery.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers2',
    top: '15.5%',
    left: '60%',
    images: [
      {
        isLeftAnimation: true,
        src: k050105trigger02img1,
        sx: {
          width: '65%',
        },
      },
      {
        src: k050105trigger02img2,
        sx: {
          width: '37%',
        },
      },
      {
        src: k050105trigger02img3,
        sx: {
          width: '53%',
          transform: 'translateX(67%)',
        },
      },
      {
        src: k050105trigger02img4,
        sx: {
          width: '59%',
        },
      },
    ],
    sources: [
      {
        sk: 'zdroj: media.tate.org.uk',
        en: 'source: media.tate.org.uk',
      },
      {
        sk: 'zdroj: cdn.rt.emap.com',
        en: 'source: cdn.rt.emap.com',
      },
      {
        sk: 'zdroj: herzogdemeuron.com',
        en: 'source: herzogdemeuron.com',
      },
      {
        sk: 'zdroj: herzogdemeuron.com',
        en: 'source: herzogdemeuron.com',
      },
    ],
    voiceover: {
      sk: trigger2voiceoverSK,
      en: trigger2voiceoverEN,
    },
    text: {
      sk: 'Turbínová hala, kde sa nachádza elektrický generátor z pôvodnej elektrárne, je vynikajúcim priestorom pre rôzne špeciálne výstavy súčasných umelcov. Architekti zachovali pôvodnú tehlovú stavbu a nad ňu postavili sklenenú modernú časť, dobudovali rampu a pôvodný komín elektrárne tiež nezbúrali, ale využili ho ako vyhliadkovú vežu, z ktorej je krásny pohľad na celý Londýn.',
      en: 'The Turbine Hall, which houses the electric generator from the original power station, is an excellent space for various special exhibitions by contemporary artists. The architects retained the original brick structure and built a glass modern section above it, added a ramp and also did not demolish the original power station smoke stack, but used it as a viewing tower from which there is a beautiful view across London.',
    },
  },
]

export { stripk0505Images, stripk0505Triggers }
