import React from 'react'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'

const InfoText = () => {
  return (
    <Typography
      sx={{
        textAlign: 'center',
        ...fonts.Urbanist['78px']({
          fontWeight: '900',
          color: '#000',
        }),
      }}
    >
      Zvoľte si jazyk kurzu.
      <br />
      Choose the language of the course.
    </Typography>
  )
}

export default InfoText
