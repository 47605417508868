import React from "react";
import Typography from "@mui/material/Typography";
import logo from '../../../assets/kurz2024/logo.svg'

const Logo = ({ color = '#3AD3A4', sx, }) => {
    return (
        <Typography
            component={'a'}
            href={'https://www.eduvision.sk/'}
            target={'_blank'}
            sx={{
                color,
                position: 'absolute',
                bottom: '4.16%',
                right: '2.81%',
                zIndex: '1',
                textDecoration: 'unset',
                ...sx,
            }}
        >
          <img src={logo} alt="" />
        </Typography>
    )
}

export default Logo
