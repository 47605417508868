import { keyframes } from '@emotion/react'

const wave = keyframes`
  0% {
    transform: translateX(-0);
  }

  50% {
    transform: translateX(-2.5%);
  }

  100% {
    transform: translateX(0);
  }
`

const wave2 = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(5%);
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInScale = keyframes`
  0% {
    opacity: 0;
      scale: 0.9;
  }

  100% {
    opacity: 1;
      scale: 1
  }
`

const right = (translateX = 'translateX(50%)') => {
  return keyframes`
    0% {
        right: 50%;
        scale: 1;
        transform: ${translateX};
    }

    50% {
        right: 0%;
        scale: 1.1;
        transform: ${translateX} matrix3d(
                1, -0.2, 0, 0,     /* Slight tilt along the X axis */
                0.2, 1, 0, 0,    /* Slight tilt along the Y axis */
                0, 0, 1, 0,       /* No change to Z-axis scale */
                0, 0, 0, 1        /* Translation stays the same */
        );
    }

    100% {
        right: 50%;
        scale: 1;
        transform: ${translateX};
    }
`
}

const left = (translateX = 'translateX(-50%)') => {
  return keyframes`
  0% {
      left: 50%;
      scale: 1;
      transform: ${translateX};
  }

    50% {
        left: 0%;
        scale: 1.1;
        transform: ${translateX} matrix3d(
                1, 0.2, 0, 0,     /* Slight tilt along the X axis */
                -0.2, 1, 0, 0,    /* Slight tilt along the Y axis */
                0, 0, 1, 0,       /* No change to Z-axis scale */
                0, 0, 0, 1        /* Translation stays the same */
        );
    }

  100% {
      left: 50%;
      scale: 1;
      transform: ${translateX};
  }
`
}

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0,255,255, 0.8);
  }

  50% {
    box-shadow: 0 0 0 30px rgba(0,255,255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0,255,255, 0);
  }
`
const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const pulseAnimation = {
  animation: `${pulse} 2s infinite`,
}

const rotate360Animation = {
  animation: `${rotate360} 30s infinite linear`,
}

export {
  wave,
  wave2,
  fadeIn,
  fadeInScale,
  right,
  left,
  pulseAnimation,
  rotate360Animation,
}
