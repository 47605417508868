import { useEffect, useRef, useState } from 'react'
import useSound from 'use-sound'
import sound from '../assets/kurz2024/EXPORT/predelovy slide.mp3'

const usePlaySound = ({ isActive }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const [playVoiceover, { stop }] = useSound(sound, {
    volume: 0.2,
    onload: () => {
      setIsLoaded(true)
    },
  })

  const timeoutRef = useRef()

  const onPlay = () => {
    playVoiceover()
  }

  const onStop = () => {
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        timeoutRef.current = setTimeout(() => {
          onPlay()
        }, 1000)
      } else {
        onStop()
      }
    }

    return () => {
      onStop()
      clearTimeout(timeoutRef.current)
    }
  }, [isLoaded, isActive])
}

export { usePlaySound }
