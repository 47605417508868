import React from 'react'
import albumBackground from '../../assets/kurz2024/EXPORT/K02/K02_01/Pozadie.png'
import Box from '@mui/material/Box'

const BackgroundAlbum = () => {
  return (
    <Box
      component={'img'}
      src={albumBackground}
      sx={{
        zIndex: '-1',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        objectFit: 'cover',
      }}
    />
  )
}

export default BackgroundAlbum
