import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext({})

const AppContextProvider = ({ children }) => {
  const [isShownMenu, setIsShownMenu] = useState(false)
  const [activeChapter, setActiveChapter] = useState('chapter0')
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  return (
    <AppContext.Provider
      value={{
        isShownMenu,
        setIsShownMenu,
        activeChapter,
        setActiveChapter,
        activeSlideIndex,
        setActiveSlideIndex,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppContext = () => useContext(AppContext)

export { AppContextProvider, useAppContext }
