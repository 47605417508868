import React from 'react'
import Box from '@mui/material/Box'

const SliderWrapper = ({ children, sx, flexCenter }) => {
  let styles = {}

  if (flexCenter) {
    styles = {
      ...styles,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        maxWidth: '1920px',
        margin: '0 auto',
        ...styles,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default SliderWrapper
