import React from 'react'
import Box from "@mui/material/Box";
import {wave} from "../../../styles/animations";

const BackgroundWave = ({ background, disabled, sx }) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
                zIndex: '-1',
            }}
        >
            <Box
                component={'img'}
                src={background}
                sx={{
                    position: 'absolute',
                    top: disabled ? '0' : '-5%',
                    left: disabled ? '0' : '-5%',
                    width: disabled ? '100%' : '110%',
                    height: disabled ? '100%' : '110%',
                    animation: disabled ? undefined : `${wave} 6s ease-in-out infinite`,
                    objectFit: 'cover',
                    ...sx,
                }}
            />
        </Box>
    )
}

export default BackgroundWave
