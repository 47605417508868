import React from 'react'
import glasses from '../../assets/_K1/DaA_K1-Strip_GlassesMask.png'
import Box from '@mui/material/Box'

const StripGlasses = ({ isSwitched, sx }) => {
  return (
    <Box
      component={'img'}
      src={glasses}
      sx={{
        position: 'absolute',
        zIndex: '15',
        opacity: isSwitched ? '1' : '0',
        transition: 'opacity 500ms ease 0s',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        ...sx,
      }}
    />
  )
}

export default StripGlasses
