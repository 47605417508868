import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import SlickSlider from 'react-slick'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import { left, right } from '../../styles/animations'
import ButtonClose from '../Buttons/ButtonClose'
import ArrowLeft from '../Buttons/ArrowLeft'
import ArrowRight from '../Buttons/ArrowRight'
import ModalNav3 from '../Modal2/ModalNav3'
import ModalNav4 from './ModalNav4'
import ModalNav2 from './ModalNav2'
import Lottie from 'react-lottie'

const ModalContent = ({
  sliderRef,
  isShownModal,
  isShownText,
  setActiveSlide,
  chapter,
  language,
  ModalNavComponent,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(undefined)

  const intervalRef = useRef()

  const isAnimationRunningRef = useRef(false)

  const startInterval = () => {
    if (!Boolean(isShownModal.images?.length)) {
      return
    }

    const imagesCount = isShownModal.images.length

    if (1 >= imagesCount) {
      return
    }

    intervalRef.current = setInterval(() => {
      setActiveImageIndex((prevState) => {
        if (prevState === undefined) {
          return 0
        }

        if (prevState + 1 > imagesCount - 1) {
          return undefined
        }

        return prevState + 1
      })

      isAnimationRunningRef.current = true

      setTimeout(() => {
        isAnimationRunningRef.current = false
      }, 1200)
    }, 3000)
  }

  useEffect(() => {
    startInterval()

    return () => clearInterval(intervalRef.current)
  }, [isShownModal.images])

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    draggable: false,
    touchMove: false,
    initialSlide: 0,
    afterChange: setActiveSlide,
    pauseOnHover: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
    autoplaySpeed: 10000,
  }

  const getActiveSlide = () => {
    if (!isShownModal.images?.length) {
      return 0
    }

    if (activeImageIndex === undefined) {
      return 0
    }

    if (activeImageIndex === isShownModal.images.length - 1) {
      return 0
    }

    return activeImageIndex + 1
  }

  useEffect(() => {
    const getActiveSlide1 = () => {
      if (!isShownModal.images?.length) {
        return 0
      }

      if (activeImageIndex === undefined) {
        return 0
      }

      if (activeImageIndex === isShownModal.images.length - 1) {
        return 0
      }

      return activeImageIndex + 1
    }

    setActiveSlide(getActiveSlide1())
  }, [activeImageIndex, isShownModal.images?.length])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          opacity: isShownText ? '1' : '0',
          pointerEvents: !isShownText ? 'none' : 'all',
          transition: 'opacity 500ms ease 0s',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'linear-gradient(180deg, #16363F 39.58%, #112232 100%)',
          boxShadow: '3px 3px 10px 0px rgba(0, 0, 0, 0.25) inset',
          zIndex: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            ...fonts.Rubik['42px']({
              color: '#3AD3A4',
            }),
            width: '100%',
            maxWidth: '83%',
            '& b': {
              color: '#E8AEBA',
            },
            ...isShownModal.sx,
          }}
        >
          {isShownModal.text[language]}
        </Typography>
      </Box>

      {Boolean(isShownModal.photos?.length) && (
        <SlickSlider ref={sliderRef} {...settings}>
          {isShownModal.photos?.map((photo) => (
            <Box
              key={photo}
              component={'img'}
              src={photo}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          ))}
        </SlickSlider>
      )}

      {isShownModal.animations?.length &&
        isShownModal.animations.map((animation) => (
          <Box
            sx={{
              pointerEvents: 'none',
              '& div': {
                zIndex: '4',
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '100% !important',
                height: '100% !important',
                ...animation.sx2,
              },
              '& svg': {
                position: 'absolute',
                width: '100%',
              },
              ...animation.sx,
            }}
          >
            {/*<ReactPlayer url={animation[language]} playing={isActive} loop muted />*/}
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animation.animation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              // isStopped={!isActive}
              // isPaused={!isActive}
            />
          </Box>
        ))}

      {Boolean(isShownModal.images?.length) && (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isShownModal.images?.map((image, index) => (
            <Box
              key={image}
              component={'img'}
              src={image.src}
              sx={{
                position: 'absolute',
                left: image.isLeftAnimation ? '50%' : undefined,
                right: image.isLeftAnimation ? undefined : '50%',
                transform: image.isLeftAnimation
                  ? image?.sx?.transform
                    ? image?.sx?.transform
                    : 'translateX(-50%)'
                  : image?.sx?.transform
                  ? image?.sx?.transform
                  : 'translateX(50%)',
                zIndex: 10 + (isShownModal.images.length - index),
                transition: 'transform 0.5s ease, z-index 0s, right 0.5s ease',
                transitionDelay: '0s, 0.5s, 0.5s',
                maxHeight: '90%',
                objectFit: 'contain',
                '&':
                  activeImageIndex >= index
                    ? {
                        zIndex: 1 + (isShownModal.images.length - index),
                        transition:
                          'right 0.5s ease, z-index 0s, transform 0.5s ease',
                        transitionDelay: '0s, 0.5s, 0.5s',
                        animation: `${
                          image.isLeftAnimation
                            ? left(image?.sx?.transform)
                            : right(image?.sx?.transform)
                        } 1.2s ease-in-out forwards`,
                      }
                    : {},
                ...image.sx,
              }}
            />
          ))}

          {isShownModal.images.length === 2 && (
            <ModalNav2 activeSlide={getActiveSlide()} />
          )}

          {isShownModal.images.length === 3 && (
            <ModalNav3 activeSlide={getActiveSlide()} />
          )}

          {isShownModal.images.length === 4 && (
            <ModalNav4 activeSlide={getActiveSlide()} />
          )}

          {isShownModal.images.length > 1 && (
            <>
              <ArrowLeft
                sx={{
                  top: '50%',
                  translate: '0 -50%',
                  left: '30px',
                  width: '60px',
                  height: '60px',
                }}
                onClick={() => {
                  if (isAnimationRunningRef.current) {
                    return
                  }

                  clearInterval(intervalRef.current)

                  const imagesCount = isShownModal.images.length

                  if (activeImageIndex + 1 > imagesCount - 1) {
                    setActiveImageIndex(undefined)
                  }

                  setTimeout(() => {
                    setActiveImageIndex((prevState) => {
                      if (prevState === undefined) {
                        return 0
                      }

                      return prevState + 1
                    })
                  }, 100)

                  isAnimationRunningRef.current = true

                  setTimeout(() => {
                    isAnimationRunningRef.current = false
                  }, 1200)

                  startInterval()
                }}
              />

              <ArrowRight
                sx={{
                  top: '50%',
                  translate: '0 -50%',
                  right: '30px',
                  width: '60px',
                  height: '60px',
                }}
                onClick={() => {
                  if (isAnimationRunningRef.current) {
                    return
                  }

                  clearInterval(intervalRef.current)

                  const imagesCount = isShownModal.images.length

                  if (activeImageIndex + 1 > imagesCount - 1) {
                    setActiveImageIndex(undefined)
                  }

                  setTimeout(() => {
                    setActiveImageIndex((prevState) => {
                      if (prevState === undefined) {
                        return 0
                      }

                      return prevState + 1
                    })
                  }, 100)

                  isAnimationRunningRef.current = true

                  setTimeout(() => {
                    isAnimationRunningRef.current = false
                  }, 1200)

                  startInterval()
                }}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ModalContent
