import React from 'react'
import Box from '@mui/material/Box'
import InfoText from './InfoText'
import SliderWrapper from '../Slider/SliderWrapper'
import { useLanguageContext } from '../../contexts/LanguageContext'
import Button from '../../components/Buttons/Button1'
import BackgroundWave from '../../v2/components/BackgroundWave/BackgroundWave'
import background from '../../assets/kurz2024/bg2.png'
import popup1 from '../../assets/kurz2024/popup1.png'
import explain1 from '../../assets/kurz2024/explain1.png'
import browsers from '../../assets/kurz2024/browsers.png'
import fonts from '../../styles/fonts'

const Info = ({ goToNextSlide }) => {
  const { setLanguage } = useLanguageContext()

  return (
    <SliderWrapper>
      <BackgroundWave
        background={background}
        sx={{
          background: 'linear-gradient(90deg, #6936C5 0%, #5C2A93 100%)',
        }}
      />

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '83vw',
          }}
        >
          <Box
            component={'img'}
            src={popup1}
            sx={{
              width: '100%',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '0% 5%',
            }}
          >
            <Box
              component={'img'}
              src={browsers}
              sx={{
                width: '21.8%',
              }}
            />

            <Box
              sx={{
                maxWidth: '1200px',
                textAlign: 'center',
                ...fonts.Urbanist['32px']({
                  fontWeight: '500',
                  color: '#000',
                }),
                '& b': {
                  color: '#6936E5',
                },
              }}
            >
              <b>
                Kurz nie je určený pre mobilné telefóny. Minimálne odporúčané
                rozlíšenie monitora je 1280x720px, pomer strán 16:9.
              </b>{' '}
              Pre správne zobrazenie kurzu použite prehliadače{' '}
              <b>Egde, Chrome alebo Firefox.</b>
              Používateľom MacOS je potrebné nainštalovať najnovšiu aktualizáciu
              operačného systému. <br />
              <br />{' '}
              <b>
                The course is not developed for mobile phones. The minimum
                recommended monitor resolution is 1280x720px, 16:9 aspect ratio.
              </b>{' '}
              Recommended browsers are <b>Chrome, Edge or Firefox.</b>
              MacOS users must install the latest operating system update.
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '4vw',
                marginTop: '26px',
              }}
            >
              <Button
                sx={{
                  minWidth: '250px',
                }}
                text={'VSTÚPIŤ'}
                onClick={() => {
                  goToNextSlide()
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SliderWrapper>
  )
}

export default Info
