import React from 'react'
import Box from '@mui/material/Box'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { fadeIn } from '../../styles/animations'

const Slider = ({ slides, sliderRef, initialSlide }) => {
  const settings = {
    dots: false,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    draggable: false,
    touchMove: false,
    initialSlide: initialSlide ?? 0,
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        opacity: '0',
        transition: 'opacity 500ms ease 0s',
        animation: `${fadeIn} 500ms ease forwards`,
        '& .slick-slider, & .slick-list': {
          width: '100%',
          height: '100%',
        },
        '& .slick-track': {
          height: '100%',
        },
        '& .slick-slide': {
          pointerEvents: 'none',
          zIndex: '-1',
        },
        '& .slick-active': {
          pointerEvents: 'unset',
          zIndex: 'unset',
        },
        '& .slick-slide > div': {
          width: '100%',
          height: '100%',
        },
      }}
    >
      <SlickSlider ref={sliderRef} {...settings}>
        {slides}
      </SlickSlider>
    </Box>
  )
}

export default Slider
