import voiceoverK0201SK from '../../assets/kurz2024/VOICEOVER/SK/VO/oprava K02 1.mp3'
import voiceoverK0201EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.1.mp3'

import voiceoverK0202SK from '../../assets/kurz2024/VOICEOVER/SK/VO/oprava K02 1.mp3'
import voiceoverK0202EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.2.mp3'

import voiceoverK0203SK from '../../assets/kurz2024/VOICEOVER/SK/VO/2.3.mp3'
import voiceoverK0203EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.3.mp3'

import voiceoverK0204SK from '../../assets/kurz2024/VOICEOVER/SK/VO/2.4.mp3'
import voiceoverK0204EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.4.mp3'

import voiceoverK0205SK from '../../assets/kurz2024/VOICEOVER/SK/VO/2.4.mp3'
import voiceoverK0205EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.1.mp3'

import voiceoverK0301SK from '../../assets/kurz2024/VOICEOVER/SK/VO/3.1.mp3'
import voiceoverK0301EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.1 mp3.mp3'

import voiceoverK0302SK from '../../assets/kurz2024/VOICEOVER/SK/VO/3.2.mp3'
import voiceoverK0302EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.2.mp3'

import voiceoverK0303SK from '../../assets/kurz2024/VOICEOVER/SK/VO/3.3.mp3'
import voiceoverK0303EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.3.mp3'

import voiceoverK0304SK from '../../assets/kurz2024/VOICEOVER/SK/VO/3.4.mp3'
import voiceoverK0304EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.4..mp3'

import voiceoverK0401SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.1.1.mp3'
import voiceoverK0401EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.1.1.mp3'

import voiceoverK0402SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.1.2.mp3'
import voiceoverK0402EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.1.2.mp3'

import voiceoverK0403SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.1.3.mp3'
import voiceoverK0403EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.1.3.mp3'

import voiceoverK0404SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.1.mp3'
import voiceoverK0404EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.1.mp3'

import voiceoverK0405SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.2.mp3'
import voiceoverK0405EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.2.mp3'

import voiceoverK0406SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.3.mp3'
import voiceoverK0406EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.3.mp3'

import voiceoverK0407SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.4.mp3'
import voiceoverK0407EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.4.mp3'

import voiceoverK0408SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.5.mp3'
import voiceoverK0408EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.5.mp3'

import voiceoverK0409SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.6.mp3'
import voiceoverK0409EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.6.mp3'

import voiceoverK0410SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.7.mp3'
import voiceoverK0410EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.7.mp3'

import voiceoverK0411SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.8.mp3'
import voiceoverK0411EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.8.mp3'

import voiceoverK0412SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.9.mp3'
import voiceoverK0412EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.9.mp3'

import voiceoverK0413SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.10.mp3'
import voiceoverK0413EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.10.mp3'

import voiceoverK0414SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.11.mp3'
import voiceoverK0414EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.11.mp3'

import voiceoverK0415SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.2.12.mp3'
import voiceoverK0415EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.12.mp3'

import voiceoverK0416SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.3.1.mp3'
import voiceoverK0416EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.3.1.mp3'

import voiceoverK0417SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.3.2.mp3'
import voiceoverK0417EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.3.2.mp3'

import voiceoverK0418SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.3.3.mp3'
import voiceoverK0418EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.3.3.mp3'

import voiceoverK0419SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.4.1.mp3'
import voiceoverK0419EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.1.mp3'

import voiceoverK0420SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.4.2.mp3'
import voiceoverK0420EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.2.mp3'

import voiceoverK0421SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.4.3.mp3'
import voiceoverK0421EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.3.mp3'

import voiceoverK0422SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.4.4.mp3'
import voiceoverK0422EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.4.mp3'

import voiceoverK0423SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.4.5.mp3'
import voiceoverK0423EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.5.mp3'

import voiceoverK0424SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.4.6.mp3'
import voiceoverK0424EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.4.6.mp3'

import voiceoverK0425SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.1.mp3'
import voiceoverK0425EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.1.mp3'

import voiceoverK0426SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.2.mp3'
import voiceoverK0426EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.2.mp3'

import voiceoverK0427SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.3.mp3'
import voiceoverK0427EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.3.mp3'

import voiceoverK0428SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.4.mp3'
import voiceoverK0428EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.4.mp3'

import voiceoverK0429SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.5.mp3'
import voiceoverK0429EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.5.mp3'

import voiceoverK0430SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.6.mp3'
import voiceoverK0430EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.6.mp3'

import voiceoverK0431SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.7.mp3'
import voiceoverK0431EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.7.mp3'

import voiceoverK0432SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.8.mp3'
import voiceoverK0432EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.8.mp3'

import voiceoverK0433SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.5.9.mp3'
import voiceoverK0433EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.9.mp3'

import voiceoverK0434SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.6.mp3'
import voiceoverK0434EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.6.mp3'

import voiceoverK0435SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.7.1.mp3'
import voiceoverK0435EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.1.mp3'

import voiceoverK0436SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.7.2.mp3'
import voiceoverK0436EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.2.mp3'

import voiceoverK0437SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.7.3.mp3'
import voiceoverK0437EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.3.mp3'

import voiceoverK0438SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.7.4.mp3'
import voiceoverK0438EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.4.mp3'

import voiceoverK0439SK from '../../assets/kurz2024/VOICEOVER/SK/VO/4.7.5.mp3'
import voiceoverK0439EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.5.mp3'

import voiceoverK0501SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.1.1.mp3'
import voiceoverK0501EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.1.mp3'

import voiceoverK0502SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.1.2.mp3'
import voiceoverK0502EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.2.mp3'

import voiceoverK0503SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.1.3.mp3'
import voiceoverK0503EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.3.mp3'

import voiceoverK0504SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.1.4.mp3'
import voiceoverK0504EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.4.mp3'

import voiceoverK0505SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.2.1.mp3'
import voiceoverK0505EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.2.1.mp3'

import voiceoverK0506SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.2.2.mp3'
import voiceoverK0506EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.2.2.mp3'

import voiceoverK0507SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.2.3.mp3'
import voiceoverK0507EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.2.3.mp3'

import voiceoverK0508SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.3.1.mp3'
import voiceoverK0508EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.1.mp3'

import voiceoverK0509SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.3.2.mp3'
import voiceoverK0509EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.2.mp3'

import voiceoverK0510SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.3.3.mp3'
import voiceoverK0510EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.3.mp3'

import voiceoverK0511SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.3.4.mp3'
import voiceoverK0511EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.4.mp3'

import voiceoverK0512SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.3.5.mp3'
import voiceoverK0512EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.5.mp3'

import voiceoverK0513SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.3.6.mp3'
import voiceoverK0513EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.6.mp3'

import voiceoverK0514SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.4.1.mp3'
import voiceoverK0514EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.4.1.mp3'

import voiceoverK0515SK from '../../assets/kurz2024/VOICEOVER/SK/VO/5.4.2.mp3'
import voiceoverK0515EN from '../../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.4.2.mp3'

import imgK0401 from '../../assets/kurz2024/k0403trigger01img1.png'
import imgK0402 from '../../assets/kurz2024/k0403trigger01img2.png'
import imgK0403 from '../../assets/kurz2024/k0403trigger01img3.png'

import imgK0404 from '../../assets/kurz2024/k0403trigger02img1.png'
import imgK0405 from '../../assets/kurz2024/k0403trigger02img2.png'
import imgK0406 from '../../assets/kurz2024/k0403trigger02img3.png'

import imgK0407 from '../../assets/kurz2024/k0403trigger03img1.png'
import imgK0408 from '../../assets/kurz2024/k0403trigger03img2.png'
import imgK0409 from '../../assets/kurz2024/k0403trigger03img3.png'

import imgK0410 from '../../assets/kurz2024/k0407trigger01img1.png'
import imgK0411 from '../../assets/kurz2024/k0407trigger01img2.png'
import imgK0412 from '../../assets/kurz2024/k0407trigger01img3.png'

import imgK0413 from '../../assets/kurz2024/k0407trigger02img1.png'
import imgK0414 from '../../assets/kurz2024/k0407trigger02img2.png'
import imgK0415 from '../../assets/kurz2024/k0407trigger02img3.png'

import imgK0416 from '../../assets/kurz2024/k0407trigger03img1.png'
import imgK0417 from '../../assets/kurz2024/k0407trigger03img2.png'
import imgK0418 from '../../assets/kurz2024/k0407trigger03img3.png'

import imgK0419 from '../../assets/kurz2024/k0407trigger04img1.png'
import imgK0420 from '../../assets/kurz2024/k0407trigger04img2.png'
import imgK0421 from '../../assets/kurz2024/k0407trigger04img3.png'

import imgK0422 from '../../assets/kurz2024/k0407trigger05img1.png'
import imgK0423 from '../../assets/kurz2024/k0407trigger05img2.png'
import imgK0424 from '../../assets/kurz2024/k0407trigger05img3.png'

import imgK0501 from '../../assets/kurz2024/k050102trigger01img1.png'
import imgK0502 from '../../assets/kurz2024/k050102trigger01img2.png'
import imgK0503 from '../../assets/kurz2024/k050102trigger01img3.png'

import imgK0504 from '../../assets/kurz2024/k050102trigger03img1.png'

import imgK0505 from '../../assets/kurz2024/k050102trigger04img1.png'
import imgK0506 from '../../assets/kurz2024/k050102trigger04img2.png'
import imgK0507 from '../../assets/kurz2024/k050102trigger04img3.png'

import imgK0508 from '../../assets/kurz2024/k050103trigger01img1.png'
import imgK0509 from '../../assets/kurz2024/k050103trigger01img2.png'
import imgK0510 from '../../assets/kurz2024/k050103trigger01img3.png'

import imgK0511 from '../../assets/kurz2024/k050103trigger02img1.png'
import imgK0512 from '../../assets/kurz2024/k050103trigger02img2.png'
import imgK0513 from '../../assets/kurz2024/k050103trigger02img3.png'
import imgK0514 from '../../assets/kurz2024/k050103trigger02img4.png'

import imgK0515 from '../../assets/kurz2024/k050103trigger03img1.png'
import imgK0516 from '../../assets/kurz2024/k050103trigger03img2.png'
import imgK0517 from '../../assets/kurz2024/k050103trigger03img3.png'

import imgK0518 from '../../assets/kurz2024/k050104trigger01img1.png'
import imgK0519 from '../../assets/kurz2024/k050104trigger01img2.png'

import imgK0520 from '../../assets/kurz2024/k050104trigger02img1.png'
import imgK0521 from '../../assets/kurz2024/k050104trigger02img2.png'
import imgK0522 from '../../assets/kurz2024/k050104trigger02img3.png'
import imgK0523 from '../../assets/kurz2024/k050104trigger02img4.png'

import imgK0524 from '../../assets/kurz2024/k050104trigger03img1.png'
import imgK0525 from '../../assets/kurz2024/k050104trigger03img2.png'
import imgK0526 from '../../assets/kurz2024/k050104trigger03img3.png'

import imgK0527 from '../../assets/kurz2024/k050104trigger04img1.png'
import imgK0528 from '../../assets/kurz2024/k050104trigger04img2.png'
import imgK0529 from '../../assets/kurz2024/k050104trigger04img3.png'
import imgK0530 from '../../assets/kurz2024/k050104trigger04img4.png'

import imgK0531 from '../../assets/kurz2024/k050104trigger05img1.png'
import imgK0532 from '../../assets/kurz2024/k050104trigger05img2.png'
import imgK0533 from '../../assets/kurz2024/k050104trigger05img3.png'

import imgK0534 from '../../assets/kurz2024/k050104trigger06img1.png'
import imgK0535 from '../../assets/kurz2024/k050104trigger06img2.png'
import imgK0536 from '../../assets/kurz2024/k050104trigger06img3.png'
import imgK0537 from '../../assets/kurz2024/k050104trigger06img4.png'

import imgK0538 from '../../assets/kurz2024/k050105trigger01img1.png'
import imgK0539 from '../../assets/kurz2024/k050105trigger01img2.png'
import imgK0540 from '../../assets/kurz2024/k050105trigger01img3.png'
import imgK0541 from '../../assets/kurz2024/k050105trigger01img4.png'

import imgK0542 from '../../assets/kurz2024/k050105trigger02img1.png'
import imgK0543 from '../../assets/kurz2024/k050105trigger02img2.png'
import imgK0544 from '../../assets/kurz2024/k050105trigger02img3.png'
import imgK0545 from '../../assets/kurz2024/k050105trigger02img4.png'

import k4img1 from '../../assets/kurz2024/k4img1.png'
import k4img2 from '../../assets/kurz2024/k4img2.png'

const chapter0 = [k4img1, k4img2]
const chapter1 = []
const chapter2 = [
  voiceoverK0201SK,
  voiceoverK0201EN,
  voiceoverK0202SK,
  voiceoverK0202EN,
  voiceoverK0203SK,
  voiceoverK0203EN,
  voiceoverK0204SK,
  voiceoverK0204EN,
  voiceoverK0205SK,
  voiceoverK0205EN,
]
const chapter3 = [
  voiceoverK0301SK,
  voiceoverK0301EN,
  voiceoverK0302SK,
  voiceoverK0302EN,
  voiceoverK0303SK,
  voiceoverK0303EN,
  voiceoverK0304SK,
  voiceoverK0304EN,
]
const chapter4_1 = [
  voiceoverK0401SK,
  voiceoverK0401EN,
  voiceoverK0402SK,
  voiceoverK0402EN,
  voiceoverK0403SK,
  voiceoverK0403EN,
  voiceoverK0404SK,
  voiceoverK0404EN,
  voiceoverK0405SK,
  voiceoverK0405EN,
  voiceoverK0406SK,
  voiceoverK0406EN,
  voiceoverK0407SK,
  voiceoverK0407EN,
  voiceoverK0408SK,
  voiceoverK0408EN,
  voiceoverK0409SK,
  voiceoverK0409EN,
  voiceoverK0410SK,
  voiceoverK0410EN,
  voiceoverK0411SK,
  voiceoverK0411EN,
  voiceoverK0412SK,
  voiceoverK0412EN,
  voiceoverK0413SK,
  voiceoverK0413EN,
  voiceoverK0414SK,
  voiceoverK0414EN,
  voiceoverK0415SK,
  voiceoverK0415EN,
]
const chapter4_2 = [
  voiceoverK0416SK,
  voiceoverK0416EN,
  voiceoverK0417SK,
  voiceoverK0417EN,
  voiceoverK0418SK,
  voiceoverK0418EN,
  voiceoverK0419SK,
  voiceoverK0419EN,
  voiceoverK0420SK,
  voiceoverK0420EN,
  voiceoverK0421SK,
  voiceoverK0421EN,
  voiceoverK0422SK,
  voiceoverK0422EN,
  voiceoverK0423SK,
  voiceoverK0423EN,
  voiceoverK0424SK,
  voiceoverK0424EN,
  imgK0401,
  imgK0402,
  imgK0403,
  imgK0404,
  imgK0405,
  imgK0406,
  imgK0407,
  imgK0408,
  imgK0409,
]
const chapter4_3 = [
  voiceoverK0425SK,
  voiceoverK0425EN,
  voiceoverK0426SK,
  voiceoverK0426EN,
  voiceoverK0427SK,
  voiceoverK0427EN,
  voiceoverK0428SK,
  voiceoverK0428EN,
  voiceoverK0429SK,
  voiceoverK0429EN,
  voiceoverK0430SK,
  voiceoverK0430EN,
  voiceoverK0431SK,
  voiceoverK0431EN,
  voiceoverK0432SK,
  voiceoverK0432EN,
  voiceoverK0433SK,
  voiceoverK0433EN,
  voiceoverK0434SK,
  voiceoverK0434EN,
  voiceoverK0435SK,
  voiceoverK0435EN,
  voiceoverK0436SK,
  voiceoverK0436EN,
  voiceoverK0437SK,
  voiceoverK0437EN,
  voiceoverK0438SK,
  voiceoverK0438EN,
  voiceoverK0439SK,
  voiceoverK0439EN,
  imgK0410,
  imgK0411,
  imgK0412,
  imgK0413,
  imgK0414,
  imgK0415,
  imgK0416,
  imgK0417,
  imgK0418,
  imgK0419,
  imgK0420,
  imgK0421,
  imgK0422,
  imgK0423,
  imgK0424,
]
const chapter5 = [
  voiceoverK0501SK,
  voiceoverK0501EN,
  voiceoverK0502SK,
  voiceoverK0502EN,
  voiceoverK0503SK,
  voiceoverK0503EN,
  voiceoverK0504SK,
  voiceoverK0504EN,
  voiceoverK0505SK,
  voiceoverK0505EN,
  voiceoverK0506SK,
  voiceoverK0506EN,
  voiceoverK0507SK,
  voiceoverK0507EN,
  voiceoverK0508SK,
  voiceoverK0508EN,
  voiceoverK0509SK,
  voiceoverK0509EN,
  voiceoverK0510SK,
  voiceoverK0510EN,
  voiceoverK0511SK,
  voiceoverK0511EN,
  voiceoverK0512SK,
  voiceoverK0512EN,
  voiceoverK0513SK,
  voiceoverK0513EN,
  voiceoverK0514SK,
  voiceoverK0514EN,
  voiceoverK0515SK,
  voiceoverK0515EN,
  imgK0501,
  imgK0502,
  imgK0503,
  imgK0504,
  imgK0505,
  imgK0506,
  imgK0507,
  imgK0508,
  imgK0509,
  imgK0510,
  imgK0511,
  imgK0512,
  imgK0513,
  imgK0514,
  imgK0515,
  imgK0516,
  imgK0517,
  imgK0518,
  imgK0519,
  imgK0520,
  imgK0521,
  imgK0522,
  imgK0523,
  imgK0524,
  imgK0525,
  imgK0526,
  imgK0527,
  imgK0528,
  imgK0529,
  imgK0530,
  imgK0531,
  imgK0532,
  imgK0533,
  imgK0534,
  imgK0535,
  imgK0536,
  imgK0537,
  imgK0538,
  imgK0539,
  imgK0540,
  imgK0541,
  imgK0542,
  imgK0543,
  imgK0544,
  imgK0545,
]
const chapter7 = []
const chapter8 = []
const quiz = []

export default {
  chapter0,
  chapter1,
  chapter2,
  chapter3,
  chapter4_1,
  chapter4_2,
  chapter4_3,
  chapter5,
  chapter7,
  chapter8,
  quiz,
}
