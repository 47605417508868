import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Slider from '../Slider/Slider'
import { useLanguageContext } from '../../contexts/LanguageContext'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'
import chaptersAssets from '../../v2/assets/chapters'

const MemoComponent = memo(({ Component, ...props }) => {
  delete props['style']

  return <Component {...props} />
})

const Chapter = ({ slides, chapters, index, url, name }) => {
  const { language, loadedChapters } = useLanguageContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const sliderRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)
  const loadedAssets = useRef([])

  const goPrevFunctions = useMemo(
    () =>
      slides.map(
        (slide, slideIndex) => () =>
          slideIndex === 0 && index > 0
            ? navigate(
                `${chapters[index - 1].url}?slide=${
                  chapters[index - 1].slides.length - 1
                }`
              )
            : setSearchParams({ slide: slideIndex - 1 })
      ),
    []
  )

  const goNextFunctions = useMemo(
    () =>
      slides.map((slide, slideIndex) => () => {
        return slideIndex === slides.length - 1 && index !== chapters.length - 1
          ? navigate(`${chapters[index + 1].url}?slide=0`)
          : setSearchParams({ slide: slideIndex + 1 })
      }),
    []
  )

  const getInitialSlide = () => {
    if (searchParams.get('slide')) {
      if (
        searchParams.get('slide') >= 0 &&
        slides.length >= searchParams.get('slide')
      ) {
        return Number(searchParams.get('slide'))
      }
    }

    return 0
  }

  function isObject(o, strict = true) {
    if (o === null || o === undefined) {
      return false
    }
    const instanceOfObject = o instanceof Object
    const typeOfObject = typeof o === 'object'
    const constructorUndefined = o.constructor === undefined
    const constructorObject = o.constructor === Object
    const typeOfConstructorObject = typeof o.constructor === 'function'
    let r
    if (strict === true) {
      r =
        (instanceOfObject || typeOfObject) &&
        (constructorUndefined || constructorObject)
    } else {
      r = constructorUndefined || typeOfConstructorObject
    }
    return r
  }

  sliderRef.current?.slickGoTo(getInitialSlide())

  const assets = chaptersAssets[name]

  const checkIfAllAssetsLoaded = () => {
    console.log(assets.length, loadedAssets.current.length)
    if (assets.length === loadedAssets.current.length) {
      loadedChapters[name] = true
      setIsLoaded(true)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 1000)

    if (!assets) {
      return setIsLoaded(true)
    }

    if (loadedChapters[name]) {
      return setIsLoaded(true)
    }

    assets.forEach((img) => {
      const link = document.createElement('link')

      link.rel = 'prefetch'
      link.href = img

      document.head.appendChild(link)
    })
  }, [])

  useEffect(() => {
    return () => window.Howler?.stop()
  })

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          zIndex: '1000',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          background: '#6936C5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: isLoaded ? '0' : '1',
          pointerEvents: isLoaded ? 'none' : undefined,
          transition: 'opacity 500ms ease 0s',
        }}
      >
        <CircularProgress
          size={80}
          sx={{
            color: '#00FFFF',
          }}
        />
      </Box>

      <Slider
        sliderRef={sliderRef}
        slides={slides.map((Slide, slideIndex) =>
          isObject(Slide) ? (
            <MemoComponent
              key={`${url}.${name}.${slideIndex}`}
              isActive={slideIndex === getInitialSlide()}
              Component={Slide.Component}
              goToPrevSlide={goPrevFunctions[slideIndex]}
              goToNextSlide={goNextFunctions[slideIndex]}
              chapter={name}
              language={language}
              {...Slide.props}
            />
          ) : (
            <MemoComponent
              key={`${url}.${name}.${slideIndex}`}
              isActive={slideIndex === getInitialSlide()}
              Component={Slide}
              goToPrevSlide={goPrevFunctions[slideIndex]}
              goToNextSlide={goNextFunctions[slideIndex]}
              chapter={name}
              language={language}
            />
          )
        )}
        name={name}
        url={url}
        goPrevFunctions={goPrevFunctions}
        goNextFunctions={goNextFunctions}
        initialSlide={getInitialSlide()}
      />
    </>
  )
}

export default Chapter
