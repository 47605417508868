import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

const AnimationNavContext = createContext({})

const AnimationNavContextProvider = ({ children }) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const [nav1ActiveSlide, setNav1ActiveSlide] = useState(0)
  const [isShownNav1, setIsShown1Nav] = useState(false)

  const [nav2ActiveSlide, setNav2ActiveSlide] = useState(0)
  const [isShownNav2, setIsShown2Nav] = useState(false)

  const [nav3ActiveSlide, setNav3ActiveSlide] = useState(0)
  const [isShownNav3, setIsShown3Nav] = useState(false)

  const timeoutRef = useRef()
  const timeoutIsChangingRef = useRef()
  const isChangingRef = useRef(false)

  useEffect(() => {
    const slideNumber = Number(searchParams.get('slide'))

    let shouldShowNav1 =
      location.pathname?.includes('chapter-4_1') &&
      slideNumber >= 7 &&
      slideNumber <= 17

    if (shouldShowNav1) {
      setNav1ActiveSlide(slideNumber - 7)
      setIsShown1Nav(true)
    } else {
      setIsShown1Nav(false)
    }

    let shouldShowNav2 =
      location.pathname?.includes('chapter-4_2') &&
      slideNumber >= 4 &&
      slideNumber <= 7

    if (shouldShowNav2) {
      setNav2ActiveSlide(slideNumber - 4)
      setIsShown2Nav(true)
    } else {
      setIsShown2Nav(false)
    }

    let shouldShowNav3 =
      location.pathname?.includes('chapter-4_3') &&
      slideNumber >= 2 &&
      slideNumber <= 9

    if (shouldShowNav3) {
      setNav3ActiveSlide(slideNumber - 2)
      setIsShown3Nav(true)
    } else {
      setIsShown3Nav(false)
    }
  }, [location.pathname, searchParams.get('slide')])

  return (
    <AnimationNavContext.Provider
      value={{
        nav1ActiveSlide,
        setNav1ActiveSlide,
        isShownNav1,
        setIsShown1Nav,
        timeoutRef,
        timeoutIsChangingRef,
        isChangingRef,
        nav2ActiveSlide,
        setNav2ActiveSlide,
        isShownNav2,
        setIsShown2Nav,
        nav3ActiveSlide,
        setNav3ActiveSlide,
        isShownNav3,
        setIsShown3Nav,
      }}
    >
      {children}
    </AnimationNavContext.Provider>
  )
}

const useAnimationNavContext = () => useContext(AnimationNavContext)

export { AnimationNavContextProvider, useAnimationNavContext }
