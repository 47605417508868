import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

const LanguageContext = createContext({})

const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('lang') || 'sk')
  const loadedChapters = useRef({})

  useEffect(() => {
    localStorage.setItem('lang', language)
  }, [language])

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        loadedChapters,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

const useLanguageContext = () => useContext(LanguageContext)

export { useLanguageContext, LanguageContextProvider }
