import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import {hoverTransition, moveRight} from '../../../styles/hover'
import useSound from 'use-sound'
import hoverSound from '../../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../../assets/sounds/2_Ikon_Click.wav'

const ButtonRight = ({
                         buttonRef,
                         isShown,
                         onClick,
                         sx,
    variant = 0,
                     }) => {
    const [playHover] = useSound(hoverSound, {
        interrupt: true,
    })
    const [playClick] = useSound(clickSound, {
        interrupt: true,
    })

    const isSoundActive = useRef(false)

    useEffect(() => {
        isSoundActive.current = false

        setTimeout(() => {
            isSoundActive.current = true
        }, 200)
    })

    return (
        <Box
            ref={buttonRef}
            sx={{
                position: 'absolute',
                right: '1.5%',
                cursor: 'pointer',
                opacity: isShown ? '1' : '0',
                pointerEvents: isShown ? 'all' : 'none',
                transition: 'opacity 500ms ease 0s',
                zIndex: '1',
                height: '7.4vh',
                aspectRatio: '1/1',
                top: '50%',
                translate: '0 -50%',
                borderRadius: '50%',
                ...hoverTransition,
                '&:hover': {
                    ...moveRight,
                },
                ...sx,
            }}
            onMouseEnter={() => {
                isSoundActive.current && playHover()
            }}
            onClick={(e) => {
                playClick()
                onClick(e)
            }}
        >
            {variant === 0 && (
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 70 70"
                    fill="none"
                    sx={{
                        '& circle': {
                            transition: '0.3s ease-out',
                        },
                        '&:hover': {
                            '& circle': {
                                fill: '#fff',
                            },
                        },
                    }}
                >
                    <circle cx="35" cy="35" r="35" fill="#3AD3A4"/>
                    <path d="M29.9546 19.3848L47.9969 35.3371L30.0393 51.3847" stroke="#112232" strokeWidth="3"/>
                </Box>
            )}

            {variant === 1 && (
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 70 70"
                    fill="none"
                    sx={{
                        '& circle': {
                            transition: '0.3s ease-out',
                        },
                        '& path': {
                            transition: '0.3s ease-out',
                        },
                        '&:hover': {
                            '& circle': {
                                fill: '#3AD3A4',
                            },
                            '& path': {
                                stroke: '#112232',
                            },
                        },
                    }}
                >
                    <circle cx="35" cy="35" r="35" fill="#112232"/>
                    <path d="M29 19L47 35L29 51" stroke="#3AD3A4" strokeWidth="3"/>
                </Box>
            )}
        </Box>
    )
}

export default ButtonRight
