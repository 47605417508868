import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import { useAppContext } from '../../contexts/AppContext'
import buttonClose from '../../assets/kurz2024/buttonMenuClose.png'
import buttonOpen from '../../assets/kurz2024/buttonMenu.png'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'

const ButtonMenu = ({ type, sx }) => {
  const { isShownMenu, setIsShownMenu } = useAppContext()

  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const onClick = () => setIsShownMenu(!isShownMenu)

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      component={'img'}
      src={type === 'close' ? buttonClose : buttonOpen}
      sx={{
        position: 'absolute',
        top: '22px',
        left: '22px',
        cursor: 'pointer',
        zIndex: '1',
        width: '4vw',
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    />
  )
}

export default ButtonMenu
