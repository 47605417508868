import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'
import { hoverTransition, moveUp } from '../../styles/hover'
import quizAnswer from '../../assets/kurz2024/quiz-answer.svg'
import quizAnswerHover from '../../assets/kurz2024/quiz-answer-hover.svg'

const QuizQuestion2 = ({ index, question, onClick, language, activeSlide }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const [answeredIndex, setAnsweredIndex] = useState(false)

  const onAnswer = (answer) => onClick(index, answer)

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  useEffect(() => {
    if (activeSlide >= index) {
      setAnsweredIndex(false)
    }
  }, [index, activeSlide])

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          top: '0%',
          display: 'flex',
          alignItems: 'center',
          gap: '5vw',
          padding: '0 5vw',
          flex: '1',
        }}
      >
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Box
            component={'img'}
            src={question.img}
            sx={{
              width: '100%',
              position: 'absolute',
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3vh',
            alignItems: 'center',
            flexBasis: '61%',
          }}
        >
          <Box
            sx={{
              ...fonts.Urbanist['48px']({
                color: '#fff',
                fontWeight: '900',
                lineHeight: 62,
                letterSpacing: 1,
              }),
              textShadow: '6px 6px 0px #000',
              textAlign: 'center',
              textTransform: 'uppercase',
              marginBottom: '8vh',
            }}
          >
            {question.question[language]}
          </Box>

          {question.options[language].map((option, index) => (
            <Box
              key={option}
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                cursor: 'pointer',
                '&:hover': {
                  '& img:nth-of-type(2)': {
                    opacity: '1',
                  },
                },
              }}
              onMouseEnter={() => {
                isSoundActive.current && playHover()
              }}
              onClick={() => {
                playClick()
                onAnswer(index)
                setAnsweredIndex(index)
              }}
            >
              <Box
                component={'img'}
                src={quizAnswer}
                sx={{
                  width: '100%',
                  maxWidth: '1045px',
                }}
              />

              <Box
                component={'img'}
                src={quizAnswerHover}
                sx={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  opacity: answeredIndex === index ? '1' : '0',
                  transition: '0.3s',
                }}
              />

              <Box
                sx={{
                  position: 'absolute',
                  top: '-6px',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 25px',
                }}
              >
                <Box
                  sx={{
                    ...fonts.Urbanist['48px']({
                      color: '#000',
                      fontWeight: '900',
                      lineHeight: 62,
                      letterSpacing: 1,
                    }),
                  }}
                >
                  {index === 0 ? 'A' : index === 1 ? 'B' : 'C'}
                </Box>

                <Box
                  sx={{
                    ...fonts.Urbanist['22px']({
                      color: '#000',
                      fontWeight: '900',
                    }),
                    flex: '1',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '0 0 0 3%',
                  }}
                >
                  {option}
                </Box>
              </Box>

              {/*<Box*/}
              {/*  sx={{*/}
              {/*    position: 'relative',*/}
              {/*    display: 'flex',*/}
              {/*    alignItems: 'center',*/}
              {/*    justifyContent: 'center',*/}
              {/*    marginRight: '3.7%',*/}
              {/*    minWidth: '70px',*/}
              {/*    aspectRatio: '1/1',*/}
              {/*    cursor: 'pointer',*/}
              {/*    background: '#112232',*/}
              {/*    borderRadius: '50%',*/}
              {/*    ...hoverTransition,*/}
              {/*    '&:hover': {*/}
              {/*      ...moveUp,*/}
              {/*      background: '#3AD3A4',*/}
              {/*      '& p': {*/}
              {/*        ...hoverTransition,*/}
              {/*        color: '#112232',*/}
              {/*      },*/}
              {/*    },*/}
              {/*  }}*/}
              {/*  onMouseEnter={() => {*/}
              {/*    isSoundActive.current && playHover()*/}
              {/*  }}*/}
              {/*  onClick={() => {*/}
              {/*    playClick()*/}
              {/*    onAnswer(index)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Typography*/}
              {/*    sx={{*/}
              {/*      ...fonts.Rubik['32px']({*/}
              {/*        color: '#3AD3A4',*/}
              {/*        fontWeight: '600',*/}
              {/*        lineHeight: 32,*/}
              {/*      }),*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {index === 0 ? 'A' : index === 1 ? 'B' : 'C'}*/}
              {/*  </Typography>*/}
              {/*</Box>*/}

              {/*<Typography*/}
              {/*  sx={{*/}
              {/*    ...fonts.Rubik['32px']({*/}
              {/*      color: '#3AD3A4',*/}
              {/*    }),*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {option}*/}
              {/*</Typography>*/}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default QuizQuestion2
