import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import buttonPlay from '../../assets/kurz2024/buttonPlay.png'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'

const ButtonPause = ({ chapter, sx, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      component={'img'}
      src={buttonPlay}
      sx={{
        position: 'absolute',
        top: '22px',
        right: '113px',
        cursor: 'pointer',
        zIndex: '1',
        width: '4vw',
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    />
  )
}

export default ButtonPause
