import React from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../Slider/SliderWrapper'
import partnersSK from '../../assets/kurz2024/partners-sk.jpg'
import partnersEN from '../../assets/kurz2024/partners-en.jpg'
import ButtonNextChapter from '../Buttons/ButtonNextChapter'
import { useNavigate } from 'react-router-dom'
import ButtonLeft from '../../components/Buttons/ButtonLeft'
import ButtonRight from '../../components/Buttons/ButtonRight'
import ButtonMenu from '../Buttons/ButtonMenu'

const Partners = ({ isEnding, language, goToPrevSlide, goToNextSlide }) => {
  const navigate = useNavigate()

  const partners = {
    sk: partnersSK,
    en: partnersEN,
  }[language]

  return (
    <SliderWrapper
      flexCenter
      sx={{
        background: '#fff',
      }}
    >
      <Box
        component={'img'}
        src={partners}
        sx={{
          maxWidth: '100%',
          maxHeight: '100vh',
          objectFit: 'contain',
        }}
      />

      <ButtonLeft isShown onClick={goToPrevSlide} />

      {!isEnding && <ButtonRight isShown onClick={goToNextSlide} />}

      {isEnding && <ButtonMenu />}
    </SliderWrapper>
  )
}

export default Partners
