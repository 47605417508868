import SliderWrapper from '../../../components/Slider/SliderWrapper'
import React, { memo, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import useSound from 'use-sound'
import ButtonMenu from '../../../components/Buttons/ButtonMenu'
import ButtonLeft from '../../../components/Buttons/ButtonLeft'
import ButtonRight from '../../../components/Buttons/ButtonRight'
import Typography from '@mui/material/Typography'
import fonts from '../../../styles/fonts'
import ButtonPlay from '../../../components/Buttons/ButtonPlay'
import ButtonPause from '../../../components/Buttons/ButtonPlay'
import ButtonRead from '../../../components/Buttons/ButtonRead'
import ButtonClose from '../../../components/Buttons/ButtonClose'
import { MouseParallax } from 'react-just-parallax'
import ReactPlayer from 'react-player'
import AdditionalStudy from '../AdditionalStudy/AdditionalStudy'
import Source from '../Source'
import Animated2Nav from './Animated2Nav'
import BackgroundWave from '../BackgroundWave/BackgroundWave'
import popup1 from '../../../assets/kurz2024/popup1.png'
import header1 from '../../../assets/kurz2024/header1.png'
import Lottie from 'react-lottie'

const Animated2 = ({
  animation,
  disableAnimationLoop,
  animationLottie,
  voiceover,
  isActive,
  language,
  text,
  title,
  goToPrevSlide,
  goToNextSlide,
  extra,
  images,
  imagesSwitch,
  imagesLang,
  loop = true,
  additionalStudy,
  source,
  activeSlide,
  bg,
  bgAnimation,
  modal,
  header,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isShownOverlay, setIsShownOverlay] = useState(false)
  const [isSwitched, setIsSwitched] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(voiceover?.[language], {
    onload: () => {
      setIsLoaded(true)
    },
    onend: () => {
      setIsPlaying(false)
    },
  })

  const reactPlayerRef = useRef()

  const onPlay = () => {
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        setTimeout(() => {
          onPlay()
        }, 500)
      } else {
        onStop()
      }
    }

    return () => {
      onStop()
    }
  }, [isLoaded, isActive])

  useEffect(() => {
    setIsLoaded(false)
    onStop()
  }, [language])

  useEffect(() => {
    if (!reactPlayerRef.current) {
      return
    }

    if (!isActive) {
      reactPlayerRef.current.seekTo(0)
    }
  }, [isActive])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <SliderWrapper flexCenter>
      {bg && <BackgroundWave disabled={!bgAnimation} background={bg} />}

      <ButtonMenu
        variant={1}
        sx={{
          zIndex: '20',
        }}
      />

      {voiceover && (
        <>
          <ButtonPlay
            sx={{
              zIndex: '20',
              opacity: isPlaying ? '1' : '0',
              pointerEvents: !isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPause}
          />

          <ButtonPause
            sx={{
              zIndex: '20',
              opacity: !isPlaying ? '1' : '0',
              pointerEvents: isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPlay}
          />
        </>
      )}

      {text && (
        <>
          {/*<ButtonPlay*/}
          {/*  variant={1}*/}
          {/*  sx={{*/}
          {/*    zIndex: '23',*/}
          {/*    opacity: isShownOverlay && !isPlaying ? '1' : '0',*/}
          {/*    pointerEvents: isShownOverlay && isPlaying ? 'none' : 'all',*/}
          {/*    transition: 'opacity 500ms ease 0s',*/}
          {/*    right: '116px',*/}
          {/*  }}*/}
          {/*  onClick={onPlay}*/}
          {/*/>*/}

          {/*<ButtonPause*/}
          {/*  variant={1}*/}
          {/*  sx={{*/}
          {/*    zIndex: '23',*/}
          {/*    opacity: isShownOverlay && isPlaying ? '1' : '0',*/}
          {/*    pointerEvents: isShownOverlay && !isPlaying ? 'none' : 'all',*/}
          {/*    transition: 'opacity 500ms ease 0s',*/}
          {/*    right: '116px',*/}
          {/*  }}*/}
          {/*  onClick={onPause}*/}
          {/*/>*/}

          <ButtonRead
            sx={{
              zIndex: '24',
              opacity: !isShownOverlay ? '1' : '0',
              pointerEvents: isShownOverlay ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={() => setIsShownOverlay(!isShownOverlay)}
          />

          <ButtonClose
            sx={{
              zIndex: '24',
              opacity: isShownOverlay ? '1' : '0',
              pointerEvents: !isShownOverlay ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={() => setIsShownOverlay(!isShownOverlay)}
          />
        </>
      )}

      <ButtonLeft
        isShown
        variant={1}
        onClick={goToPrevSlide}
        sx={{
          zIndex: '18',
        }}
      />

      <ButtonRight
        isShown
        variant={1}
        onClick={goToNextSlide}
        sx={{
          zIndex: '18',
        }}
      />

      {animation && (
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',
              position: 'absolute',
              objectFit: 'cover',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                position: 'absolute',
                '& video': {
                  objectFit: 'cover',
                },
              }}
            >
              <ReactPlayer
                ref={reactPlayerRef}
                url={animation}
                playing={isActive}
                loop={!disableAnimationLoop}
                muted
                width={'100%'}
                height={'100%'}
              />
            </Box>
          </Box>
        </Box>
      )}

      {animationLottie && (
        <Box
          sx={{
            pointerEvents: 'none',
            '& div': {
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100% !important',
              height: '100% !important',
            },
            '& svg': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              translate: '-50% -50%',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          }}
        >
          {/*<ReactPlayer url={animation[language]} playing={isActive} loop muted />*/}
          <Lottie
            options={defaultOptions}
            // isStopped={!isActive}
            // isPaused={!isActive}
          />
        </Box>
      )}

      {header && (
        <Box
          sx={{
            position: 'absolute',
            top: '10vh',
            left: '50%',
            translate: '-50% 0',
            width: '30vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            component={'img'}
            src={header1}
            sx={{
              width: '100%',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              ...fonts.Urbanist['36px']({
                color: '#fff',
                fontWeight: '900',
                lineHeight: 40,
              }),
              textAlign: 'center',
              marginTop: '-6px',
            }}
          >
            {header[language]}
          </Box>
        </Box>
      )}

      {images && (
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        >
          {images?.map((image) => (
            <Box
              key={image.image}
              sx={{
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                position: 'absolute',
                objectFit: 'cover',
                ...image.sx,
              }}
            >
              {image.parallax ? (
                <MouseParallax
                  key={image.image}
                  strength={0.08}
                  lerpEase={0.15}
                >
                  <Box
                    component={'img'}
                    src={image.image}
                    sx={{
                      width: '100%',
                      height: '100%',
                      top: '0',
                      left: '0',
                      position: 'absolute',
                      objectFit: 'cover',
                      ...image.sx,
                    }}
                  />
                </MouseParallax>
              ) : (
                <Box
                  key={image.image}
                  component={'img'}
                  src={image.image}
                  sx={{
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    position: 'absolute',
                    objectFit: 'cover',
                    ...image.sx,
                  }}
                />
              )}
            </Box>
          ))}

          {imagesSwitch && (
            <>
              {imagesSwitch.map((image) => (
                <Box
                  key={image.image}
                  sx={{
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    position: 'absolute',
                    objectFit: 'cover',
                    ...image.sx,
                  }}
                >
                  {image.parallax ? (
                    <MouseParallax
                      key={image.image}
                      strength={0.08}
                      lerpEase={0.15}
                    >
                      <Box
                        component={'img'}
                        src={image.image}
                        sx={{
                          width: '100%',
                          height: '100%',
                          top: '0',
                          left: '0',
                          position: 'absolute',
                          objectFit: 'cover',
                          opacity: isSwitched ? '1' : '0',
                          transition: 'opacity 500ms ease 0s',
                          ...image.sx,
                        }}
                      />
                    </MouseParallax>
                  ) : (
                    <Box
                      key={image.image}
                      component={'img'}
                      src={image.image}
                      sx={{
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                        objectFit: 'cover',
                        opacity: isSwitched ? '1' : '0',
                        transition: 'opacity 500ms ease 0s',
                        ...image.sx,
                      }}
                    />
                  )}
                </Box>
              ))}
            </>
          )}

          {imagesLang &&
            imagesLang[language] &&
            imagesLang[language].map((image) => (
              <Box
                key={image.image}
                // component={'img'}
                // src={image.image}
                sx={{
                  width: '100%',
                  height: '100%',
                  top: '0',
                  left: '0',
                  position: 'absolute',
                  objectFit: 'cover',
                  ...image.sx,
                }}
              >
                {image.parallax ? (
                  <MouseParallax
                    key={image.image}
                    strength={0.08}
                    lerpEase={0.15}
                  >
                    <Box
                      component={'img'}
                      src={image.image}
                      sx={{
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                        position: 'absolute',
                        objectFit: 'cover',
                        ...image.sx,
                      }}
                    />
                  </MouseParallax>
                ) : (
                  <Box
                    key={image.image}
                    component={'img'}
                    src={image.image}
                    sx={{
                      width: '100%',
                      height: '100%',
                      top: '0',
                      left: '0',
                      position: 'absolute',
                      objectFit: 'cover',
                      ...image.sx,
                    }}
                  />
                )}
              </Box>
            ))}
        </Box>
      )}

      {text && (
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            opacity: isShownOverlay ? '1' : '0',
            pointerEvents: !isShownOverlay ? 'none' : 'all',
            transition: 'opacity 500ms ease 0s',
            background:
              'linear-gradient(76deg, rgba(105, 54, 197, 0.95) 3.42%, rgba(92, 42, 147, 0.95) 97.76%)',
            zIndex: '22',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              ...fonts.Urbanist['48px']({
                color: '#fff',
                fontWeight: '500',
                lineHeight: 57,
              }),
              textAlign: 'center',
              maxWidth: '75%',
              '& b': {
                color: '#fff',
              },
            }}
          >
            {text[language]}
          </Typography>
        </Box>
      )}

      {extra && extra[language]}

      {additionalStudy && (
        <AdditionalStudy
          additionalStudy={additionalStudy}
          language={language}
        />
      )}

      {source && <Source>{source}</Source>}

      {modal && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '83vw',
            }}
          >
            <Box
              component={'img'}
              src={popup1}
              sx={{
                width: '100%',
              }}
            />

            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: '2% 5% 2% 2%',
                gap: '3vw',
              }}
            >
              <Box
                component={'img'}
                src={modal.img}
                sx={{
                  width: '50%',
                }}
              />

              <Box
                sx={{
                  flex: '1',
                  maxWidth: '600px',
                  ...fonts.Urbanist['36px']({
                    color: '#000',
                    fontWeight: '500',
                    lineHeight: 46,
                    letterSpacing: 0.72,
                  }),
                  '& b': {
                    color: '#6936E5',
                  },
                }}
              >
                {modal.text[language]}
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Animated2Nav activeSlide={activeSlide} />
    </SliderWrapper>
  )
}

export default memo(Animated2)
