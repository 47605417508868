import React from 'react'
import Box from '@mui/material/Box'
import SliderWrapper from '../Slider/SliderWrapper'
import fonts from '../../styles/fonts'
import background from '../../assets/kurz2024/bg5.png'
import BackgroundWave from '../../v2/components/BackgroundWave/BackgroundWave'
import ButtonLeft from '../Buttons/ButtonLeft'
import ButtonRight from '../Buttons/ButtonRight'
import popup1 from '../../assets/kurz2024/popup1.png'
import ButtonMenu from '../Buttons/ButtonMenu'

const textSK = (
  <Box
    sx={{
      textAlign: 'center',
      color: '#000',
      ...fonts.Urbanist['40px']({
        fontWeight: '500',
        lineHeight: 50,
      }),
    }}
  >
    Ako každý rok, aj tento rok sa tešíme aj na{' '}
    <b style={{ color: '#6936E5' }}>
      Vaše výtvarné práce na tému „Obnova pamiatok“
    </b>
    .  Môžete si zvoliť akúkoľvek{' '}
    <b style={{ color: '#6936E5' }}>
      historickú budovu vo svojom okolí a navrhnúť, ako by ste ju
      zrekonštruovali či zmodernizovali, prípadne na aký účel by mohla slúžiť.
    </b>{' '}
    Alebo si vyberte niektorú budovu z tých, ktoré sme predstavili v e-kurze,
    a namaľujte Vašu predstavu jej konverzie či adaptácie. Je to na Vás a my sa
    budeme tešiť na Vaše výtvarné práce na{' '}
    <b style={{ color: '#6936E5' }}>
      výkresoch formátov A3 alebo A2. Z opačnej strany výkresu napíšte meno
      a školu autora, vek žiaka a kontakt na učiteľa alebo rodiča,
    </b>{' '}
    ktorého budeme kontaktovať v prípade, že výtvarnú prácu vyberie porota na
    ocenenie.
    <br />
    <br />
    Výtvarné práce posielajte na adresu: <br />{' '}
    <b style={{ color: '#6936E5' }}>
      OZ EUROARCH, D. Lalíková, Na varte 4/C, 83101 Bratislava do 31. januára
      2025.
    </b>
  </Box>
)

const textEN = (
  <Box
    sx={{
      textAlign: 'center',
      color: '#000',
      ...fonts.Urbanist['40px']({
        fontWeight: '500',
        lineHeight: 50,
      }),
    }}
  >
    As every year, we look forward to{' '}
    <b style={{ color: '#6936E5' }}>
      receiving your artwork on the theme of "Restoring Monuments"
    </b>
    .  You can choose any{' '}
    <b style={{ color: '#6936E5' }}>
      historic building in your area and suggest how you would renovate or
      modernise it, or what purpose it could serve.
    </b>{' '}
    Or choose a building from those we have featured in the e-course and paint
    your idea for its conversion or adaptation. It's up to you and we look
    forward to seeing your artwork on{' '}
    <b style={{ color: '#6936E5' }}>
      On the reverse side of the drawing, write the name and school of the
      artist, the age of the pupil and the contact details of the teacher or
      parent
    </b>{' '}
    who will be contacted if the artwork is selected for an award by the judges.
    <br />
    <br />
    Artwork should be sent to: <br />{' '}
    <b style={{ color: '#6936E5' }}>
      OZ EUROARCH, D. Lalíková, Na varte 4/C, 83101 Bratislava until 31 January
      2025.
    </b>
  </Box>
)

const Intro = ({ language, goToPrevSlide, goToNextSlide }) => {
  return (
    <SliderWrapper flexCenter>
      <BackgroundWave
        disabled
        background={background}
        sx={{
          background: 'linear-gradient(90deg, #6936C5 0%, #5C2A93 100%)',
        }}
      />

      <ButtonMenu />

      <ButtonLeft isShown onClick={goToPrevSlide} />

      <ButtonRight isShown onClick={goToNextSlide} />

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '83vw',
        }}
      >
        <Box
          component={'img'}
          src={popup1}
          sx={{
            width: '100%',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            padding: '4vh 6vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {language === 'sk' ? textSK : textEN}
        </Box>
      </Box>
    </SliderWrapper>
  )
}

export default Intro
