import React from "react";

import strip1Background from '../v2/assets/PROTOTYP/K08/K08-PAS.jpg'

import trigger1img1 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-1-1.jpg'
import trigger1img2 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-1-2.jpg'
import trigger1img3 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-1-3.jpg'
import trigger2img1 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-2-1.jpg'
import trigger2img2 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-2-2.jpg'
import trigger2img3 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-2-3.jpg'
import trigger3img1 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-3-1.jpg'
import trigger3img2 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-3-2.jpg'
import trigger3img3 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-3-3.jpg'
import trigger4img1 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-4-1.jpg'
import trigger5img1 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-5-1.jpg'
import trigger5img2 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-5-2.jpg'
import trigger6img1 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-6-1.jpg'
import trigger6img2 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-6-2.jpg'
import trigger6img3 from '../v2/assets/PROTOTYP/K08/K08-TRIGGER-6-3.jpg'

import trigger1voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K08_triger 1.mp3'
import trigger1voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K08_triger 1.mp3'
import trigger2voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K08_triger 2.mp3'
import trigger2voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K08_triger 2.mp3'
import trigger3voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K08_triger 3.mp3'
import trigger3voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K08_triger 3.mp3'
import trigger4voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K08_Triger 4.mp3'
import trigger4voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K08_triger 4.mp3'
import trigger5voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K08_Triger 5.mp3'
import trigger5voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K08_triger 5.mp3'
import trigger6voiceoverSK from '../v2/assets/VOICEOVER/VOICEOVER_SK/K08_Triger 6.mp3'
import trigger6voiceoverEN from '../v2/assets/VOICEOVER/VOICEOVER_EN/K08_triger 6.mp3'

const strip2Images = [
  {
    image: strip1Background,
    sx: {
      zIndex: '3',
      position: 'relative',
    },
  },
]

const strip2Triggers = [
  {
    id: 'strip2Triggers1',
    top: '32%',
    left: '14.7%',
    photos: [trigger1img1, trigger1img2, trigger1img3],
    source: '',
    voiceover: {
      sk: trigger1voiceoverSK,
      en: trigger1voiceoverEN,
    },
    title: {
      sk: <>SMART BUDOVY A ZELENÉ MESTSKÉ ŠTVRTE</>,
      en: <>Smart buildings</>,
    },
    text: {
      sk: <>Tým, že správca budovy, <b>zbiera všetky údaje o kúrení, chladení, svietení,</b> atď. a následne ich vyhodnocuje, vie spätne lepšie nastaviť systémy tak, aby ešte <b>znížil prevádzkové náklady</b> budovy a zefektívnil ich prevádzku. Smart budovy zvyčajne disponujú aj ďalšími inteligentnými systémami riadenia, napríklad zabezpečovacími systémami, atď.</>,
      en: <>By <b>collecting all data on heating, cooling, lighting,</b> etc. and then evaluating it, the building manager can retrospectively better adjust the systems to further <b>reduce the building's operating costs</b> making them more efficient. Smart buildings usually also have other smart management systems, such as alarm systems, etc.</>,
    },
  },
  {
    id: 'strip2Triggers2',
    top: '22.77%',
    left: '26.79%',
    photos: [trigger2img1, trigger2img2, trigger2img3],
    source: '',
    voiceover: {
      sk: trigger2voiceoverSK,
      en: trigger2voiceoverEN,
    },
    title: {
      sk: <>SMART BUDOVY A ZELENÉ MESTSKÉ ŠTVRTE</>,
      en: <>Smart buildings</>,
    },
    text: {
      sk: <>Predstavte si, že žijete v nejakej mestskej štvrti, kde len jedna budova je naozaj „zelená“. To je síce fajn, ale jedna zelená budova neznamená veľa pre ochranu prírody aj zdravie ľudí. Ako sa hovorí, jedna lastovička ešte jar nerobí! Ak ostatné budovy v mestskej štvrti nie sú „zelené“ a <b>spotrebúvajú veľké množstvo energie</b> z neobnoviteľných zdrojov energie na kúrenie, chladenie či svietenie, potom je celá mestská štvrť zaťažená vysokými emisiami skleníkových plynov, ktoré tieto budovy vypúšťajú do ovzdušia.</>,
      en: <>Imagine you live in some urban neighbourhood where only one building is really "green". That's fine, but one green building doesn't do much for the protection of nature and people's health. As they say, one swallow does not make a spring! If other buildings in an urban district are not "green" and <b>consume large amounts of energy</b> from non-renewable sources for heating, cooling or lighting, then the whole urban district is burdened by the high greenhouse gas emissions that these buildings emit into the air.</>,
    },
  },
  {
    id: 'strip2Triggers3',
    top: '71.66%',
    left: '33.76%',
    photos: [trigger3img1, trigger3img2, trigger3img3],
    source: '',
    voiceover: {
      sk: trigger3voiceoverSK,
      en: trigger3voiceoverEN,
    },
    title: {
      sk: <>SMART BUDOVY A ZELENÉ MESTSKÉ ŠTVRTE</>,
      en: <>Smart buildings</>,
    },
    text: {
      sk: <>Cieľom by preto malo byť, aby sa budovali <b>celé mestské štvrte ako zelené.</b> To ale nie je jednoduché, pretože nemôžeme všetky existujúce budovy zbúrať a začať stavať len zelené budovy. Ale dôležité je, aby sa existujúce budovy <b>postupne obnovovali</b> na princípoch zelenej architektúry a aby sa <b>prepájali priestory medzi budovami</b> v mestských štvrtiach zeleňou a nie betónovými parkovacími plochami.</>,
      en: <>The goal should therefore be to build <b>entire urban neighbourhoods as green.</b> But this is not easy, because we cannot demolish all existing buildings and start building only green buildings. But it is important that existing buildings are <b>gradually renovated</b> on the principles of green architecture and that <b>the spaces between buildings in urban districts are connected</b> by green spaces and not by concrete parking areas.</>,
    },
  },
  {
    id: 'strip2Triggers4',
    top: '19.9%',
    left: '51.12%',
    photos: [trigger4img1],
    source: '',
    voiceover: {
      sk: trigger4voiceoverSK,
      en: trigger4voiceoverEN,
    },
    title: {
      sk: <>SMART BUDOVY A ZELENÉ MESTSKÉ ŠTVRTE</>,
      en: <>Smart buildings</>,
    },
    text: {
      sk: <>Budovy v štvrtiach si môžu <b>vzájomne pomôcť</b> aj tým, že majú spoločnú výrobu energií z obnoviteľných zdrojov, spoločné odpadové a vodné hospodárstvo a spoločne sa starajú o verejné priestory. Tým to každého <b>vyjde lacnejšie.</b></>,
      en: <>Buildings in neighbourhoods can also <b>help each other</b> by having shared renewable energy generation, shared waste and water management and shared care of public spaces. This makes it <b>cheaper for everyone.</b></>,
    },
  },
  {
    id: 'strip2Triggers5',
    top: '78%',
    left: '78.63%',
    photos: [trigger5img1, trigger5img2],
    source: '',
    voiceover: {
      sk: trigger5voiceoverSK,
      en: trigger5voiceoverEN,
    },
    title: {
      sk: <>SMART BUDOVY A ZELENÉ MESTSKÉ ŠTVRTE</>,
      en: <>Smart buildings</>,
    },
    text: {
      sk: <>V zelených mestských štvrtiach by sa mala <b>obmedziť aj doprava,</b> resp. ju usmerniť len po obvode štvrte. Zelené mestské štvrte sa čoraz viac vo svete nazývajú aj ako <b>bezuhlíkové mestské štvrte,</b> pretože ich hlavným cieľom je znížiť produkciu skleníkových plynov najmä uhlíka do ovzdušia.</>,
      en: <>In green neighbourhoods, <b>traffic should also be reduced</b> or directed only around the perimeter of the neighbourhood. Green urban neighbourhoods are increasingly being referred to around the world as <b>carbon-free urban neighbourhoods</b> because their main objective is to reduce the production of greenhouse gases, especially carbon in the air.</>,
    },
  },
  {
    id: 'strip2Triggers6',
    top: '41.29%',
    left: '94.7%',
    photos: [trigger6img1, trigger6img2, trigger6img3],
    source: '',
    voiceover: {
      sk: trigger6voiceoverSK,
      en: trigger6voiceoverEN,
    },
    title: {
      sk: <>SMART BUDOVY A ZELENÉ MESTSKÉ ŠTVRTE</>,
      en: <>Smart buildings</>,
    },
    text: {
      sk: <>Aj Slovensko sa zaviazalo k tomu, že do roku 2030 <b>zníži emisie skleníkových plynov o 55 %</b> a dokonca do roku <b>2050</b> ich zníži úplne a staneme sa tzv. <b>uhlíkovo neutrálnym územím.</b> Je to veľká výzva, ktorá sa však dá splniť, ak všetci priložíme ruku k dielu.</>,
      en: <>Slovakia has also committed to <b>reducing greenhouse gas emissions by 55%</b> by 2030 and even reducing them completely by <b>2050</b> and becoming a so-called <b>carbon neutral territory.</b> This is a big challenge, but one that can be met if we all put our hand to the job.</>,
    },
  },
]

export { strip2Images, strip2Triggers }
