import strip1Background from '../assets/kurz2024/EXPORT/K05 pás/1 Paríž/pariz pas exportAsset17.jpg'

import trigger1voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.1.1.mp3'
import trigger1voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.1.mp3'
import k050102trigger01img1 from '../assets/kurz2024/k050102trigger01img1.png'
import k050102trigger01img2 from '../assets/kurz2024/k050102trigger01img2.png'
import k050102trigger01img3 from '../assets/kurz2024/k050102trigger01img3.png'

import trigger2voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.1.2.mp3'
import trigger2voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.2.mp3'
import k050102trigger02img1 from '../assets/kurz2024/k050102trigger02img1.png'

import trigger3voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.1.3.mp3'
import trigger3voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.3.mp3'
import k050102trigger03img1 from '../assets/kurz2024/k050102trigger03img1.png'

import trigger4voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.1.4.mp3'
import trigger4voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.1.4.mp3'
import k050102trigger04img1 from '../assets/kurz2024/k050102trigger04img1.png'
import k050102trigger04img2 from '../assets/kurz2024/k050102trigger04img2.png'
import k050102trigger04img3 from '../assets/kurz2024/k050102trigger04img3.png'

import ModalNav3 from '../components/Modal/ModalNav3'
import animation1 from '../assets/kurz2024/EXPORT/K05 pás/1 Paríž/auto pariz bodymovin.json'
import animation2 from '../assets/kurz2024/EXPORT/K05 pás/1 Paríž/trigger 3 pariz bodymovin.json'

const strip3Images = [
  {
    image: strip1Background,
    sx: {
      zIndex: '3',
      position: 'relative',
    },
  },
]

const strip3Triggers = [
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers1',
    top: '25.5%',
    left: '16%',
    photos: [],
    images: [
      {
        src: k050102trigger01img1,
        sx: {
          width: '25.6%',
          top: '0',
        },
      },
      {
        isLeftAnimation: true,
        src: k050102trigger01img2,
        sx: {
          width: '52.1%',
        },
      },
      {
        src: k050102trigger01img3,
        sx: {
          bottom: '20px',
          width: '40%',
        },
      },
    ],
    source: {
      sk: 'zdroj: www.lecarreaudutemple.eu',
      en: 'source: www.lecarreaudutemple.eu',
    },
    voiceover: {
      sk: trigger1voiceoverSK,
      en: trigger1voiceoverEN,
    },
    text: {
      sk: 'Impozantnú budovu starej tržnice, ktorá stojí neďaleko historického námestia du Temple, navrhol známy francúzsky architekt a staviteľ Jean-Charles Alphand. Tržnica bola postavená v roku 1863 a okamžite sa stala veľmi obľúbeným miestom Parížanov, kde chodili pravidelne nakupovať najmä oblečenie, látky ale aj koberce.',
      en: 'The imposing old market building, which stands near the historic Place du Temple, was designed by the well-known French architect and builder Jean-Charles Alphand. The market was built in 1863 and immediately became a very popular place for Parisians to go shopping, especially for clothes, fabrics and carpets.',
    },
  },
  {
    id: 'strip1Triggers2',
    top: '25%',
    left: '42%',
    // images: [{ src: k050102trigger02img1, sx: { width: '50%' } }],
    animations: [
      {
        animation: animation1,
        sx: {},
      },
    ],
    source: '',
    voiceover: {
      sk: trigger2voiceoverSK,
      en: trigger2voiceoverEN,
    },
    text: {
      sk:
        'Tržnica fungovala nepretržite až do roku 1976, kedy hrozilo, že sa budova bude musieť zbúrať, pretože tu investor chcel postaviť parkovisko. \n' +
        'Za záchranu tržnice sa však postavilo päťtisíc miestnych obyvateľov, ktorí proti týmto plánom podpísali petíciu.\n',
      en:
        'The market operated continuously until 1976, when the building was threatened with demolition because the investor wanted to build a car park.\n' +
        'However, five thousand local residents stood up to save the market and signed a petition against the plans.\n',
    },
  },
  {
    id: 'strip1Triggers3',
    top: '47%',
    left: '60%',
    photos: [],
    // images: [
    //   {
    //     src: k050102trigger03img1,
    //     sx: {
    //       width: '100%',
    //       height: '100%',
    //       objectFit: 'fill',
    //       maxHeight: 'unset !important',
    //     },
    //   },
    // ],
    animations: [
      {
        animation: animation2,
        sx: {
          clipPath:
            'polygon(1.4% 0%, 98.6% 0%, 100% 3%, 100% 95%, 98.5% 98.1%, 1.4% 98.1%, 0% 95%, 0% 3%)',
          width: '100%',
          height: '100%',
        },
        sx2: {
          top: '-3%',
          height: '104% !important',
        },
      },
    ],
    voiceover: {
      sk: trigger3voiceoverSK,
      en: trigger3voiceoverEN,
    },
    text: {
      sk: 'Historickú budovu s unikátnou oceľovou konštrukciou sa im podarilo zachrániť a mesto vypísalo architektonickú súťaž na jej rekonštrukciu. Cieľom rekonštrukcie bolo zachovať kultúrnu a historickú hodnotu pôvodnej budovy a navrhnúť jej prestavbu a dostavbu tak, aby mohla slúžiť na usporadúvanie rôznych kultúrnych, športových a spoločenských podujatí.',
      en: 'They managed to save the historic building with its unique steel structure and the city launched an architectural competition for its reconstruction. The aim of the reconstruction was to preserve the cultural and historical value of the original building and to design its reconstruction and extension so that it could be used to host various cultural, sporting and social events.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers4',
    top: '60%',
    left: '90%',
    images: [
      {
        src: k050102trigger04img1,
        width: 28,
        sx: {
          top: '50px',
        },
      },
      {
        src: k050102trigger04img2,
        width: 42,
        sx: {
          top: '20px',
          transform: 'translateX(70%)',
        },
      },
      {
        isLeftAnimation: true,
        src: k050102trigger04img3,
        width: 55,
        sx: {
          top: '20px',
          transform: 'translateX(-70%)',
        },
      },
    ],
    source: {
      sk: 'zdroj: www.studiomilou.sg',
      en: 'source: www.studiomilou.sg',
    },
    voiceover: {
      sk: trigger4voiceoverSK,
      en: trigger4voiceoverEN,
    },
    text: {
      sk: 'Víťazom architektonickej súťaže sa stal známy francúzsky architekt Jean Francois Milou, ktorý harmonicky skĺbil historické architektonické hodnoty s kreativitou a citlivým vkomponovaním nových prvkov. Jeho návrh navonok zachoval všetky atribúty pôvodnej budovy, najmä plne rešpektoval pôvodnú  kovovú konštrukciu v štýle parížskej architektúry devätnásteho storočia, zároveň však navrhol dobudovať pod budovu 2 podzemné podlažia, kde umiestnil priestory pre športové podujatia a do existujúcich priestorov bývalej tržnice navrhol priestory pre usporadúvanie kultúrnych a spoločenských podujatí.',
      en: 'The winner of the architectural competition was the well-known French architect Jean Francois Milou, who harmoniously combined historical architectural values with creativity and sensitive incorporation of new elements. His design externally retained all the attributes of the original building, in particular fully respecting the original metal structure in the style of nineteenth-century Parisian architecture, but at the same time proposing to add two underground floors under the building to accommodate sports events and to incorporate spaces for cultural and social events into the existing space of the former market hall.',
    },
  },
]

export { strip3Images, strip3Triggers }
