import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import imageShadow from '../../styles/shadows'
import { hoverTransition, moveRight } from '../../styles/hover'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'
import buttonRight from '../../assets/kurz2024/button-right.png'

const ButtonRight = ({ buttonRef, isShown, sx, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      ref={buttonRef}
      component={'img'}
      src={buttonRight}
      sx={{
        position: 'absolute',
        right: '0',
        cursor: 'pointer',
        opacity: isShown ? '1' : '0',
        pointerEvents: isShown ? 'all' : 'none',
        transition: 'opacity 500ms ease 0s',
        zIndex: '1',
        width: '4.42vw',
        maxWidth: '85px',
        objectFit: 'contain',
        ...hoverTransition,
        '&:hover': {
          ...moveRight,
        },
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    />
  )
}

export default ButtonRight
