import React, { useEffect, useState } from 'react'
import SliderWrapper from '../Slider/SliderWrapper'
import Box from '@mui/material/Box'
import fonts from '../../styles/fonts'
import { useNavigate } from 'react-router-dom'
import { useQuizContext } from '../../contexts/QuizContext'
import useSound from 'use-sound'
import resultSound from '../../assets/_Sfx/__Compress/Q_Result.mp3'
import ButtonMenu from '../../components/Buttons/ButtonMenu'
import Button from '../../components/Buttons/Button1'
import popup1 from '../../assets/kurz2024/popup2.png'
import thumpUp from '../../assets/kurz2024/thumbUp.png'

const QuizResult = ({ isActive, language, goToNextSlide }) => {
  const { correctAnswers, setCorrectAnswers } = useQuizContext()

  const [isLoaded, setIsLoaded] = useState(false)
  const [playVoiceover, { stop }] = useSound(resultSound, {
    interrupt: true,
    onload: () => setIsLoaded(true),
  })

  const onPlay = () => {
    playVoiceover()
  }

  const onStop = () => {
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()
      } else {
        onStop()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  const navigate = useNavigate()

  const getText = () => {
    if (correctAnswers === 12) {
      return {
        sk: 'Bravó, gratulujeme, máš to na 100%',
        en: 'Congratulation! You smashed it.',
      }
    }

    return {
      sk: 'Zopakuj si kvíz a vylepši si svoje skóre',
      en: 'Take the quiz again and improve your score',
    }
  }

  // useEffect(() => {
  //   if (!Number.isInteger(correctAnswers)) {
  //     navigate('/quiz?slide=0')
  //   }
  // }, [isActive])

  return (
    <SliderWrapper
      sx={{
        background: 'linear-gradient(90deg, #6936C5 0%, #5C2A93 100%)',
      }}
    >
      <ButtonMenu chapter={'chapter0'} />

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '95vw',
          }}
        >
          <Box
            sx={{
              ...fonts.Urbanist['78px']({
                color: '#fff',
                fontWeight: '900',
                letterSpacing: 1.56,
              }),
              textTransform: 'uppercase',
              position: 'absolute',
              top: '8%',
              textShadow: '6px 6px 0px #000',
            }}
          >
            {language === 'sk' ? 'Tvoja úspešnosť' : 'Your result'}
          </Box>

          <Box
            component={'img'}
            src={popup1}
            sx={{
              width: '100%',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              bottom: '9%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '3.2vw',
              }}
            >
              <Box
                component={'img'}
                src={thumpUp}
                sx={{
                  width: '9vw',
                }}
              />

              <Box
                sx={{
                  ...fonts.Urbanist['150px']({
                    color: '#6936E5',
                    fontWeight: '900',
                    letterSpacing: 2.92,
                  }),
                  textTransform: 'uppercase',
                }}
              >
                {correctAnswers}/12
              </Box>
            </Box>

            <Box
              sx={{
                ...fonts.Urbanist['22px']({
                  color: '#000',
                  fontWeight: '900',
                  letterSpacing: 0.44,
                }),
                textTransform: 'uppercase',
                padding: '6% 0 2% 0',
              }}
            >
              {getText()[language]}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '4vw',
                marginTop: '26px',
              }}
            >
              <Button
                variant={2}
                sx={{
                  minWidth: '250px',
                }}
                text={language === 'sk' ? 'späť' : 'back'}
                onClick={() => {
                  setCorrectAnswers(undefined)
                  navigate('/quiz?slide=0')
                }}
              />

              <Button
                sx={{
                  minWidth: '250px',
                }}
                text={language === 'sk' ? 'pokračuj' : 'continue'}
                onClick={() => {
                  goToNextSlide()
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SliderWrapper>
  )
}

export default QuizResult
