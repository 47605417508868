import React from 'react'
import { HashRouter } from 'react-router-dom'
import App from './App'
import { AppContextProvider } from './contexts/AppContext'
import { LanguageContextProvider } from './contexts/LanguageContext'
import { QuizContextProvider } from './contexts/QuizContext'
import { AnimationNavContextProvider } from './contexts/AnimationNavContext'

const Router = () => {
  return (
    <HashRouter>
      <AppContextProvider>
        <LanguageContextProvider>
          <QuizContextProvider>
            <AnimationNavContextProvider>
              <App />
            </AnimationNavContextProvider>
          </QuizContextProvider>
        </LanguageContextProvider>
      </AppContextProvider>
    </HashRouter>
  )
}

export default Router
