import React from 'react'
import Instructions from '../components/Instructions/Instructions'
import ChapterTitle from '../v2/components/ChapterTitle/ChapterTitle'
import backgroundk02 from '../v2/assets/PROTOTYP/K08/K08-0.png'
import Video from '../components/Video/Video'
import video1SK from '../v2/assets/PROTOTYP/K08/K08_01_1.mp4'
import video1EN from '../v2/assets/PROTOTYP/K08/K08_ eng gg.mp4'
import Strip from '../components/Strip/Strip'
import animation1 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-1.webm'
import animation2 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-2.webm'
import animation3 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-3.webm'
import animation4 from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-4.webm'
import animation1Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-1.mov'
import animation2Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-2.mov'
import animation3Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-3.mov'
import animation4Safari from '../v2/assets/PROTOTYP/K08/K08-PAS-anim-4.mov'
import { strip2Images, strip2Triggers } from '../strips/strip2'
import bg from '../v2/assets/PROTOTYP/K08/K08-DOPLNKOVESTUDIUM.jpg'
import bgInstructions from '../v2/assets/PROTOTYP/K08/K08-PAS_RESIZED.jpg'
import { isSafari } from 'react-device-detect'
import Partners from '../components/Partners/Partners'

const chapter8 = {
  url: '/chapter-8',
  name: 'chapter8',
  slides: [
    {
      Component: Partners,
      props: {
        isEnding: true,
      },
    },
  ],
}

export default chapter8
