import SliderWrapper from '../../../components/Slider/SliderWrapper'
import React, { memo, useEffect, useState } from 'react'
import useSound from 'use-sound'
import ButtonMenu from '../../../components/Buttons/ButtonMenu'
import ButtonLeft from '../../../components/Buttons/ButtonLeft'
import ButtonRight from '../../../components/Buttons/ButtonRight'
import ButtonPlay from '../../../components/Buttons/ButtonPlay'
import ButtonPause from '../../../components/Buttons/ButtonPlay'
import BackgroundWave from '../BackgroundWave/BackgroundWave'
import explain3 from '../../../assets/kurz2024/explain3.png'
import explain3EN from '../../../assets/kurz2024/explain3EN.png'
import Box from '@mui/material/Box'
import fonts from '../../../styles/fonts'
import { fadeInScale } from '../../../styles/animations'
import { useNavigate } from 'react-router-dom'
import { useAnimationNavContext } from '../../../contexts/AnimationNavContext'

const Animated3 = ({
  voiceover,
  isActive,
  language,
  goToPrevSlide,
  goToNextSlide,
  activeSlide,
  bg,
  mainImg,
  text,
  secondaryImg,
  year,
  description,
  source,
  floatingImages,
  showArrows,
  navComponent,
  backButtonSrc,
}) => {
  const navigate = useNavigate()

  const [shouldRenderFloatingImages, setShouldRenderFloatingImages] =
    useState(isActive)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(voiceover?.[language], {
    onload: () => {
      setIsLoaded(true)
    },
    onend: () => {
      setIsPlaying(false)
    },
  })

  useEffect(() => {
    if (isActive) {
      setShouldRenderFloatingImages(true)
    }
  }, [isActive])

  const onPlay = () => {
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()
      } else {
        onStop()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    setIsLoaded(false)
    onStop()
  }, [language])

  return (
    <SliderWrapper flexCenter>
      {bg && <BackgroundWave disabled background={bg} />}

      <ButtonMenu
        variant={1}
        sx={{
          zIndex: '20',
        }}
      />

      <Box
        component={'img'}
        src={language === 'sk' ? explain3 : explain3EN}
        sx={{
          position: 'absolute',
          top: '22px',
          right: '112px',
          width: '15.5vw',
        }}
      />

      {voiceover && (
        <>
          <ButtonPlay
            sx={{
              right: '22px',
              zIndex: '20',
              opacity: isPlaying ? '1' : '0',
              pointerEvents: !isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPause}
          />

          <ButtonPause
            sx={{
              right: '22px',
              zIndex: '20',
              opacity: !isPlaying ? '1' : '0',
              pointerEvents: isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPlay}
          />
        </>
      )}

      {showArrows && (
        <>
          <ButtonLeft
            isShown
            variant={1}
            onClick={() => navigate(backButtonSrc)}
            sx={{
              zIndex: '18',
            }}
          />

          <ButtonRight
            isShown
            variant={1}
            onClick={goToNextSlide}
            sx={{
              zIndex: '18',
            }}
          />
        </>
      )}

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 7% 0 8%',
          marginTop: '-2%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '5vw',
          }}
        >
          <Box
            sx={{
              minWidth: '65%',
            }}
          >
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Box
                component={'img'}
                src={mainImg}
                sx={{
                  width: '100%',
                }}
              />

              <Box
                sx={{
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 40,
                  }),
                  position: 'absolute',
                  top: '-50px',
                  left: '50%',
                  translate: '-50%',
                }}
              >
                {year}
              </Box>

              <Box
                sx={{
                  ...fonts.Urbanist['16px']({
                    color: '#000',
                    fontWeight: '700',
                    lineHeight: 40,
                  }),
                  position: 'absolute',
                  bottom: '4%',
                  left: '2%',
                }}
              >
                {description[language]}
              </Box>

              <Box
                sx={{
                  ...fonts.Urbanist['16px']({
                    color: '#EBEBEB',
                    fontWeight: '700',
                    lineHeight: 40,
                  }),
                  position: 'absolute',
                  bottom: '4%',
                  right: '3.4%',
                }}
              >
                {source[language]}
              </Box>

              {shouldRenderFloatingImages &&
                floatingImages.map((image, index) => (
                  <Box
                    component={'img'}
                    src={image.img}
                    sx={{
                      position: 'absolute',
                      width: image.width,
                      top: image.top,
                      left: image.left,
                      bottom: image.bottom,
                      right: image.right,
                      opacity: '0',
                      animation: `${fadeInScale} 1000ms ease forwards`,
                      animationDelay: `${500 * (index + 1)}ms`,
                    }}
                  />
                ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {text[language]}

            {shouldRenderFloatingImages && (
              <Box
                component={'img'}
                src={secondaryImg}
                sx={{
                  maxWidth: '100%',
                  opacity: '0',
                  animation: `${fadeInScale} 1000ms ease forwards`,
                  animationDelay: `${500 * (floatingImages.length + 1)}ms`,
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </SliderWrapper>
  )
}

export default memo(Animated3)
