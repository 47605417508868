import React from 'react'
import Box from '@mui/material/Box'
import { pulseAnimation } from '../../styles/animations'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'
import buttonPlay from '../../assets/kurz2024/button-video.png'

const ButtonVideoPlay = ({ isShown, onClick, chapter }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        cursor: 'pointer',
        borderRadius: '25%',
        opacity: isShown ? '1' : '0',
        pointerEvents: isShown ? 'all' : 'none',
        transition: 'opacity 500ms ease 0s',
        width: '11vw',
        ...pulseAnimation,
      }}
      onMouseEnter={playHover}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
      <Box
        component={'img'}
        src={buttonPlay}
        sx={{
          width: '100%',
        }}
      />
    </Box>
  )
}

export default ButtonVideoPlay
