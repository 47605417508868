import React from 'react'
import Box from '@mui/material/Box'
import navigationBase from '../../assets/kurz2024/modalNav2.png'
import navDot from '../../assets/kurz2024/modalDot.png'

const ModalNav3 = ({ activeSlide }) => {
  return (
    <Box
      sx={{
        width: '6.7vw',
        position: 'absolute',
        left: '50%',
        bottom: '30px',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '19',
      }}
    >
      <Box
        component={'img'}
        src={navigationBase}
        sx={{
          width: '100%',
        }}
      />

      <Box
        component={'img'}
        src={navDot}
        sx={{
          width: '16.27%',
          position: 'absolute',
          top: '50%',
          left: `${29 + activeSlide * 26}%`,
          translate: '0 -50%',
          transition: '0.3s',
        }}
      />
    </Box>
  )
}

export default ModalNav3
