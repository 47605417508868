import Info from '../components/Info/Info'
import Partners from '../components/Partners/Partners'
import Intro from '../v2/components/Intro/Intro'
import Intro2 from '../v2/components/Intro2/Intro2'
import Video from '../components/Video/Video'
import video1SK from '../assets/kurz2024/EXPORT/K00 Intro/K00_4(1).mp4'
import video1EN from '../assets/kurz2024/EXPORT/K00 Intro/K00_4.mp4'
import Info5 from '../components/Info/Info5'

const chapter0 = {
  url: '/chapter-0',
  name: 'chapter0',
  slides: [
    // Intro2,
    Info5,
    Info,
    Partners,
    Intro,
    Intro2,
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
  ],
}

export default chapter0
