import React from 'react'
import Box from '@mui/material/Box'
import navigationBase from '../../assets/kurz2024/modalNav3.png'
import navDot from '../../assets/kurz2024/modalDot.png'
import { useNavigate } from 'react-router-dom'

const ModalNav3 = ({ activeSlide, sx }) => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        width: '8.125vw',
        position: 'absolute',
        left: '50%',
        bottom: '30px',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '19',
        ...sx,
      }}
    >
      <Box
        component={'img'}
        src={navigationBase}
        sx={{
          width: '100%',
        }}
      />

      <Box
        component={'img'}
        src={navDot}
        sx={{
          width: '16.27%',
          position: 'absolute',
          top: '50%',
          left: `${16 + activeSlide * 26}%`,
          translate: '0 -50%',
          transition: '0.3s',
        }}
      />

      {/*{dots.map((dot, index) => (*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      position: 'absolute',*/}
      {/*      top: '16%',*/}
      {/*      left: `${index * 25}%`,*/}
      {/*      translate: '0 -50%',*/}
      {/*      height: '15px',*/}
      {/*      width: '25%',*/}
      {/*      cursor: 'pointer',*/}
      {/*      zIndex: '1',*/}
      {/*    }}*/}
      {/*    onClick={dot.onClick}*/}
      {/*  />*/}
      {/*))}*/}
    </Box>
  )
}

export default ModalNav3
