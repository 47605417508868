import React from 'react'
import Animated2 from '../v2/components/Animated/Animated2'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import bg9 from '../assets/kurz2024/bg9.png'
import k4img6 from '../assets/kurz2024/k4img6.png'
import k4img7 from '../assets/kurz2024/k4img7.png'
import k4img9 from '../assets/kurz2024/k4img9.png'
import Video from '../components/Video/Video'
import video2SK from '../assets/kurz2024/EXPORT/K04/K04_06/K04_06_Sklad_7_SK_OPRAVA_FINAL01.mp4'
import video2EN from '../assets/kurz2024/EXPORT/K04/K04_06/Sklad_7_EN_OPRAVA_FINAL01.mp4'
import fonts from '../styles/fonts'
import Box from '@mui/material/Box'
import animation4lottie from '../assets/kurz2024/EXPORT/K04/K04_05/K_04_05_01-Spilka2.json'
import Animated3 from '../v2/components/Animated/Animated3'

import voiceover20sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.1.mp3'
import voiceover20en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.1.mp3'
import Animated3Nav3 from '../v2/components/Animated/Animated3Nav3'

import k4img0502 from '../assets/kurz2024/k4img0502.png'
import k4img0502main from '../assets/kurz2024/k4img0502main.png'
import k4img0502secondary from '../assets/kurz2024/k4img0502secondary.png'
import k4img0502float1 from '../assets/kurz2024/k4img0502float1.png'
import k4img0502float2 from '../assets/kurz2024/k4img0502float2.png'
import k4img0502float3 from '../assets/kurz2024/k4img0502float3.png'
import voiceover21sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.2.mp3'
import voiceover21en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.2.mp3'

import k4img0503 from '../assets/kurz2024/k4img0503.png'
import k4img0503main from '../assets/kurz2024/k4img0503main.png'
import k4img0503secondary from '../assets/kurz2024/k4img0503secondary.png'
import k4img0503float1 from '../assets/kurz2024/k4img0503float1.png'
import k4img0503float2 from '../assets/kurz2024/k4img0503float2.png'
import k4img0503float3 from '../assets/kurz2024/k4img0503float3.png'
import voiceover22sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.3.mp3'
import voiceover22en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.3.mp3'

import k4img0504 from '../assets/kurz2024/k4img0504.png'
import k4img0504main from '../assets/kurz2024/k4img0504main.png'
import k4img0504secondary from '../assets/kurz2024/k4img0504secondary.png'
import k4img0504float1 from '../assets/kurz2024/k4img0504float1.png'
import k4img0504float2 from '../assets/kurz2024/k4img0504float2.png'
import k4img0504float3 from '../assets/kurz2024/k4img0504float3.png'
import voiceover23sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.4.mp3'
import voiceover23en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.4.mp3'

import k4img0505 from '../assets/kurz2024/k4img0505.png'
import k4img0505main from '../assets/kurz2024/k4img0505main.png'
import k4img0505secondary from '../assets/kurz2024/k4img0505secondary.png'
import k4img0505float1 from '../assets/kurz2024/k4img0505float1.png'
import k4img0505float2 from '../assets/kurz2024/k4img0505float2.png'
import k4img0505float3 from '../assets/kurz2024/k4img0505float3.png'
import voiceover24sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.5.mp3'
import voiceover24en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.5.mp3'

import k4img0506 from '../assets/kurz2024/k4img0506.png'
import k4img0506main from '../assets/kurz2024/k4img0506main.png'
import k4img0506secondary from '../assets/kurz2024/k4img0506secondary.png'
import k4img0506float1 from '../assets/kurz2024/k4img0506float1.png'
import k4img0506float2 from '../assets/kurz2024/k4img0506float2.png'
import k4img0506float3 from '../assets/kurz2024/k4img0506float3.png'
import voiceover25sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.6.mp3'
import voiceover25en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.6.mp3'

import k4img0507 from '../assets/kurz2024/k4img0507.png'
import k4img0507main from '../assets/kurz2024/k4img0507main.png'
import k4img0507secondary from '../assets/kurz2024/k4img0507secondary.png'
import k4img0507float1 from '../assets/kurz2024/k4img0507float1.png'
import k4img0507float2 from '../assets/kurz2024/k4img0507float2.png'
import k4img0507float3 from '../assets/kurz2024/k4img0507float3.png'
import voiceover26sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.7.mp3'
import voiceover26en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.7.mp3'

import k4img0508 from '../assets/kurz2024/k4img0508.png'
import k4img0508main from '../assets/kurz2024/k4img0508main.png'
import k4img0508secondary from '../assets/kurz2024/k4img0508secondary.png'
import k4img0508float1 from '../assets/kurz2024/k4img0508float1.png'
import k4img0508float2 from '../assets/kurz2024/k4img0508float2.png'
import voiceover27sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.8.mp3'
import voiceover27en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.8.mp3'

import k4img0509 from '../assets/kurz2024/k4img0509.png'
import k4img0509main from '../assets/kurz2024/k4img0509main.png'
import k4img0509secondary from '../assets/kurz2024/k4img0509secondary.png'
import k4img0509float1 from '../assets/kurz2024/k4img0509float1.png'
import k4img0509float2 from '../assets/kurz2024/k4img0509float2.png'
import voiceover28sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.5.9.mp3'
import voiceover28en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.5.9.mp3'

import k0407trigger01img1 from '../assets/kurz2024/k0407trigger01img1.png'
import k0407trigger01img2 from '../assets/kurz2024/k0407trigger01img2.png'
import k0407trigger01img3 from '../assets/kurz2024/k0407trigger01img3.png'
import k0407trigger01voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.7.1.mp3'
import k0407trigger01voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.1.mp3'

import k0407trigger02img1 from '../assets/kurz2024/k0407trigger02img1.png'
import k0407trigger02img2 from '../assets/kurz2024/k0407trigger02img2.png'
import k0407trigger02img3 from '../assets/kurz2024/k0407trigger02img3.png'
import k0407trigger02secondaryImg1 from '../assets/kurz2024/k0407trigger02secondaryImg1.png'
import k0407trigger02secondaryImg2 from '../assets/kurz2024/k0407trigger02secondaryImg2.png'
import k0407trigger02voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.7.2.mp3'
import k0407trigger02voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.2.mp3'

import k0407trigger03img1 from '../assets/kurz2024/k0407trigger03img1.png'
import k0407trigger03img2 from '../assets/kurz2024/k0407trigger03img2.png'
import k0407trigger03img3 from '../assets/kurz2024/k0407trigger03img3.png'
import k0407trigger03voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.7.3.mp3'
import k0407trigger03voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.3.mp3'

import k0407trigger04img1 from '../assets/kurz2024/k0407trigger04img1.png'
import k0407trigger04img2 from '../assets/kurz2024/k0407trigger04img2.png'
import k0407trigger04img3 from '../assets/kurz2024/k0407trigger04img3.png'
import k0407trigger04voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.7.4.mp3'
import k0407trigger04voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.4.mp3'

import k0407trigger05img1 from '../assets/kurz2024/k0407trigger05img1.png'
import k0407trigger05img2 from '../assets/kurz2024/k0407trigger05img2.png'
import k0407trigger05img3 from '../assets/kurz2024/k0407trigger05img3.png'
import k0407trigger05secondaryImg1 from '../assets/kurz2024/k0407trigger05secondaryImg1.png'
import k0407trigger05voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/4.7.5.mp3'
import k0407trigger05voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.7.5.mp3'
import Strip from '../components/Strip/Strip'
import strip1Background from '../assets/kurz2024/EXPORT/K04/K04_07/Pas SNG.jpg'

const Text = ({ children, secondColor }) => {
  return (
    <Box
      sx={{
        ...fonts.Urbanist['32px']({
          color: '#fff',
          fontWeight: '500',
          lineHeight: 40,
        }),
        '& b': {
          color: secondColor,
        },
      }}
    >
      {children}
    </Box>
  )
}

const Text2 = ({ children, secondColor = '#6936E5', sx }) => {
  return (
    <Box
      sx={{
        ...fonts.Urbanist['36px']({
          color: '#000',
          fontWeight: '500',
          lineHeight: 40,
        }),
        '& b': {
          color: secondColor,
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

const title2 = {
  sk: <>BUDOVA IKEA - VIEDEŇ - RAKÚSKO</>,
  en: <>IKEA building - Vienna - Austria</>,
}

const triggers3 = [
  {
    id: 'triggers3-1',
    top: '41%',
    left: '9%',
    photos: [k0407trigger01img1, k0407trigger01img2, k0407trigger01img3],
    sources: [
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
    ],
    voiceover: {
      sk: k0407trigger01voiceoverSK,
      en: k0407trigger01voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <Text2
          sx={{
            maxWidth: '480px',
          }}
        >
          Ako posledný príklad v tejto časti e-kurzu prinášame veľmi{' '}
          <b>pozitívny príklad dôslednej rekonštrukcie pôvodnej budovy,</b>{' '}
          ktorej súčasťou je aj dostavba a modernizácia. Pôvodná budova SNG,
          ktorej autorom je{' '}
          <b>významný slovenský architekt Vladimír Dedeček,</b> bola postavená
          v roku 1977{' '}
          <b>na území bývalých Vodných kasárni na nábreží Dunaja.</b>
        </Text2>
      ),
      en: (
        <Text2
          sx={{
            maxWidth: '480px',
          }}
        >
          As the last example in this part of the e-course, we present a very
          <b>
            positive example of a rigorous reconstruction of an original
            building,
          </b>
          which also includes an extension and modernisation. The original SNG
          building, designed by the{' '}
          <b>eminent Slovak architect Vladimír Dedeček,</b>
          was built in 1977{' '}
          <b>
            on the site of the former Water Barracks on the embankment of the
            Danube.
          </b>
        </Text2>
      ),
    },
  },
]

const triggers4 = [
  {
    id: 'triggers4-1',
    top: '57%',
    left: '30%',
    photos: [k0407trigger02img1, k0407trigger02img2, k0407trigger02img3],
    sources: [
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
    ],
    voiceover: {
      sk: k0407trigger02voiceoverSK,
      en: k0407trigger02voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0407trigger02secondaryImg1}
              sx={{
                width: '6vw',
              }}
            />

            <Box
              component={'img'}
              src={k0407trigger02secondaryImg2}
              sx={{
                width: '6vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '480px',
            }}
          >
            Išlo o jedinečnú architektúru, ktorej typickým{' '}
            <b>architektonickým prvkom bolo premostenie ako novotvaru</b> zo
            strany nábrežia, pri zachovaní pôvodnej architektúry Vodných
            kasární.
          </Text2>
        </>
      ),
      en: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0407trigger02secondaryImg1}
              sx={{
                width: '6vw',
              }}
            />

            <Box
              component={'img'}
              src={k0407trigger02secondaryImg2}
              sx={{
                width: '6vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '480px',
            }}
          >
            It was a unique architecture, whose typical{' '}
            <b>
              architectural element was a bridge as a new form on the embankment
              side,
            </b>{' '}
            while preserving the original architecture of the Water Barracks.
          </Text2>
        </>
      ),
    },
  },
]

const triggers5 = [
  {
    id: 'triggers5-1',
    top: '38%',
    left: '44.5%',
    photos: [k0407trigger03img1, k0407trigger03img2, k0407trigger03img3],
    sources: [
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
    ],
    voiceover: {
      sk: k0407trigger03voiceoverSK,
      en: k0407trigger03voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <Text2
          sx={{
            maxWidth: '540px',
          }}
        >
          <b> Budova sa v minulosti stala terčom obdivu aj zatracovania,</b>{' '}
          dokonca prebiehala debata o tom, či by sa nemala zbúrať. Tlak
          verejnosti, ale aj niektorých architektov bol veľký, ale{' '}
          <b>
            nakoniec sa podarilo budovu zrekonštruovať, dostavať a modernizovať
            na základe víťazného architektonického návrhu architektov Paňáka
            a Kusého.
          </b>{' '}
          Rekonštrukcia trvala vyše 6 rokov, ale podarilo sa ju ukončiť koncom
          roka 2022.
        </Text2>
      ),
      en: (
        <Text2
          sx={{
            maxWidth: '540px',
          }}
        >
          <b>
            The building has been the target of both admiration and condemnation
          </b>
          in the past, and there has even been debate as to whether it should be
          demolished. There was a lot of pressure from the public as well as
          some architects,{' '}
          <b>
            but in the end the building was renovated, completed and modernised
            based on the winning architectural design by architects Paňák and
            Kusý.
          </b>{' '}
          The reconstruction took over 6 years, but was completed by the end of
          2022.
        </Text2>
      ),
    },
  },
]

const triggers6 = [
  {
    id: 'triggers6-1',
    top: '35%',
    left: '66.4%',
    photos: [k0407trigger04img1, k0407trigger04img2, k0407trigger04img3],
    sources: [
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
    ],
    voiceover: {
      sk: k0407trigger04voiceoverSK,
      en: k0407trigger04voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <Text2
          sx={{
            maxWidth: '540px',
          }}
        >
          <b>
            Architekti rekonštrukcie, dostavby a modernizácie zvolili citlivý
            a udržateľný prístup.
          </b>{' '}
          Viaceré pôvodné materiály zrecyklovali a použili ich pri
          rekonštrukcii.{' '}
          <b>
            A čo ocenili na rekonštrukcii odborníci a laici asi najviac?
            Povrchovú úpravu všetkých zadných fasád Vodných kasární,{' '}
          </b>
          ktorá bola očistená na základné zmiešané murivo a reštaurátorsky
          opravená <b>s priznanými stopami rôznych zásahov z minulosti.</b>
        </Text2>
      ),
      en: (
        <Text2
          sx={{
            maxWidth: '540px',
          }}
        >
          <b>
            The architects of the reconstruction, extension and modernisation
            took a sensitive and sustainable approach.
          </b>{' '}
          A number of original materials were recycled and used in the
          reconstruction.{' '}
          <b>
            And what did professionals and lay people appreciate most about the
            reconstruction? The surface treatment of all the rear facades of the
            Water Barracks,
          </b>
          which was cleaned to a basic mixed masonry and restored{' '}
          <b>with exposed traces of various interventions from the past.</b>
        </Text2>
      ),
    },
  },
]

const triggers7 = [
  {
    id: 'triggers7-1',
    top: '35%',
    left: '89.4%',
    photos: [k0407trigger05img1, k0407trigger05img2, k0407trigger05img3],
    sources: [
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
      {
        sk: 'zdroj: www.dagslovakia.sk',
        en: 'source: www.dagslovakia.sk',
      },
    ],
    voiceover: {
      sk: k0407trigger05voiceoverSK,
      en: k0407trigger05voiceoverEN,
    },
    title: title2,
    text: {
      sk: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0407trigger05secondaryImg1}
              sx={{
                width: '6vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '500px',
            }}
          >
            <b>
              Rekonštrukcia budovy SNG bola ocenená viacerými architektonickými
              cenami,
            </b>{' '}
            vrátane najprestížnejšej,{' '}
            <span
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              ktorou je Cena D.Jurkoviča
            </span>{' '}
            a rekonštrukcia SNG bola nominovaná aj na významnú európsku
            architektonickú Cenu Mies van der Rohe.
          </Text2>
        </>
      ),
      en: (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={k0407trigger05secondaryImg1}
              sx={{
                width: '6vw',
              }}
            />
          </Box>

          <Text2
            sx={{
              maxWidth: '500px',
            }}
          >
            <b>
              The renovation of the SNG building has been awarded several
              architectural prizes,
            </b>{' '}
            including the most prestigious, which is the D.Jurkovič Prize and
            the reconstruction of the SNG was also nominated for the prestigious
            Mies van der Rohe European Architecture Prize.
          </Text2>
        </>
      ),
    },
  },
]

const chapter4 = {
  url: '/chapter-4_3',
  name: 'chapter4_3',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 3,
        bg: bg9,
        img: k4img6,
        imgWidth: '12.6%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span>Spilka, </span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architekti Peter Bouda, Ivan Masár
              </Box>
            </>
          ),
          en: (
            <>
              <span>Spilka, </span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architects Peter Bouda, Ivan Masár
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Animated2,
      props: {
        animationLottie: animation4lottie,
        voiceover: {
          sk: voiceover20sk,
          en: voiceover20en,
        },
        text: {
          sk: 'V areáli bývalého bratislavského pivovaru Stein v Starom Meste, vyrástol pred 3 rokmi nový multifunkčný komplex s názvom Stein 2.',
          en: 'On the site of the former Bratislava Stein Brewery in the Old Town, a new multifunctional complex called Stein 2 was built 3 years ago.',
        },
        header: {
          sk: (
            <>
              Spilka Bratislava <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                architekti Peter Bouda, Ivan Masár
              </Box>
            </>
          ),
          en: (
            <>
              Spilka Bratislava <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                architects Peter Bouda, Ivan Masár
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        activeSlide: 0,
        bg: k4img0502,
        mainImg: k4img0502main,
        secondaryImg: k4img0502secondary,
        floatingImages: [
          {
            img: k4img0502float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0502float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0502float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '1872',
        voiceover: {
          sk: voiceover21sk,
          en: voiceover21en,
        },
        description: {
          sk: 'Rodina Steinovcov',
          en: 'The Stein family',
        },
        source: {
          sk: 'zdroj: www.dennikn.sk',
          en: 'source: www.dennikn.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#C3BC8A'}>
              Pivovar Stein založila rodina Steinovcov v roku 1872 a 
              <b>patril k najvýznamnejším bratislavským podnikom.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#C3BC8A'}>
              The Stein brewery was founded by the Stein family in 1872 and{' '}
              <b>was one of the most important enterprises in Bratislava.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        activeSlide: 1,
        bg: k4img0503,
        mainImg: k4img0503main,
        secondaryImg: k4img0503secondary,
        floatingImages: [
          {
            img: k4img0503float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0503float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0503float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '1902',
        voiceover: {
          sk: voiceover22sk,
          en: voiceover22en,
        },
        description: {
          sk: 'Pohľad na továreň ',
          en: 'A view of the factory',
        },
        source: {
          sk: 'zdroj: www.archinfo.sk',
          en: 'source: www.archinfo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#EFDC9F'}>
              Z pôvodného areálu pivovaru sa nezachovalo takmer nič, okrem
              architektonicky{' '}
              <b>
                vzácnej budovy bývalej kvasiarne, ktorá dnes nesie meno Spilka.
              </b>
            </Text>
          ),
          en: (
            <Text secondColor={'#EFDC9F'}>
              Almost nothing of the original brewery premises has survived,
              except for the architecturally{' '}
              <b>
                precious building of the former fermentation plant, which now
                bears the name Spilka.
              </b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        activeSlide: 2,
        bg: k4img0504,
        mainImg: k4img0504main,
        secondaryImg: k4img0504secondary,
        floatingImages: [
          {
            img: k4img0504float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0504float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0504float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '1949 - 1953',
        voiceover: {
          sk: voiceover23sk,
          en: voiceover23en,
        },
        description: {
          sk: 'Dokončená továreň s ikonickou budovou kvasiarne ',
          en: 'Completed factory with the iconic fermenter building',
        },
        source: {
          sk: 'zdroj: www.istavebnictvo.sk',
          en: 'source: www.istavebnictvo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#FCF190'}>
              Pôvodná budova kvasiarne bola vyprojektovaná v roku 1949 a jej
              realizáciu ukončili v roku 1953.{' '}
              <b>
                Svojou celkovou architektúrou vrátane kupoly sa stala dominantou
                nielen pivovaru, ale aj vtedajšej Bratislavy.
              </b>
            </Text>
          ),
          en: (
            <Text secondColor={'#FCF190'}>
              The original fermentation plant building was designed in 1949 and
              completed in 1953.{' '}
              <b>
                Its overall architecture, including the dome, made it a landmark
                not only of the brewery but also of Bratislava at that time.
              </b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        activeSlide: 3,
        bg: k4img0505,
        mainImg: k4img0505main,
        secondaryImg: k4img0505secondary,
        floatingImages: [
          {
            img: k4img0505float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0505float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0505float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '1950 - 1960',
        voiceover: {
          sk: voiceover24sk,
          en: voiceover24en,
        },
        description: {
          sk: 'Plán budovy a suterén s ležiakovými pivnicami ',
          en: 'Building plan and basement with lager cellars',
        },
        source: {
          sk: 'zdroj: www.archinfo.sk',
          en: 'source: www.archinfo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#FA9C59'}>
              <b>Mala jedno podzemné a 5 nadzemných podlaží.</b> Na prízemí a v
              suteréne budovy boli umiestnené ležiakové pivnice s cisternami na
              pivo.
            </Text>
          ),
          en: (
            <Text secondColor={'#FA9C59'}>
              <b>It had one underground and 5 above-ground floors.</b> On the
              ground floor and in the basement of the building there were lager
              cellars with beer tanks.
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        activeSlide: 4,
        bg: k4img0506,
        mainImg: k4img0506main,
        secondaryImg: k4img0506secondary,
        floatingImages: [
          {
            img: k4img0506float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0506float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0506float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '1960 - 1970',
        voiceover: {
          sk: voiceover25sk,
          en: voiceover25en,
        },
        description: {
          sk: 'Technologické vybavenie továrne',
          en: 'Technological equipment of the factory',
        },
        source: {
          sk: 'zdroj: www.archinfo.sk',
          en: 'source: www.archinfo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#FFDE8A'}>
              <b>Na štyroch podlažiach</b> pod kupolou boli technológie na
              kvasenie piva.
            </Text>
          ),
          en: (
            <Text secondColor={'#FFDE8A'}>
              <b>On four floors</b> under the dome there were technologies for
              beer fermentation.
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        activeSlide: 5,
        bg: k4img0507,
        mainImg: k4img0507main,
        secondaryImg: k4img0507secondary,
        floatingImages: [
          {
            img: k4img0507float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0507float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0507float2,
            width: '13.3%',
            bottom: '-8%',
            right: '30%',
          },
        ],
        year: '1980 - 2017',
        voiceover: {
          sk: voiceover26sk,
          en: voiceover26en,
        },
        description: {
          sk: 'Pohľad na chátrajúcu kvasiareň, počas veľkej rekonštrukcie',
          en: 'A view of the dilapidated fermentation plant, during a major renovation',
        },
        source: {
          sk: 'zdroj: www.istavebnictvo.sk',
          en: 'source: www.istavebnictvo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#FAA6C4'}>
              Architekti sa pri rekonštrukcii snažili{' '}
              <b>
                zachovať všetky špecifické konštrukčné aj technologické prvky
              </b>{' '}
              a zakomponovať ich do svojho návrhu rekonštrukcie objektu
            </Text>
          ),
          en: (
            <Text secondColor={'#FAA6C4'}>
              The architects sought to{' '}
              <b>retain all specific structural and technological elements</b>{' '}
              during the refurbishment and incorporate them into their design
              for the building reconstruction.
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        activeSlide: 6,
        bg: k4img0508,
        mainImg: k4img0508main,
        secondaryImg: k4img0508secondary,
        floatingImages: [
          {
            img: k4img0508float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0508float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '2021',
        voiceover: {
          sk: voiceover27sk,
          en: voiceover27en,
        },
        description: {
          sk: 'Projekt interiéru',
          en: 'Interior project',
        },
        source: {
          sk: 'zdroj: www.archinfo.sk',
          en: 'source: www.archinfo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#50EE95'}>
              Spilka prešla rozsiahlou rekonštrukciou a získala príťažlivú
              podobu.{' '}
              <b>
                Stal sa z nej objekt so špičkovými kancelárskymi a obchodnými
                priestormi.
              </b>
            </Text>
          ),
          en: (
            <Text secondColor={'#50EE95'}>
              The Spilka has undergone extensive reconstruction and has been
              given an attractive appearance.{' '}
              <b>
                It has become a building with high-end office and retail space.
              </b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        NavComponent: Animated3Nav3,
        showArrows: true,
        backButtonSrc: '/chapter-4_3?slide=1',
        activeSlide: 7,
        bg: k4img0509,
        mainImg: k4img0509main,
        secondaryImg: k4img0509secondary,
        floatingImages: [
          {
            img: k4img0509float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0509float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '2024',
        voiceover: {
          sk: voiceover28sk,
          en: voiceover28en,
        },
        description: {
          sk: 'Zrekonštruovaná budova',
          en: 'Renovated building',
        },
        source: {
          sk: 'zdroj: www.archinfo.sk',
          en: 'source: www.archinfo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#50EE95'}>
              Na prízemí vznikla piváreň, zvyšné štyri podlažia slúžia ako
              moderné kancelárske priestory.{' '}
              <b>
                Aj po citlivej rekonštrukcii a konverzii patrí objekt Spilky k
                ikonickej architektúre Bratislavy.
              </b>
            </Text>
          ),
          en: (
            <Text secondColor={'#50EE95'}>
              The ground floor has been converted into a beer bar, while the
              remaining four floors serve as modern office space.{' '}
              <b>
                Even after the sensitive reconstruction and conversion, the
                Spilka building belongs to the iconic architecture of
                Bratislava.
              </b>
            </Text>
          ),
        },
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 3,
        bg: bg9,
        img: k4img7,
        imgWidth: '29.4%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span>Sklad č. 7, </span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architekt Marek Rudohradský
              </Box>
            </>
          ),
          en: (
            <>
              <span>Warehouse No. 7, </span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architect Marek Rudohradský
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video2SK,
          en: video2EN,
        },
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 3,
        bg: bg9,
        img: k4img9,
        imgWidth: '24.4%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span>
                Slovenská Národná <br /> Galéria,{' '}
              </span>{' '}
              Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architekti Pavol Paňák a Martin Kusý
              </Box>
            </>
          ),
          en: (
            <>
              <span>
                Slovak National <br /> Gallery,{' '}
              </span>{' '}
              Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architects Pavol Paňák and Martin Kusý
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Strip,
      props: {
        isStrip2: true,
        images: [
          {
            image: strip1Background,
            sx: {
              zIndex: '3',
              position: 'relative',
            },
          },
        ],
        triggers: [
          ...triggers3,
          ...triggers4,
          ...triggers5,
          ...triggers6,
          ...triggers7,
        ],
        scrollHandler: (percentage, language) => {
          return {
            sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
            en: 'Technologies in green architecture and construction sector',
          }[language]
        },
      },
    },
  ],
}

export default chapter4
