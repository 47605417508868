import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import ModalContent from './ModalContent'
import { fadeIn } from '../../styles/animations'
import useSound from 'use-sound'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import ButtonClose from '../Buttons/ButtonClose'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'
import ButtonPlay from '../../components/Buttons/ButtonPlay'
import ButtonPause from '../../components/Buttons/ButtonPause'
import ButtonRead from '../Buttons/ButtonRead'
import Source from '../../v2/components/Source'
import popup1 from '../../assets/kurz2024/popup1.png'

const Modal = ({
  modalRef,
  textOnly,
  language,
  isShownModal,
  setIsShownModal,
  chapter,
  ModalNavComponent,
  setIsShownOverlay,
  customPause,
  customIsPlaying,
  customPlay,
  text,
}) => {
  const sliderRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(
    isShownModal.voiceover?.[language],
    {
      onload: () => setIsLoaded(true),
    }
  )

  const [isShownText, setIsShownText] = useState(textOnly)
  const [activeSlide, setActiveSlide] = useState(0)

  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const onPlay = () => {
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded && !textOnly) {
      onPlay()
    }
  }, [isLoaded])

  if (modalRef) {
    modalRef.current = { onStop }
  }

  const close = () => {
    if (textOnly) {
      return setIsShownModal(false)
    }

    onStop()
    setIsShownModal(false)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        opacity: '0',
        transition: 'opacity 500ms ease 0s',
        animation: `${fadeIn} 500ms ease forwards`,
        width: '83.3%',
        height: '83.3%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '83vw',
        }}
      >
        <Box
          component={'img'}
          src={popup1}
          sx={{
            width: '100%',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <ButtonClose
            sx={{
              top: '15px',
              right: '30px',
              width: '60px',
              height: '60px',
            }}
            onClick={close}
          />

          <ButtonPlay
            variant={1}
            sx={{
              opacity: !isPlaying ? '1' : '0',
              pointerEvents: isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
              top: '15px',
              right: '105px',
              width: '60px',
              height: '60px',
            }}
            onClick={onPlay}
          />

          <ButtonPause
            variant={1}
            sx={{
              opacity: isPlaying ? '1' : '0',
              pointerEvents: !isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
              top: '15px',
              right: '105px',
              width: '60px',
              height: '60px',
            }}
            onClick={onPause}
          />

          {/*<ButtonRead*/}
          {/*  variant={1}*/}
          {/*  sx={{*/}
          {/*    transition: 'opacity 500ms ease 0s',*/}
          {/*    top: '15px',*/}
          {/*    right: '90px',*/}
          {/*    width: '60px',*/}
          {/*    height: '60px',*/}
          {/*  }}*/}
          {/*  onClick={() => setIsShownOverlay(isShownModal)}*/}
          {/*/>*/}

          {/*<Box*/}
          {/*  sx={{*/}
          {/*    position: 'absolute',*/}
          {/*    left: '50%',*/}
          {/*    translate: '-50% 0',*/}
          {/*    bottom: '32.5px',*/}
          {/*    display: 'flex',*/}
          {/*    gap: '15px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {isShownModal.photos &&*/}
          {/*    isShownModal.photos.map((photo, index) => (*/}
          {/*      <Box*/}
          {/*        key={photo}*/}
          {/*        sx={{*/}
          {/*          width: '25px',*/}
          {/*          height: '25px',*/}
          {/*          cursor: 'pointer',*/}
          {/*          background:*/}
          {/*            index === activeSlide*/}
          {/*              ? 'radial-gradient(50% 50% at 50% 50%, #3AD3A4 0%, #2B927A 100%)'*/}
          {/*              : '#112232',*/}
          {/*          borderRadius: '50%',*/}
          {/*          transition: '0.3s',*/}
          {/*        }}*/}
          {/*        onMouseEnter={playHover}*/}
          {/*        onClick={(e) => {*/}
          {/*          playClick()*/}
          {/*          sliderRef.current?.slickGoTo(index)*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    ))}*/}
          {/*</Box>*/}

          <ModalContent
            chapter={chapter}
            sliderRef={sliderRef}
            isShownModal={isShownModal}
            isShownText={isShownText}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            language={language}
            ModalNavComponent={ModalNavComponent}
          />

          {isShownModal.source && (
            <Typography
              sx={{
                position: 'absolute',
                right: '30px',
                bottom: '30px',
                ...fonts.Urbanist['16px']({
                  fontWeight: '700',
                  color: '#fff',
                }),
              }}
            >
              {isShownModal.source[language]}
            </Typography>
          )}
        </Box>

        {/*{isShownModal.sources && isShownModal.sources[activeSlide] && (*/}
        {/*  <Typography*/}
        {/*    sx={{*/}
        {/*      position: 'absolute',*/}
        {/*      right: '30px',*/}
        {/*      bottom: '30px',*/}
        {/*      ...fonts.Urbanist['16px']({*/}
        {/*        fontWeight: '700',*/}
        {/*        color: '#fff',*/}
        {/*      }),*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {isShownModal.sources[activeSlide][language]}*/}
        {/*  </Typography>*/}
        {/*)}*/}
      </Box>

      {/*<ModalFrame chapter={chapter} />*/}

      {/*<ModalHeader*/}
      {/*  chapter={chapter}*/}
      {/*  isShownModal={isShownModal}*/}
      {/*  language={language}*/}
      {/*/>*/}

      {/*<ModalButtons*/}
      {/*  chapter={chapter}*/}
      {/*  setIsShownModal={setIsShownModal}*/}
      {/*  isShownText={isShownText}*/}
      {/*  setIsShownText={setIsShownText}*/}
      {/*  isPlaying={isPlaying}*/}
      {/*  playVoiceover={onPlay}*/}
      {/*  pauseVoiceover={onPause}*/}
      {/*  stopVoiceover={onStop}*/}
      {/*  customPause={customPause}*/}
      {/*  customPlay={customPlay}*/}
      {/*  textOnly={textOnly}*/}
      {/*  customIsPlaying={customIsPlaying}*/}
      {/*/>*/}

      {/*{!textOnly && (*/}
      {/*  <>*/}
      {/*    <ModalArrowLeft chapter={chapter} sliderRef={sliderRef} />*/}

      {/*    <ModalArrowRight chapter={chapter} sliderRef={sliderRef} />*/}
      {/*  </>*/}
      {/*)}*/}

      {/*{!textOnly && (*/}
      {/*  <ModalNavigation*/}
      {/*    sliderRef={sliderRef}*/}
      {/*    chapter={chapter}*/}
      {/*    isShownModal={isShownModal}*/}
      {/*    activeSlide={activeSlide}*/}
      {/*  />*/}
      {/*)}*/}

      {/*<ModalContent*/}
      {/*  chapter={chapter}*/}
      {/*  sliderRef={sliderRef}*/}
      {/*  isShownModal={isShownModal}*/}
      {/*  isShownText={isShownText}*/}
      {/*  setActiveSlide={setActiveSlide}*/}
      {/*  language={language}*/}
      {/*/>*/}
    </Box>
  )
}

export default Modal
