import React from 'react'
import { Routes, Route } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Menu from './components/Menu/Menu'
import Wrapper from './components/Wrapper/Wrapper'
import Chapter from './components/Chapter/Chapter'
import chapter1 from './chapters/chapter1'
import chapter0 from './chapters/chapter0'
import chapter2 from './chapters/chapter2'
import chapter3 from './chapters/chapter3'
import chapter4_1 from './chapters/chapter4'
import chapter5 from './chapters/chapter5'
import quiz from './chapters/quiz'
import chapter8 from './chapters/chapter8'
import chapter7 from './chapters/chapter7'
import Animated3Nav1 from './v2/components/Animated/Animated3Nav1'
import Animated3Nav2 from './v2/components/Animated/Animated3Nav2'
import Animated3Nav3 from './v2/components/Animated/Animated3Nav3'
import chapter4_2 from './chapters/chapter4_2'
import chapter4_3 from './chapters/chapter4_3'

const App = () => {
  const chapters = [
    chapter0,
    chapter1,
    chapter2,
    chapter3,
    chapter4_1,
    chapter4_2,
    chapter4_3,
    chapter5,
    quiz,
    chapter7,
    chapter8,
  ]

  return (
    <>
      <CssBaseline />

      <Wrapper>
        <Menu />

        <Animated3Nav1 />

        <Animated3Nav2 />

        <Animated3Nav3 />

        <Routes>
          <Route
            key={'/'}
            path={'/'}
            element={
              <Chapter
                key={'/'}
                chapters={chapters}
                index={0}
                {...chapters[0]}
              />
            }
          />

          {chapters.map((chapter, index) => (
            <Route
              key={chapter.url}
              path={chapter.url}
              element={
                <Chapter
                  key={chapter.url}
                  chapters={chapters}
                  index={index}
                  {...chapter}
                />
              }
            />
          ))}

          <Route
            key={'*'}
            path={'*'}
            element={
              <Chapter
                key={'*'}
                chapters={chapters}
                index={0}
                {...chapters[0]}
              />
            }
          />
        </Routes>
      </Wrapper>
    </>
  )
}

export default App
