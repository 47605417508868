const hoverTransition = {
  transition: '0.2s',
}

const moveUp = {
  transform: 'translateY(-3px)',
}

const moveLeft = {
  transform: 'translateX(-3px)',
}

const moveRight = {
  transform: 'translateX(3px)',
}

export { moveUp, moveRight, moveLeft, hoverTransition }
