import React from 'react'
import Box from '@mui/material/Box'
import navigationBase from '../../../assets/kurz2024/k04nav1.png'
import navDot from '../../../assets/kurz2024/navdot.png'
import { useNavigate } from 'react-router-dom'
import { useAnimationNavContext } from '../../../contexts/AnimationNavContext'

const Animated3Nav1 = () => {
  const {
    nav1ActiveSlide,
    isShownNav1,
    timeoutRef,
    timeoutIsChangingRef,
    isChangingRef,
    setNav1ActiveSlide,
  } = useAnimationNavContext()

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        width: '70vw',
        position: 'absolute',
        left: '50%',
        bottom: '10px',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '19',
        opacity: isShownNav1 ? '1' : '0',
        transition: '0.3s',
        pointerEvents: isShownNav1 ? undefined : 'none',
      }}
    >
      <Box
        component={'img'}
        src={navigationBase}
        sx={{
          width: '100%',
        }}
      />

      <Box
        component={'img'}
        src={navDot}
        sx={{
          width: '5%',
          position: 'absolute',
          top: '20%',
          left: `${2.2 + nav1ActiveSlide * 9.07}%`,
          translate: '0 -50%',
          transition: '0.3s',
        }}
      />

      <Box
        component={'input'}
        type={'range'}
        min={0}
        max={10}
        value={nav1ActiveSlide}
        step={1}
        sx={{
          position: 'absolute',
          top: '0',
          left: '50%',
          translate: '-50%',
          width: '100%',
          height: '30%',
          padding: '0 4%',
          opacity: '0',
          cursor: 'pointer',
        }}
        onChange={(e) => {
          const value = 7 + Number(e.target.value)

          setNav1ActiveSlide(Number(e.target.value))

          clearTimeout(timeoutRef.current)
          clearTimeout(timeoutIsChangingRef.current)

          const timeoutTime = isChangingRef.current ? 600 : 200

          timeoutRef.current = setTimeout(() => {
            isChangingRef.current = true
            navigate(`/chapter-4_1?slide=${value}`)

            timeoutIsChangingRef.current = setTimeout(() => {
              isChangingRef.current = false
            }, 600)
          }, timeoutTime)
        }}
      />
    </Box>
  )
}

export default Animated3Nav1
