import React from 'react'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import Info3 from '../components/Info/Info3'
import Info4 from '../components/Info/Info4'

const chapter7 = {
  url: '/chapter-7',
  name: 'chapter7',
  slides: [
    {
      Component: Info3,
      props: {
        fontSize: '130px',
        title2: {
          sk: 'Výtvarná súťaž',
          en: 'Art competition',
        },
      },
    },
    Info4,
  ],
}

export default chapter7
