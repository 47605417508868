import React from 'react'
import Box from '@mui/material/Box'

const ModalWrapper = ({ children, isShownModal }) => {
  return (
    <Box
      sx={{
        zIndex: '30',
        width: '100%',
        height: '100%',
        position: 'absolute',
        opacity: Boolean(isShownModal) ? '1' : '0',
        pointerEvents: !Boolean(isShownModal) ? 'none' : 'all',
        transition: 'opacity 500ms ease 0s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  )
}

export default ModalWrapper
