import strip1Background from '../assets/kurz2024/EXPORT/K05 pás/2 Ostrava/ostrava pas exportAsset 14-100.jpg'

import trigger1voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.2.1.mp3'
import trigger1voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.2.1.mp3'
import k050103trigger01img1 from '../assets/kurz2024/k050103trigger01img1.png'
import k050103trigger01img2 from '../assets/kurz2024/k050103trigger01img2.png'
import k050103trigger01img3 from '../assets/kurz2024/k050103trigger01img3.png'

import trigger2voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.2.2.mp3'
import trigger2voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.2.2.mp3'
import k050103trigger02img1 from '../assets/kurz2024/k050103trigger02img1.png'
import k050103trigger02img2 from '../assets/kurz2024/k050103trigger02img2.png'
import k050103trigger02img3 from '../assets/kurz2024/k050103trigger02img3.png'
import k050103trigger02img4 from '../assets/kurz2024/k050103trigger02img4.png'

import trigger3voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.2.3.mp3'
import trigger3voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.2.3.mp3'
import k050103trigger03img1 from '../assets/kurz2024/k050103trigger03img1.png'
import k050103trigger03img2 from '../assets/kurz2024/k050103trigger03img2.png'
import k050103trigger03img3 from '../assets/kurz2024/k050103trigger03img3.png'

import ModalNav3 from '../components/Modal/ModalNav3'

const stripk0503Images = [
  {
    image: strip1Background,
    sx: {
      zIndex: '3',
      position: 'relative',
    },
  },
]

const stripk0503Triggers = [
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers1',
    top: '25%',
    left: '11%',
    images: [
      {
        isLeftAnimation: true,
        src: k050103trigger01img1,
        sx: {
          width: '55%',
          top: '40px',
        },
      },
      {
        src: k050103trigger01img2,
        sx: {
          width: '45%',
          top: '20px',
        },
      },
      {
        src: k050103trigger01img3,
        sx: {
          width: '39%',
          transform: 'translateX(85%)',
        },
      },
    ],
    sources: [
      {
        sk: 'zdroj: www.kudyznudy.cz',
        en: 'source: www.kudyznudy.cz',
      },
      {
        sk: 'zdroj: www.archinfo.sk',
        en: 'source: www.archinfo.sk',
      },
      {
        sk: 'zdroj: www.forbes.cz',
        en: 'source: www.forbes.cz',
      },
    ],
    voiceover: {
      sk: trigger1voiceoverSK,
      en: trigger1voiceoverEN,
    },
    text: {
      sk: 'Bývalý priemyselný areál Vítkovických železiarní tvorí dnes unikátnu industriálnu pamiatku Ostravy, ktorá je zapísaná v zozname Európskeho kultúrneho dedičstva. Zásluhu na tom má nielen vlastník lokality Jan Světlík, ktorý zachovanie a obnovu tohto už nefunkčného industriálneho komplexu inicioval, organizoval a pomáhal financovať, ale aj významný český architekt Josef Pleskot, ktorý vtláča celému komplexu postupne výraznú architektonickú pečať a nový život. ',
      en: 'The former industrial complex of Vítkovice Ironworks is now a unique industrial monument of Ostrava, which is listed as a European Cultural Heritage Site. The credit for this goes not only to the owner of the site, Jan Světlík, who initiated, organised and helped finance the preservation and restoration of this no longer functional industrial complex, but also to the eminent Czech architect Josef Pleskot, who has gradually given the whole complex a distinctive architectural stamp and new life.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers2',
    top: '28%',
    left: '46%',
    images: [
      {
        src: k050103trigger02img1,
        sx: {
          top: '30px',
          width: '60%',
          transform: 'translateX(60%)',
        },
      },
      {
        src: k050103trigger02img2,
        sx: {
          width: '50%',
          top: '30px',
          transform: 'translateX(52%)',
        },
      },
      {
        isLeftAnimation: true,
        src: k050103trigger02img3,
        sx: {
          top: '5px',
          transform: 'translateX(-65%)',
          width: '50%',
        },
      },
      {
        isLeftAnimation: true,
        src: k050103trigger02img4,
        sx: {
          top: '70px',
          transform: 'translateX(-40%)',
          width: '54%',
        },
      },
    ],
    sources: [
      {
        sk: 'zdroj: www.archinfo.sk',
        en: 'source: www.archinfo.sk',
      },
      {
        sk: 'zdroj: www.archinfo.sk',
        en: 'source: www.archinfo.sk',
      },
      {
        sk: 'zdroj: www.technotrasa.cz',
        en: 'source: www.technotrasa.cz',
      },
      {
        sk: 'zdroj: www.archinfo.sk',
        en: 'source: www.archinfo.sk',
      },
    ],
    voiceover: {
      sk: trigger2voiceoverSK,
      en: trigger2voiceoverEN,
    },
    text: {
      sk: 'Vďaka premene tohto najväčšieho českého brownfieldu sa z Ostravy za posledné 2 roky stalo po Prahe druhé najnavštevovanejšie mesto v Českej republike. Projekt revitalizácie industriálnej pamiatky v sebe zahŕňa premenu plynojemu na Multifunkčnú aulu Gong, vytvorenie baníckeho múzea Landek Park, výstavbu novej budovy Sveta techniky, rekonštrukciu Vysokej pece č. 1. A postupne sa revitalizujú aj ďalšie časti.',
      en: 'Thanks to the transformation of this largest Czech brownfield site, Ostrava has become the second most visited city in the Czech Republic after Prague in the last 2 years. The revitalisation project of the industrial monument includes the conversion of the gas storage building into the Gong Multifunctional Auditorium, the creation of the Landek Park Mining Museum, the construction of a new building of the World of Technology, the reconstruction of the Blast Furnace No. 1. And other parts are gradually being revitalised.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers3',
    top: '1%',
    left: '80%',
    images: [
      {
        src: k050103trigger03img1,
        sx: {
          top: '30px',
          width: '45%',
          transform: 'translateX(60%)',
        },
      },
      {
        src: k050103trigger03img2,
        sx: {
          top: '20px',
          transform: 'translateX(100%)',
          width: '35%',
        },
      },
      {
        isLeftAnimation: true,
        src: k050103trigger03img3,
        sx: {
          top: '30px',
          transform: 'translateX(-60%)',
          width: '55%',
        },
      },
    ],
    source: {
      sk: 'zdroj: www.archinfo.sk',
      en: 'source: www.archinfo.sk',
    },
    voiceover: {
      sk: trigger3voiceoverSK,
      en: trigger3voiceoverEN,
    },
    text: {
      sk: (
        <>
          Práve na spomínanej vysokej peci č.1 nedávno dokončili výstavbu
          vyhliadkovej lávky zaujímavého tvaru aj konštrukcie, ktorej súčasťou
          je aj klub s kaviarňou. Nadstavba a lávka majú symbolizovať oheň,
          ktorý bol pre túto vysokú pec v Ostrave kedysi charakteristický. Lávka
          je skonštruovaná z dierovanej ocele, cez ktorú možno vidieť na zem z
          výšky 71,5 metra. Originálna konštrukcia nadstavby aj lávky vytvorili
          výškový orientačný bod celého areálu a Ostrava ním získala novú
          dominantu mesta, druhú najvyššiu po veži novej radnice.
        </>
      ),
      en: (
        <>
          It was at the aforementioned Blast Furnace No.1 that the construction
          of a viewing platform of interesting shape and design was recently
          completed, which also includes a club with a café. The superstructure
          and the footbridge are intended to symbolise the fire that was once a
          characteristic feature of this blast furnace in Ostrava. The
          footbridge is constructed of perforated steel, through which one can
          see the ground from a height of 71.5 metres. The original construction
          of the superstructure and the footbridge created a height landmark for
          the whole area and Ostrava gained a new landmark of the city, the
          second highest after the tower of the new town hall.
        </>
      ),
    },
  },
]

export { stripk0503Images, stripk0503Triggers }
