import React from 'react'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import Book2 from '../components/Book/Book2'
import bookBase from '../assets/kurz2024/EXPORT/K02/K02_01/1_STRANA.png'
import bookAnimation1 from '../assets/kurz2024/EXPORT/K02/K02_01/1 STRANA.json'
import bookAnimation2 from '../assets/kurz2024/EXPORT/K02/K02_02/2 STRANA.json'
import bookAnimation3 from '../assets/kurz2024/EXPORT/K02/K02_03/3 STRANA.json'
import bookAnimation4 from '../assets/kurz2024/EXPORT/K02/K02_04/4_STARNA.json'
import bookAnimation5 from '../assets/kurz2024/EXPORT/K02/K02_05/5_STRANA.json'

import voiceover1sk from '../assets/kurz2024/VOICEOVER/SK/VO/oprava K02 1.mp3'
import voiceover1en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/Conversion_K02_1.mp3'

import voiceover2sk from '../assets/kurz2024/VOICEOVER/SK/VO/oprava K02 1_2.mp3'
import voiceover2en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/Adaptation.mp3'

import voiceover3sk from '../assets/kurz2024/VOICEOVER/SK/VO/2.2.mp3'
import voiceover3en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.2.mp3'

import voiceover4sk from '../assets/kurz2024/VOICEOVER/SK/VO/2.3.mp3'
import voiceover4en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.3.mp3'

import voiceover5sk from '../assets/kurz2024/VOICEOVER/SK/VO/2.4.mp3'
import voiceover5en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/2.4.mp3'

const chapter2 = {
  url: '/chapter-2',
  name: 'chapter2',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 2,
        fontSize: '78px',
        title1: {
          sk: '2. kapitola',
          en: '2. chapter',
        },
        title2: {
          sk: (
            <>
              Čo znamenajú pojmy ako <br />{' '}
              <span>konverzia, adaptácia, rekonštrukcia</span> <br /> či{' '}
              <span>modernizácia</span> budov?
            </>
          ),
          en: (
            <>
              What do terms such as{' '}
              <span>
                conversion, <br /> adaptation, reconstruction
              </span>{' '}
              or <br /> <span>modernisation </span> of buildings mean?
            </>
          ),
        },
      },
    },
    {
      Component: Book2,
      props: {
        albumBase: bookBase,
        count: 5,
        activeSlide: 0,
        voiceover: {
          sk: voiceover1sk,
          en: voiceover1en,
        },
        animation1: {
          sk: bookAnimation1,
          en: bookAnimation1,
        },
        text: {
          sk: (
            <>
              Asi pre viacerých z vás sú to nové pojmy, ale je dôležité si ich
              vyjasniť hneď na začiatku. O <b>konverziu</b> ide vtedy, ak sa
              historická budova, ktorá už prestávala slúžiť svojmu účelu a
              chátra, obnoví, pričom sa úplne zmení jej funkčná náplň. Často ide
              najmä o bývalé priemyselné alebo poľnohospodárske objekty, ako sú
              sýpky, mlyny, fabriky, alebo aj bývalé kasárne, ktoré po ich
              rekonštrukcií a obnove slúžia úplne inému účelu, napr. pre
              kultúrne účely.
            </>
          ),
          en: (
            <>
              These are probably new terms to many of you, but it is important
              to be clear about them at the outset. <b>Conversion</b> is when a
              historic building that has outlived its purpose and is falling
              into disrepair is restored, completely changing its functional
              use. Often this is the case in particular with former industrial
              or agricultural buildings such as granaries, mills, factories, or
              even former barracks which, after their reconstruction and
              restoration, serve a completely different purpose, e.g. for
              cultural purposes.
            </>
          ),
        },
        text1: {
          sk: 'Konverzia',
          en: 'Conversion',
        },
        text2: {
          sk: (
            <>
              Zo synagógy v Trnave <br /> sa stala kaviareň
            </>
          ),
          en: (
            <>
              The synagogue in Trnava <br /> became a cafe
            </>
          ),
        },
      },
    },
    {
      Component: Book2,
      props: {
        albumBase: bookBase,
        count: 5,
        activeSlide: 1,
        voiceover: {
          sk: voiceover2sk,
          en: voiceover2en,
        },
        animation1: {
          sk: bookAnimation2,
          en: bookAnimation2,
        },
        text: {
          sk: (
            <>
              Ak ide aspoň o nejakú formu prispôsobenia pôvodnej budovy pre nový
              účel, hovoríme o <b>adaptácii.</b>
            </>
          ),
          en: (
            <>
              If there is at least some form of adaptation of the original
              building for a new purpose, we speak of <b>adaptation.</b>
            </>
          ),
        },
        text1: {
          sk: 'Adaptácia',
          en: 'Adaptation',
        },
        text2: {
          sk: (
            <>
              Adaptácia starého vodojemu <br /> na vyhliadkovú vežu
            </>
          ),
          en: (
            <>
              Adaptation of the old reservoir <br /> into a lookout tower
            </>
          ),
        },
      },
    },
    {
      Component: Book2,
      props: {
        albumBase: bookBase,
        count: 5,
        activeSlide: 2,
        voiceover: {
          sk: voiceover3sk,
          en: voiceover3en,
        },
        animation1: {
          sk: bookAnimation3,
          en: bookAnimation3,
        },
        text: {
          sk: (
            <>
              O <b>rekonštrukcii</b> hovoríme vtedy, ak budovu uvedieme do
              pôvodného stavu, teda ak stavebné zásahy do pôvodnej budovy majú
              za cieľ odstrániť účinky jej opotrebovania, ale budova bude ďalej
              slúžiť svojmu pôvodnému účelu. Ide napríklad o rekonštrukciu
              stredovekého meštianskeho domu, kde na prízemí bol nejaký obchod a
              na vyšších podlažiach boli byty. Ak aj po rekonštrukcii tohoto
              domu, budú na prízemí nejaké obchodné prevádzky a na vyšších
              podlažiach byty, môžeme hovoriť o rekonštrukcii.
            </>
          ),
          en: (
            <>
              We speak of <b>reconstruction</b> when a building is restored to
              its original state, i.e. when structural interventions in the
              original building are intended to remove the effects of wear and
              tear, but the building will continue to serve its original
              purpose. This is, for example, the reconstruction of a medieval
              town house where there was a shop on the ground floor and flats on
              the upper floors. If, even after the reconstruction of this house,
              there are some commercial establishments on the ground floor and
              flats on the upper floors, we can talk about reconstruction.{' '}
            </>
          ),
        },
        text1: {
          sk: 'Rekonštrukcia',
          en: 'Reconstruction',
        },
        text2: {
          sk: (
            <>
              Rekonštrukcia meštianskeho <br /> domu v Trnave
            </>
          ),
          en: (
            <>
              Reconstruction of a bourgeois <br /> house in Trnava
            </>
          ),
        },
      },
    },
    {
      Component: Book2,
      props: {
        albumBase: bookBase,
        count: 5,
        activeSlide: 3,
        voiceover: {
          sk: voiceover4sk,
          en: voiceover4en,
        },
        animation1: {
          sk: bookAnimation4,
          en: bookAnimation4,
        },
        text: {
          sk: (
            <>
              <b>Modernizáciou</b> rozumieme zlepšenie vybavenia stavebných
              objektov podľa dnešných požiadaviek, to znamená, že časť
              stavebných konštrukcií a vybavenia budov sa nahrádza modernejšími
              stavebnými konštrukciami a technológiami.
            </>
          ),
          en: (
            <>
              By <b>modernisation</b> we mean the improvement of building
              facilities according to today's requirements, i.e. replacing part
              of the building structures and building equipment with more modern
              building structures and technologies.{' '}
            </>
          ),
        },
        text1: {
          sk: 'Modernizácia',
          en: 'Modernisation',
        },
        text4: {
          sk: (
            <>
              Modernizácia <br /> hradu v Uhrovci
            </>
          ),
          en: (
            <>
              Modernization <br /> of the castle in Uhrovec
            </>
          ),
        },
        text5: {
          sk: (
            <>
              Modernizácia <br /> hradu Het Steen <br /> v Antverpách
            </>
          ),
          en: (
            <>
              Modernization <br /> of the castle Het Steen <br /> in Antwerp
            </>
          ),
        },
      },
    },
    {
      Component: Book2,
      props: {
        albumBase: bookBase,
        count: 5,
        activeSlide: 4,
        voiceover: {
          sk: voiceover5sk,
          en: voiceover5en,
        },
        animation1: {
          sk: bookAnimation5,
          en: bookAnimation5,
        },
        text: {
          sk: (
            <>
              V mnohých prípadoch ide aj o vzájomnú kombináciu jednotlivých
              prístupov. A často ide aj o to, že stará budova sa zrekonštruuje a
              k nej sa napríklad v pôvodnom vnútrobloku dostavia úplne nová
              časť. V tom prípade ide o rekonštrukciu a dostavbu. Alebo sa na
              pôvodnú zrekonštruovanú budovu nadstaví ešte jedno podlažie, vtedy
              hovoríme o rekonštrukcii a nadstavbe.
            </>
          ),
          en: (
            <>
              In many cases it also involves a combination of approaches. And
              often it also involves renovating an old building and adding a
              completely new part to it, for example, in the original courtyard.
              In that case it is a case of renovation and extension. Or another
              floor is added on top of the original renovated building, in which
              case we are talking about reconstruction and extension.
            </>
          ),
        },
        text1: {
          sk: 'Modernizácia',
          en: 'Modernisation',
        },
        text3: {
          sk: 'a dostavba',
          en: 'and completion',
        },
        text6: {
          sk: (
            <>
              RekonŠtrukcia a <br /> dostavba nádvoria <br /> v Trnave
            </>
          ),
          en: (
            <>
              Reconstruction and <br /> completion of the courtyard <br /> in
              Trnava
            </>
          ),
        },
        text7: {
          sk: (
            <>
              Moderná <br /> dostavba kostola <br /> v Berlíne
            </>
          ),
          en: (
            <>
              Modern <br /> completion of a church <br /> in Berlin
            </>
          ),
        },
      },
    },
  ],
}

export default chapter2
