import React from 'react'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import bg6 from '../assets/kurz2024/bg6.png'
import Animated2 from '../v2/components/Animated/Animated2'
import animation1 from '../assets/kurz2024/EXPORT/K03/K03-1.mp4'
import animation2 from '../assets/kurz2024/EXPORT/K03/K03-2.mp4'
import animation3 from '../assets/kurz2024/EXPORT/K03/K03-3.mp4'
import animation4 from '../assets/kurz2024/EXPORT/K03/K03-4.mp4'
import voiceoverK03_1SK from '../assets/kurz2024/VOICEOVER/SK/VO/3.1.mp3'
import voiceoverK03_1EN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.1 mp3.mp3'

import voiceoverK03_2SK from '../assets/kurz2024/VOICEOVER/SK/VO/3.2.mp3'
import voiceoverK03_2EN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.2.mp3'

import voiceoverK03_3SK from '../assets/kurz2024/VOICEOVER/SK/VO/3.3.mp3'
import voiceoverK03_3EN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.3.mp3'

import voiceoverK03_4SK from '../assets/kurz2024/VOICEOVER/SK/VO/3.4.mp3'
import voiceoverK03_4EN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/3.4..mp3'

const chapter3 = {
  url: '/chapter-3',
  name: 'chapter3',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 2,
        bg: bg6,
        fontSize: '78px',
        title1: {
          sk: '3. kapitola',
          en: '3. chapter',
        },
        title2: {
          sk: (
            <>
              Kultúrne, architektonické <br />a stavebné <span>dedičstvo</span>{' '}
              <br /> <span>Slovenska</span>
            </>
          ),
          en: (
            <>
              Cultural, architectural <br />
              and building <span>heritage </span> <br />{' '}
              <span>of Slovakia</span>
            </>
          ),
        },
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation1,
        disableAnimationLoop: true,
        voiceover: {
          sk: voiceoverK03_1SK,
          en: voiceoverK03_1EN,
        },
        text: {
          sk: 'Každá krajina má svoju históriu, ktorá sa odráža aj v architektúre a stavebníctve. Naši predkovia budovali svoje obydlia, budovali školy, kostoly, hrady a zámky a neskôr byty a domy a aj fabriky a priemyselné areály, ktoré nesú pečať obdobia svojho vzniku po stránke stavebnej a architektonickej. Aj pri tvorbe nových objektov nemožno opomenúť existenciu tých stavieb, ktoré už vo verejnom priestore existujú.',
          en: 'Every country has its own history, which is also reflected in its architecture and construction. Our ancestors built their dwellings, schools, churches, castles and palaces, and later apartments and houses, as well as factories and industrial complexes, which bear the stamp of the period of their origin in terms of their construction and architecture. Even in the creation of new buildings, the existence of those structures that already exist in the public space cannot be overlooked.',
        },
        activeSlide: 0,
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation2,
        disableAnimationLoop: true,
        voiceover: {
          sk: voiceoverK03_2SK,
          en: voiceoverK03_2EN,
        },
        text: {
          sk: 'Zásah do historického prostredia musí byť realizovaný citlivo a ohľaduplne, aby zachoval génia loci daného miesta. To znamená, aby sa zachoval duch miesta. A to platí aj pri obnove, rekonštrukcii aj konverzii starých historických objektov. Každý historický objekt je nemým svedkom života človeka danej doby. Sú tu a svojou prítomnosťou nám, súčasníkom, nedovoľujú opomínať odkaz histórie. Našou povinnosťou je tento odkaz minulosti rešpektovať a chrániť.\n',
          en: 'Intervention in the historic environment must be carried out sensitively and considerately in order to preserve the genius loci of the site. That is, to preserve the spirit of the place. And this also applies to the restoration, reconstruction and conversion of old historic buildings. Every historic building is a mute witness to the life of a person of a given time. They are there and by their presence they do not allow us, the contemporaries, to neglect the legacy of history. It is our duty to respect and protect this legacy of the past.',
        },
        activeSlide: 1,
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation3,
        disableAnimationLoop: true,
        voiceover: {
          sk: voiceoverK03_3SK,
          en: voiceoverK03_3EN,
        },
        text: {
          sk: 'Prostredníctvom tohto kurzu chceme vyslať signál, že pre každú krajinu je dôležité nielen chrániť, ale aj zveľaďovať naše kultúrne dedičstvo vrátane architektonického a stavebného, keďže ono sa vhodnou ochranou môže ďalej rozvíjať a zveľaďovať, čím sa stáva autentickým posolstvom pre nasledujúce generácie. ',
          en: 'Through this course, we want to convey the message that it is important for every country not only to protect but also to enhance our cultural heritage, including architectural and built heritage, as it can be further developed and enhanced by appropriate protection, thus becoming an authentic message for future generations.',
        },
        activeSlide: 2,
      },
    },
    {
      Component: Animated2,
      props: {
        animation: animation4,
        disableAnimationLoop: true,
        voiceover: {
          sk: voiceoverK03_4SK,
          en: voiceoverK03_4EN,
        },
        text: {
          sk: (
            <>
              V tomto e-kurze, ktorého hlavným cieľom je naučiť vás{' '}
              <b>vnímať architektúru</b>, chceme upriamiť Vašu pozornosť aj na
              to, že prelínanie kultúrnych hodnôt minulosti a súčasnosti je
              procesom, ktorý si žiada citlivý prístup, snahu o nadviazanie
              určitého typu komunikácie s odkazom minulosti. Chceme vám ukázať,
              že architekti a projektanti musia k návrhu rekonštrukcie, obnovy,
              modernizácie či konverzie historickej architektonickej pamiatky
              pristupovať vždy s rešpektom a úctou k už prežitému a musia hľadať
              nové cesty existencie/koexistencie starého a nového s cieľom
              zachovať to hodnotné - historické a zároveň vytvoriť to nové -
              súčasné.
            </>
          ),
          en: (
            <>
              In this e-course, whose main aim is to teach you how to{' '}
              <b>perceive architecture</b>, we also want to draw your attention
              to the fact that the intermingling of cultural values of the past
              and the present is a process that requires a sensitive approach,
              an attempt to establish a certain type of communication with
              legacy of the past. We want to show you that architects and
              designers must always approach the design of reconstruction,
              restoration, modernisation or conversion of a historic
              architectural monument with respect and reverence for what has
              already survived and must seek new ways of existence/coexistence
              of the old and the new in order to preserve the valuable - the
              historic - and at the same time create the new - the contemporary.
            </>
          ),
        },
        activeSlide: 3,
      },
    },
  ],
}

export default chapter3
