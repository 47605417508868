import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import button from '../../assets/kurz2024/button2.png'
import buttonVariant2 from '../../assets/kurz2024/button1variant2.png'
import fonts from '../../styles/fonts'
import { hoverTransition, moveUp } from '../../styles/hover'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'

const Button1 = ({ text1, text2, sx, variant = 1, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  const isSoundActive = useRef(false)

  useEffect(() => {
    isSoundActive.current = false

    setTimeout(() => {
      isSoundActive.current = true
    }, 200)
  })

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: '1',
        maxWidth: '27vw',
        ...hoverTransition,
        '&:hover': {
          ...moveUp,
        },
        ...sx,
      }}
      onMouseEnter={() => {
        isSoundActive.current && playHover()
      }}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
      <Box
        component={'img'}
        src={variant === 1 ? button : buttonVariant2}
        sx={{
          width: '100%',
          objectFit: 'contain',
        }}
      />

      <Typography
        sx={{
          ...fonts.Urbanist['24px']({
            fontWeight: '900',
            lineHeight: 30,
          }),
          position: 'absolute',
          translate: '0 -6px',
          textAlign: 'center',
        }}
      >
        {text1 && (
          <>
            <span
              style={{
                textTransform: 'uppercase',
                color: '#00AEAE',
              }}
            >
              {text1}
            </span>

            <br />
          </>
        )}

        <span>{text2}</span>
      </Typography>
    </Box>
  )
}

export default Button1
