import { useState } from 'react'

const useVideoPlayer = () => {
  const [isShownPreview, setIsShownPreview] = useState(true)
  const [isPLaying, setIsPlaying] = useState(false)

  const onPlayClick = () => {
    setIsShownPreview(false)
    setIsPlaying(!isPLaying)
  }

  return {
    isShownPreview,
    setIsShownPreview,
    isPLaying,
    setIsPlaying,
    onPlayClick,
  }
}

export default useVideoPlayer
