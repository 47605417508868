import React from 'react'
import Quiz from '../components/Quiz/Quiz'
import QuizResult from '../components/Quiz/QuizResult'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'

const quiz = {
  url: '/quiz',
  name: 'quiz',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 2,
        fontSize: '130px',
        title1: {
          sk: '6. kapitola',
          en: '6. chapter',
        },
        title2: {
          sk: 'Veľký kvíz',
          en: 'Big Quiz',
        },
      },
    },
    Quiz,
    QuizResult,
  ],
}

export default quiz
