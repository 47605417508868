import React from 'react'
import Box from '@mui/material/Box'
import navigationBase0 from '../../../assets/kurz2024/k3nav1.png'
import navigationBase1 from '../../../assets/kurz2024/k3nav2.png'
import navigationBase2 from '../../../assets/kurz2024/k3nav3.png'
import navigationBase3 from '../../../assets/kurz2024/k3nav4.png'

const BookNavigation = ({ activeSlide }) => {
  const navigationBase = {
    0: navigationBase0,
    1: navigationBase1,
    2: navigationBase2,
    3: navigationBase3,
  }[activeSlide]

  return (
    <Box
      sx={{
        width: '15vw',
        position: 'absolute',
        left: '50%',
        bottom: '10px',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '19',
      }}
    >
      <Box
        component={'img'}
        src={navigationBase}
        sx={{
          width: '100%',
        }}
      />
    </Box>
  )
}

export default BookNavigation
