import React from 'react'
import BackgroundWave from '../BackgroundWave/BackgroundWave'
import background from '../../../assets/kurz2024/bg10.png'
import Logo from '../Logo/Logo'
import Box from '@mui/material/Box'
import fonts from '../../../styles/fonts'
import Button from '../../../components/Buttons/Button1'
import SliderWrapper from '../../../components/Slider/SliderWrapper'
import ButtonMenu from '../../../components/Buttons/ButtonMenu'
import popup1 from '../../../assets/kurz2024/popup1.png'
import popup3 from '../../../assets/kurz2024/popup3.png'
import popup4 from '../../../assets/kurz2024/popup4.png'
import { usePlaySound } from '../../../hooks/usePlaySound'

const ChapterStart = ({
  language,
  goToPrevSlide,
  goToNextSlide,
  title1,
  title2,
  isActive,
  fontSize = '96px',
  bg,
  img,
  imgWidth,
  variant = 1,
}) => {
  usePlaySound({ isActive })

  const popupImage = { 1: popup1, 2: popup3, 3: popup4, 4: popup1 }[variant]
  const textColor = { 1: '#000', 2: '#fff', 3: '#fff', 4: '#000' }[variant]
  const linearBg = {
    1: 'linear-gradient(90deg, #6936C5 0%, #5C2A93 100%)',
    2: 'linear-gradient(76deg, #D7C7FF 3.42%, #C7B0FF 97.76%)',
    3: 'linear-gradient(76deg, #D7C7FF 3.42%, #C7B0FF 97.76%)',
    4: 'linear-gradient(76deg, #F2ACF4 3.42%, #FF6F48 97.76%)',
  }[variant]

  return (
    <SliderWrapper>
      <BackgroundWave
        background={bg || background}
        sx={{
          background: linearBg,
        }}
      />

      <ButtonMenu />

      <Logo />

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '83vw',
          }}
        >
          <Box
            component={'img'}
            src={popupImage}
            sx={{
              width: '100%',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '1% 1% 2% 1%',
            }}
          >
            {title1 && (
              <Box
                sx={{
                  ...fonts.Urbanist['36px']({
                    color: '#F0F',
                    fontWeight: '900',
                  }),
                  textTransform: 'uppercase',
                }}
              >
                {title1[language]}
              </Box>
            )}

            {img && (
              <Box
                component={'img'}
                src={img}
                sx={{
                  width: imgWidth,
                }}
              />
            )}

            <Box
              sx={{
                ...fonts.Urbanist[fontSize]({
                  color: textColor,
                  fontWeight: '900',
                }),
                textAlign: 'center',
              }}
            >
              {title2[language]}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '4vw',
                marginTop: '26px',
              }}
            >
              <Button
                variant={2}
                sx={{
                  minWidth: '250px',
                }}
                text={language === 'sk' ? 'späť' : 'back'}
                onClick={() => {
                  goToPrevSlide()
                }}
              />

              <Button
                sx={{
                  minWidth: '250px',
                }}
                text={language === 'sk' ? 'pokračuj' : 'continue'}
                onClick={() => {
                  goToNextSlide()
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SliderWrapper>
  )
}

export default ChapterStart
