import React from 'react'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import bg10 from '../assets/kurz2024/bg10.png'
import Instructions from '../components/Instructions/Instructions'
import bg8 from '../assets/kurz2024/bg8.png'
import k5img1 from '../assets/kurz2024/k5img1.png'
import k5img2 from '../assets/kurz2024/k5img2.png'
import k5img3 from '../assets/kurz2024/k5img3.png'
import k5img4 from '../assets/kurz2024/k5img4.png'
import Box from '@mui/material/Box'
import fonts from '../styles/fonts'
import Strip from '../components/Strip/Strip'
import { strip3Images, strip3Triggers } from '../strips/strip3'
import { stripk0503Images, stripk0503Triggers } from '../strips/stripk0503'
import { stripk0504Images, stripk0504Triggers } from '../strips/stripk0504'
import { stripk0505Images, stripk0505Triggers } from '../strips/stripk0505'

const chapter5 = {
  url: '/chapter-5',
  name: 'chapter5',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 2,
        bg: bg10,
        fontSize: '78px',
        title1: {
          sk: '5. kapitola',
          en: '5. chapter',
        },
        title2: {
          sk: (
            <>
              Príklady architektonických <br />{' '}
              <span>konverzií zo zahraničia</span>
            </>
          ),
          en: (
            <>
              Examples of architectural <br />{' '}
              <span>conversions from abroad</span>
            </>
          ),
        },
      },
    },
    // Instructions,
    {
      Component: ChapterStart,
      props: {
        variant: 4,
        bg: bg8,
        img: k5img1,
        imgWidth: '20%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span style={{ color: '#6936E5' }}>Le Carreau du Temple,</span>{' '}
              Paríž <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                Francúzsko, studio Milou Architecture
              </Box>
            </>
          ),
          en: (
            <>
              <span style={{ color: '#6936E5' }}>Le Carreau du Temple,</span>{' '}
              Paris <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                France, studio Milou Architecture
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Strip,
      props: {
        images: strip3Images,
        triggers: strip3Triggers,
        scrollHandler: (percentage, language) => {
          return {
            sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
            en: 'Technologies in green architecture and construction sector',
          }[language]
        },
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 4,
        bg: bg8,
        img: k5img2,
        imgWidth: '20%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span style={{ color: '#6936E5' }}>Vítkovické železárny,</span>{' '}
              Ostrava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                Česko, architekt Josef Pleskot
              </Box>
            </>
          ),
          en: (
            <>
              <span style={{ color: '#6936E5' }}>Vítkovice Ironworks,</span>{' '}
              Ostrava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                Czech Republic, architect Josef Pleskot
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Strip,
      props: {
        images: stripk0503Images,
        triggers: stripk0503Triggers,
        scrollHandler: (percentage, language) => {
          return {
            sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
            en: 'Technologies in green architecture and construction sector',
          }[language]
        },
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 4,
        bg: bg8,
        img: k5img3,
        imgWidth: '20%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span style={{ color: '#6936E5' }}>
                Technologicko-kultúrny park <br /> Lavrion,
              </span>{' '}
              Lavrion <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                Grécko
              </Box>
            </>
          ),
          en: (
            <>
              <span style={{ color: '#6936E5' }}>
                Lavrion Technological and Cultural <br /> Park
              </span>{' '}
              Lavrion <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                Greece
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Strip,
      props: {
        images: stripk0504Images,
        triggers: stripk0504Triggers,
        scrollHandler: (percentage, language) => {
          return {
            sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
            en: 'Technologies in green architecture and construction sector',
          }[language]
        },
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 4,
        bg: bg8,
        img: k5img4,
        imgWidth: '20%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span style={{ color: '#6936E5' }}>Tate Modern,</span> Londýn{' '}
              <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                Veľká Británia, ateliér Herzog & de Meuron
              </Box>
            </>
          ),
          en: (
            <>
              <span style={{ color: '#6936E5' }}>Tate Modern</span> London{' '}
              <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#000',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                UK, Herzog & de Meuron
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Strip,
      props: {
        images: stripk0505Images,
        triggers: stripk0505Triggers,
        scrollHandler: (percentage, language) => {
          return {
            sk: 'TECHNOLÓGIE V ZELENEJ ARCHITEKTÚRE A STAVEBNÍCTVE',
            en: 'Technologies in green architecture and construction sector',
          }[language]
        },
      },
    },
  ],
}

export default chapter5
