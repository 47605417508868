import React from 'react'
import Box from '@mui/material/Box'
import fonts from '../../styles/fonts'
import Button from '../Buttons/Button2'

const MenuSection = ({ buttons, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        gap: '15px',
        ...sx,
      }}
    >
      {buttons.map((button, index) => (
        <Button
          key={button.id}
          text1={button.text1}
          text2={button.text2}
          sx={{
            ...fonts.Rubik['18px']({
              fontWeight: '700',
            }),
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={button.onClick}
        />
      ))}
    </Box>
  )
}

export default MenuSection
