import React from 'react'
import Animated2 from '../v2/components/Animated/Animated2'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import bg7 from '../assets/kurz2024/bg7.png'
import bg8 from '../assets/kurz2024/bg8.png'
import k4bg1 from '../assets/kurz2024/k4bg1.png'
import k4img1 from '../assets/kurz2024/k4img1.png'
import k4img2 from '../assets/kurz2024/k4img2.png'
import k4img3 from '../assets/kurz2024/k4img3.png'
import k4img8 from '../assets/kurz2024/k4img8.png'
import Video from '../components/Video/Video'
import video1SK from '../assets/kurz2024/EXPORT/K04/K04_01.2/Teplaren_SK01_Final.mp4'
import video1EN from '../assets/kurz2024/EXPORT/K04/K04_01.2/Teplaren_EN01_Final.mp4'
import fonts from '../styles/fonts'
import Box from '@mui/material/Box'
import animation1lottie from '../assets/kurz2024/EXPORT/K04/K04_02/K04_02_01_Pradiareň.json'
import Animated3 from '../v2/components/Animated/Animated3'

import voiceover1sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.1.1.mp3'
import voiceover1en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.1.1.mp3'

import voiceover2sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.1.3.mp3'
import voiceover2en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.1.3.mp3'

import voiceover3sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.1.mp3'
import voiceover3en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.1.mp3'

import k4img0202 from '../assets/kurz2024/k4img0202.png'
import k4img0202main from '../assets/kurz2024/k4img0202main.png'
import k4img0202secondary from '../assets/kurz2024/k4img0202secondary.png'
import k4img0202float1 from '../assets/kurz2024/k4img0202float1.png'
import k4img0202float2 from '../assets/kurz2024/k4img0202float2.png'
import k4img0202float3 from '../assets/kurz2024/k4img0202float3.png'
import voiceover4sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.2.mp3'
import voiceover4en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.2.mp3'

import k4img0203 from '../assets/kurz2024/k4img0203.png'
import k4img0203main from '../assets/kurz2024/k4img0203main.png'
import k4img0203secondary from '../assets/kurz2024/k4img0203secondary.png'
import k4img0203float1 from '../assets/kurz2024/k4img0203float1.png'
import k4img0203float2 from '../assets/kurz2024/k4img0203float2.png'
import k4img0203float3 from '../assets/kurz2024/k4img0203float3.png'
import voiceover5sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.3.mp3'
import voiceover5en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.3.mp3'

import k4img0204 from '../assets/kurz2024/k4img0204.png'
import k4img0204main from '../assets/kurz2024/k4img0204main.png'
import k4img0204secondary from '../assets/kurz2024/k4img0204secondary.png'
import k4img0204float1 from '../assets/kurz2024/k4img0204float1.png'
import k4img0204float2 from '../assets/kurz2024/k4img0204float2.png'
import k4img0204float3 from '../assets/kurz2024/k4img0204float3.png'
import voiceover6sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.4.mp3'
import voiceover6en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.4.mp3'

import k4img0205 from '../assets/kurz2024/k4img0205.png'
import k4img0205main from '../assets/kurz2024/k4img0205main.png'
import k4img0205secondary from '../assets/kurz2024/k4img0205secondary.png'
import k4img0205float1 from '../assets/kurz2024/k4img0205float1.png'
import k4img0205float2 from '../assets/kurz2024/k4img0205float2.png'
import k4img0205float3 from '../assets/kurz2024/k4img0205float3.png'
import voiceover7sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.5.mp3'
import voiceover7en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.5.mp3'

import k4img0206 from '../assets/kurz2024/k4img0206.png'
import k4img0206main from '../assets/kurz2024/k4img0206main.png'
import k4img0206secondary from '../assets/kurz2024/k4img0206secondary.png'
import k4img0206float1 from '../assets/kurz2024/k4img0206float1.png'
import k4img0206float2 from '../assets/kurz2024/k4img0206float2.png'
import voiceover8sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.6.mp3'
import voiceover8en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.6.mp3'

import k4img0207 from '../assets/kurz2024/k4img0207.png'
import k4img0207main from '../assets/kurz2024/k4img0207main.png'
import k4img0207secondary from '../assets/kurz2024/k4img0207secondary.png'
import k4img0207float1 from '../assets/kurz2024/k4img0207float1.png'
import k4img0207float2 from '../assets/kurz2024/k4img0207float2.png'
import voiceover9sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.7.mp3'
import voiceover9en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.7.mp3'

import k4img0208 from '../assets/kurz2024/k4img0208.png'
import k4img0208main from '../assets/kurz2024/k4img0208main.png'
import k4img0208secondary from '../assets/kurz2024/k4img0208secondary.png'
import k4img0208float1 from '../assets/kurz2024/k4img0208float1.png'
import k4img0208float2 from '../assets/kurz2024/k4img0208float2.png'
import voiceover10sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.8.mp3'
import voiceover10en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.8.mp3'

import k4img0209 from '../assets/kurz2024/k4img0209.png'
import k4img0209main from '../assets/kurz2024/k4img0209main.png'
import k4img0209secondary from '../assets/kurz2024/k4img0209secondary.png'
import k4img0209float1 from '../assets/kurz2024/k4img0209float1.png'
import k4img0209float2 from '../assets/kurz2024/k4img0209float2.png'
import voiceover11sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.9.mp3'
import voiceover11en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.9.mp3'

import k4img0210 from '../assets/kurz2024/k4img0210.png'
import k4img0210main from '../assets/kurz2024/k4img0210main.png'
import k4img0210secondary from '../assets/kurz2024/k4img0210secondary.png'
import k4img0210float1 from '../assets/kurz2024/k4img0210float1.png'
import k4img0210float2 from '../assets/kurz2024/k4img0210float2.png'
import k4img0210float3 from '../assets/kurz2024/k4img0210float3.png'
import voiceover12sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.10.mp3'
import voiceover12en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.10.mp3'

import k4img0211 from '../assets/kurz2024/k4img0211.png'
import k4img0211main from '../assets/kurz2024/k4img0211main.png'
import k4img0211secondary from '../assets/kurz2024/k4img0211secondary.png'
import k4img0211float1 from '../assets/kurz2024/k4img0211float1.png'
import k4img0211float2 from '../assets/kurz2024/k4img0211float2.png'
import k4img0211float3 from '../assets/kurz2024/k4img0211float3.png'
import k4img0211extra1 from '../assets/kurz2024/k4img0211extra1.png'
import k4img0211extra2 from '../assets/kurz2024/k4img0211extra2.png'
import voiceover13sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.11.mp3'
import voiceover13en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.11.mp3'

import k4img0212 from '../assets/kurz2024/k4img0212.png'
import k4img0212main from '../assets/kurz2024/k4img0212main.png'
import k4img0212secondary from '../assets/kurz2024/k4img0212secondary.png'
import k4img0212float1 from '../assets/kurz2024/k4img0212float1.png'
import k4img0212float2 from '../assets/kurz2024/k4img0212float2.png'
import voiceover14sk from '../assets/kurz2024/VOICEOVER/SK/VO/4.2.12.mp3'
import voiceover14en from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/4.2.12.mp3'

const Text = ({ children, secondColor }) => {
  return (
    <Box
      sx={{
        ...fonts.Urbanist['32px']({
          color: '#fff',
          fontWeight: '500',
          lineHeight: 40,
        }),
        '& b': {
          color: secondColor,
        },
      }}
    >
      {children}
    </Box>
  )
}

const chapter4 = {
  url: '/chapter-4_1',
  name: 'chapter4_1',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 2,
        bg: bg7,
        fontSize: '78px',
        title1: {
          sk: '4. kapitola',
          en: '4. chapter',
        },
        title2: {
          sk: (
            <>
              Príklady architektonických <br />{' '}
              <span>konverzií zo Slovenska</span>
            </>
          ),
          en: (
            <>
              Examples of architectural <br />{' '}
              <span>conversions from Slovakia</span>
            </>
          ),
        },
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 3,
        bg: bg8,
        img: k4img8,
        imgWidth: '22.5%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span>Jurkovičova tepláreň,</span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architekt Martin Paško a kolektív
              </Box>
            </>
          ),
          en: (
            <>
              <span>Jurkovič's heating plant,</span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architect Martin Paško and team
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Animated2,
      props: {
        // animation: animation1,
        bg: k4bg1,
        voiceover: {
          sk: voiceover1sk,
          en: voiceover1en,
        },
        text: {
          sk: 'Funkcionalistická budova Jurkovičovej teplárne je jednou z posledných spomienok na zlatú industriálnu éru Bratislavy a je naozaj chvályhodné, že sa podarilo zachrániť nielen samotnú budovu, ktorej architektúra bola od svojho vzniku jedinečná, ale že sa v rámci jej rekonštrukcie a konverzie podarilo zachrániť aj časť jej technického vybavenia, ktoré pripomína aj súčasníkom jej pôvodný účel.',
          en: "The functionalist building of Jurkovič's heating plant is one of the last memories of the golden industrial era of Bratislava, and it is truly commendable that not only the building itself, whose architecture was unique from its inception, was saved, but that in the course of its reconstruction and conversion it was also possible to save part of its technical equipment, which reminds its contemporaries of its original purpose.",
        },
        modal: {
          img: k4img1,
          text: {
            sk: (
              <>
                Funkcionalistická{' '}
                <b>
                  budova Jurkovičovej teplárne je jednou  z posledných spomienok
                  na zlatú industriálnu éru Bratislavy
                </b>{' '}
                a je naozaj chvályhodné, že sa podarilo zachrániť nielen samotnú
                budovu, ktorej architektúra bola od svojho vzniku jedinečná, ale
                že sa v rámci jej rekonštrukcie a konverzie podarilo{' '}
                <b>
                  zachrániť aj časť jej technického vybavenia, ktoré pripomína
                  aj súčasníkom jej pôvodný účel.
                </b>
              </>
            ),
            en: (
              <>
                The functionalist{' '}
                <b>
                  building of Jurkovič's heating plant is one of the last
                  memories of the golden industrial era of Bratislava,
                </b>{' '}
                and it is truly commendable that not only the building itself,
                whose architecture was unique from its inception, was saved, but
                that in the course of its reconstruction and conversion it was
                also possible to{' '}
                <b>
                  save part of its technical equipment, which reminds its
                  contemporaries of its original purpose.
                </b>
              </>
            ),
          },
        },
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
    {
      Component: Animated2,
      props: {
        // animation: animation1,
        bg: k4bg1,
        voiceover: {
          sk: voiceover2sk,
          en: voiceover2en,
        },
        text: {
          sk: 'V roku 2021 bola budova uvedená do prevádzky a dnes sa stala veľmi vyhľadávaným a obľúbeným miestom návštevníkov aj obyvateľov Bratislavy. Za rekonštrukciu teplárne získali jej autori mnohé architektonické ceny doma aj v zahraničí.',
          en: 'In 2021 the building was put into operation and today it has become a very popular and favoured place for visitors and residents of Bratislava. For the reconstruction of the heating plant, its authors have won many architectural prizes at home and abroad.',
        },
        modal: {
          img: k4img2,
          text: {
            sk: (
              <>
                <b>V roku 2021 bola budova uvedená do prevádzky</b> a dnes sa
                stala veľmi vyhľadávaným a obľúbeným miestom návštevníkov aj
                obyvateľov Bratislavy.{' '}
                <b>
                  Za rekonštrukciu teplárne získali jej autori mnohé
                  architektonické ceny
                </b>{' '}
                doma aj v zahraničí.
              </>
            ),
            en: (
              <>
                <b>In 2021 the building was put into operation</b> and today it
                has become a very popular and favoured place for visitors and
                residents of Bratislava.{' '}
                <b>
                  For the reconstruction of the heating plant, its authors have
                  won many architectural prizes
                </b>{' '}
                at home and abroad.
              </>
            ),
          },
        },
      },
    },
    {
      Component: ChapterStart,
      props: {
        variant: 3,
        bg: bg8,
        img: k4img3,
        imgWidth: '35%',
        fontSize: '78px',
        title2: {
          sk: (
            <>
              <span>Pradiareň 1900,</span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architekti Peter Bouda a Ivan Masár a kolektív
              </Box>
            </>
          ),
          en: (
            <>
              <span>Spinning mill 1900,</span> Bratislava <br />
              <Box
                sx={{
                  marginTop: '12px',
                  ...fonts.Urbanist['40px']({
                    color: '#fff',
                    fontWeight: '700',
                  }),
                  textAlign: 'center',
                }}
              >
                architects Peter Bouda and Ivan Masár and team
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Animated2,
      props: {
        // animation: animation1,
        animationLottie: animation1lottie,
        voiceover: {
          sk: voiceover3sk,
          en: voiceover3en,
        },
        text: {
          sk: 'Pradiareň 1900 je zrekonštruovaná historická budova v Bratislave. Pôvodná budova Pradiarne patrila k ikonám priemyselnej architektúry 20. storočia na Slovensku a pôvodne slúžila ako pradiareň v rámci areálu Bratislavskej cvernovej továrne.',
          en: 'Spinning mill 1900 is a reconstructed historical building in Bratislava. The original spinning mill building was one of the icons of 20th century industrial architecture in Slovakia and originally served as a spinning mill within the premises of the Bratislava yarn factory.',
        },
        header: {
          sk: (
            <>
              Pradiareň 1900, Bratislava <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                architekti Peter Bouda a Ivan Masár a kolektív
              </Box>
            </>
          ),
          en: (
            <>
              Spinning mill 1900, Bratislava <br />
              <Box
                sx={{
                  marginTop: '6px',
                  ...fonts.Urbanist['18px']({
                    color: '#fff',
                    fontWeight: '700',
                    lineHeight: 20,
                  }),
                  textAlign: 'center',
                }}
              >
                architects Peter Bouda and Ivan Masár and team
              </Box>
            </>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 0,
        bg: k4img0202,
        mainImg: k4img0202main,
        secondaryImg: k4img0202secondary,
        floatingImages: [
          {
            img: k4img0202float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0202float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0202float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '1900',
        voiceover: {
          sk: voiceover4sk,
          en: voiceover4en,
        },
        description: {
          sk: 'Pôvodné vedenie továrne v silocentrále',
          en: 'The original management of the factory in the silo plant',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Text secondColor={'#CCBDA8'}>
              S výstavbou sa{' '}
              <b>
                začalo v roku 1900 a už v roku 1902 bola postavená južná časť
                Pradiarne
              </b>{' '}
              spolu s nadväzujúcim objektom Silocentrály, ktorý slúžil ako{' '}
              <b>energocentrum celého areálu.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#CCBDA8'}>
              Its construction{' '}
              <b>
                began in 1900 and already in 1902 the southern part of the
                spinning mill was built
              </b>{' '}
              together with the adjacent power generator building, which served
              as the <b>energy centre of the whole complex.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 1,
        bg: k4img0203,
        mainImg: k4img0203main,
        secondaryImg: k4img0203secondary,
        floatingImages: [
          {
            img: k4img0203float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0203float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
          {
            img: k4img0203float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '1905',
        voiceover: {
          sk: voiceover5sk,
          en: voiceover5en,
        },
        description: {
          sk: 'Severná časť továrne',
          en: 'The northern part of the factory',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Text secondColor={'#DD9343'}>
              <b>Úspešnosť výroby Uhorskej cvernovej továrne okolo roku 1905</b>{' '}
              viedla k rozšíreniu pôvodnej budovy{' '}
              <b>dostavaním severnej časti.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#DD9343'}>
              <b>
                The success of the production of the Hungarian spinning mill
                factory around 1905
              </b>{' '}
              led to the extension of the original building by{' '}
              <b>completion of its northern part.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 2,
        bg: k4img0204,
        mainImg: k4img0204main,
        secondaryImg: k4img0204secondary,
        floatingImages: [
          {
            img: k4img0204float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0204float2,
            width: '13.3%',
            bottom: '-8%',
            right: '30%',
          },
          {
            img: k4img0204float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
        ],
        year: '1920',
        voiceover: {
          sk: voiceover6sk,
          en: voiceover6en,
        },
        description: {
          sk: 'Silocentrála fungovala ako energetické centrum továrne',
          en: 'The silo plant functioned as the energy center of the factory',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Text secondColor={'#CCAF2D'}>
              <b>Budova bola plne dokončená v roku 1920</b> a krátko nato sa
              stala <b>najväčším priemyselným výrobným objektom v Bratislave</b>{' '}
              a výroba v nej fungovala takmer 80 rokov.
            </Text>
          ),
          en: (
            <Text secondColor={'#CCAF2D'}>
              <b>The building was fully completed in 1920</b> and shortly
              thereafter became the{' '}
              <b>largest industrial production facility in Bratislava</b> and
              production operated there for almost 80 years.
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 3,
        bg: k4img0205,
        mainImg: k4img0205main,
        secondaryImg: k4img0205secondary,
        floatingImages: [
          {
            img: k4img0205float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0205float2,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
          {
            img: k4img0205float3,
            width: '13.3%',
            top: '-8%',
            right: '15%',
          },
        ],
        year: '1950 - 1960',
        voiceover: {
          sk: voiceover7sk,
          en: voiceover7en,
        },
        description: {
          sk: 'Veľká rekonštrukcia, záber zo Svätoplukovej ulice',
          en: 'Major reconstruction, shot from Svätopluková Street',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Text secondColor={'#F48A7F'}>
              Neskôr sa areál bývalej cvernovej továrne búral a podarilo sa
              zachrániť len budovu pradiarne.{' '}
              <b>Veľké rozširovanie a modernizovanie</b> Pradiarne prebehlo{' '}
              <b>v 50. a 60. rokoch 20. storočia.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#F48A7F'}>
              Later, the former spinning mill factory site was demolished and
              only the spinning mill building itself was preserved.{' '}
              <b>Major expansion and modernisation</b> of the spinning mill took
              place <b>in the 1950s and 1960s.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 4,
        bg: k4img0206,
        mainImg: k4img0206main,
        secondaryImg: k4img0206secondary,
        floatingImages: [
          {
            img: k4img0206float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0206float2,
            width: '17.1%',
            top: '-12%',
            right: '15%',
          },
        ],
        year: '1960 - 1970',
        voiceover: {
          sk: voiceover8sk,
          en: voiceover8en,
        },
        description: {
          sk: 'Pracovníci Pradiarne',
          en: 'Workers of the spinning mill',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Text secondColor={'#FFB5A4'}>
              <b>Každé podlažie malo svoje špecifiká</b> – architektonický návrh
              vychádza vždy z dispozičných potrieb jednotlivých procesov.
            </Text>
          ),
          en: (
            <Text secondColor={'#FFB5A4'}>
              <b>Each floor had its own particular characteristics</b> - the
              architectural design was always based on the layout needs of the
              individual processes.
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 5,
        bg: k4img0207,
        mainImg: k4img0207main,
        secondaryImg: k4img0207secondary,
        floatingImages: [
          {
            img: k4img0207float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0207float2,
            width: '20%',
            top: '-15%',
            right: '12%',
          },
        ],
        year: '1970 - 1980',
        voiceover: {
          sk: voiceover9sk,
          en: voiceover9en,
        },
        description: {
          sk: 'Strojovňa',
          en: 'Engine room',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Text secondColor={'#FCE290'}>
              <b>Surová bavlna</b> z rôznych kútov sveta{' '}
              <b>
                dorazila do Pradiarne v podobe 250 kilogramov ťažkých balíkov
              </b>{' '}
              pripravených na spracovanie.
            </Text>
          ),
          en: (
            <Text secondColor={'#FCE290'}>
              <b>Raw cotton</b> from various parts of the world{' '}
              <b>
                arrived at the spinning mill in the form of 250 kg heavy bales
              </b>{' '}
              ready for processing.
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 6,
        bg: k4img0208,
        mainImg: k4img0208main,
        secondaryImg: k4img0208secondary,
        floatingImages: [
          {
            img: k4img0208float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0208float2,
            width: '20%',
            top: '-15%',
            right: '12%',
          },
        ],
        year: '2007',
        voiceover: {
          sk: voiceover10sk,
          en: voiceover10en,
        },
        description: {
          sk: 'Pohľad na chátrajúcu budovu zo Svätoplukovej ulice',
          en: 'View of the dilapidated building from Svätopluková street',
        },
        source: {
          sk: 'zdroj: www.archinfo.sk',
          en: 'source: www.archinfo.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#FCF190'}>
              V roku 2007 bola Pradiareň zaradená do zoznamu{' '}
              <b>národných kultúrnych pamiatok, ale chátrala.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#FCF190'}>
              In 2007, the Spinning mill was listed as a{' '}
              <b>
                National Historic Landmark, but it was falling into disrepair.
              </b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 7,
        bg: k4img0209,
        mainImg: k4img0209main,
        secondaryImg: k4img0209secondary,
        floatingImages: [
          {
            img: k4img0209float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0209float2,
            width: '10%',
            top: '-10%',
            right: '15%',
          },
        ],
        year: '2018',
        voiceover: {
          sk: voiceover11sk,
          en: voiceover11en,
        },
        description: {
          sk: 'Pohľad na severnú stranu počas rekonštrukcie',
          en: 'View of the north side during the reconstruction',
        },
        source: {
          sk: 'zdroj: bratislava.dnes24.sk',
          en: 'source: bratislava.dnes24.sk',
        },
        text: {
          sk: (
            <Text secondColor={'#FCF190'}>
              V roku 2018 sa vlastník budovy rozhodol,{' '}
              <b>že budovu zrekonštruuje.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#FCF190'}>
              In 2018, the owner of the building{' '}
              <b>decided to renovate the building.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 8,
        bg: k4img0210,
        mainImg: k4img0210main,
        secondaryImg: k4img0210secondary,
        floatingImages: [
          {
            img: k4img0210float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0210float2,
            width: '10%',
            top: '-10%',
            right: '15%',
          },
          {
            img: k4img0210float3,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '2021',
        voiceover: {
          sk: voiceover12sk,
          en: voiceover12en,
        },
        description: {
          sk: 'Pohľad na severnú stranu po rekonštrukcii',
          en: 'View of the north side after reconstruction',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Text secondColor={'#4EF993'}>
              Dnes sú v nej obchodné,{' '}
              <b>kancelárske priestory aj priestory pre kultúru.</b>
            </Text>
          ),
          en: (
            <Text secondColor={'#4EF993'}>
              Today, it houses commercial, <b>office and cultural spaces.</b>
            </Text>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        activeSlide: 9,
        bg: k4img0211,
        mainImg: k4img0211main,
        secondaryImg: k4img0211secondary,
        floatingImages: [
          {
            img: k4img0211float1,
            width: '13.3%',
            top: '9%',
            left: '-5%',
          },
          {
            img: k4img0211float2,
            width: '10%',
            top: '-10%',
            right: '15%',
          },
          {
            img: k4img0211float3,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
        ],
        year: '2022',
        voiceover: {
          sk: voiceover13sk,
          en: voiceover13en,
        },
        description: {
          sk: 'Interiér budovy',
          en: 'Interior of the building',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Box>
              <Text secondColor={'#4EF993'}>
                Za jej rekonštrukciou stoja skúsení architekti{' '}
                <b>Peter Bouda a Ivan Masár.</b>
              </Text>

              <Box
                sx={{
                  display: 'flex',
                  gap: '2vw',
                  marginTop: '3vh',
                }}
              >
                <Box
                  component={'img'}
                  src={k4img0211extra1}
                  sx={{
                    width: '9vw',
                  }}
                />

                <Box
                  component={'img'}
                  src={k4img0211extra2}
                  sx={{
                    width: '9vw',
                  }}
                />
              </Box>
            </Box>
          ),
          en: (
            <Box>
              <Text secondColor={'#4EF993'}>
                Experienced architects <b>Peter Bouda and Ivan Masár</b> are
                behind the reconstruction.
              </Text>

              <Box
                sx={{
                  display: 'flex',
                  gap: '2vw',
                  marginTop: '3vh',
                }}
              >
                <Box
                  component={'img'}
                  src={k4img0211extra1}
                  sx={{
                    width: '9vw',
                  }}
                />

                <Box
                  component={'img'}
                  src={k4img0211extra2}
                  sx={{
                    width: '9vw',
                  }}
                />
              </Box>
            </Box>
          ),
        },
      },
    },
    {
      Component: Animated3,
      props: {
        navComponent: 1,
        showArrows: true,
        backButtonSrc: '/chapter-4_1?slide=6',
        activeSlide: 10,
        bg: k4img0212,
        mainImg: k4img0212main,
        secondaryImg: k4img0212secondary,
        floatingImages: [
          {
            img: k4img0212float1,
            width: '13.3%',
            bottom: '-8%',
            right: '40%',
          },
          {
            img: k4img0212float2,
            width: '10%',
            top: '-10%',
            right: '15%',
          },
        ],
        year: '2022',
        voiceover: {
          sk: voiceover14sk,
          en: voiceover14en,
        },
        description: {
          sk: 'Exteriér budovy',
          en: 'Exterior of the building',
        },
        source: {
          sk: 'zdroj: www.pradiaren1900.com',
          en: 'source: www.pradiaren1900.com',
        },
        text: {
          sk: (
            <Box>
              <Text secondColor={'#4EF993'}>
                Budova Pradiarne je veľmi pozitívnym príkladom konverzie bývalej
                priemyselnej budovy, kde boli{' '}
                <b>
                  zachované jej pôvodné architektonické kvality a zároveň je
                  tzv. zelenou budovou.{' '}
                </b>
              </Text>

              <Box
                sx={{
                  marginTop: '3vh',
                  ...fonts.Urbanist['24px']({
                    color: '#fff',
                    fontWeight: '500',
                    lineHeight: 30,
                  }),
                  '& a': {
                    color: '#fff',
                    textDecoration: 'unset',
                  },
                }}
              >
                Čo je zelená alebo udržateľná budova sa môžete dozvedieť
                v e-kurze z roku 2023:{' '}
                <a href="https://kurz.detiaarchitektura.sk" target={'_blank'}>
                  https://kurz.detiaarchitektura.sk
                </a>
              </Box>
            </Box>
          ),
          en: (
            <Box>
              <Text secondColor={'#4EF993'}>
                The Spinning mill building is a very positive example of a
                conversion of a former industrial building, where its original
                architectural qualities have been{' '}
                <b>
                  preserved and at the same time it is a so-called green
                  building.
                </b>
              </Text>

              <Box
                sx={{
                  marginTop: '3vh',
                  ...fonts.Urbanist['24px']({
                    color: '#fff',
                    fontWeight: '500',
                    lineHeight: 30,
                  }),
                  '& a': {
                    color: '#fff',
                    textDecoration: 'unset',
                  },
                }}
              >
                You can learn what a green or sustainable building is in the
                2023 e-course:{' '}
                <a href="https://kurz.detiaarchitektura.sk" target={'_blank'}>
                  https://kurz.detiaarchitektura.sk
                </a>
              </Box>
            </Box>
          ),
        },
      },
    },
  ],
}

export default chapter4
