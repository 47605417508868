import React from 'react'
import BackgroundWave from '../../v2/components/BackgroundWave/BackgroundWave'
import background from '../../assets/kurz2024/bg10.png'
import Logo from '../../v2/components/Logo/Logo'
import Box from '@mui/material/Box'
import fonts from '../../styles/fonts'
import Button from '../Buttons/Button1'
import SliderWrapper from '../Slider/SliderWrapper'
import ButtonMenu from '../Buttons/ButtonMenu'
import popup1 from '../../assets/kurz2024/popup3.png'
import explain2 from '../../assets/kurz2024/explain2.png'

const Info3 = ({
  language,
  goToPrevSlide,
  goToNextSlide,
  title1,
  title2,
  isActive,
  fontSize = '96px',
}) => {
  return (
    <SliderWrapper>
      <BackgroundWave
        background={background}
        sx={{
          background: 'linear-gradient(76deg, #D7C7FF 3.42%, #C7B0FF 97.76%)',
        }}
      />

      <ButtonMenu />

      <Logo />

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '83vw',
          }}
        >
          <Box
            component={'img'}
            src={popup1}
            sx={{
              width: '100%',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '1% 1% 2% 1%',
            }}
          >
            <Box
              component={'img'}
              src={explain2}
              sx={{
                width: '22vw',
              }}
            />

            <Box
              sx={{
                ...fonts.Urbanist[fontSize]({
                  color: '#fff',
                  fontWeight: '900',
                }),
                textAlign: 'center',
              }}
            >
              {title2[language]}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '4vw',
                marginTop: '26px',
              }}
            >
              <Button
                variant={2}
                sx={{
                  minWidth: '250px',
                }}
                text={language === 'sk' ? 'späť' : 'back'}
                onClick={() => {
                  goToPrevSlide()
                }}
              />

              <Button
                sx={{
                  minWidth: '250px',
                }}
                text={language === 'sk' ? 'pokračuj' : 'continue'}
                onClick={() => {
                  goToNextSlide()
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SliderWrapper>
  )
}

export default Info3
