import React from 'react'
import ChapterStart from '../v2/components/ChapterStart/ChapterStart'
import Video from '../components/Video/Video'
import video1SK from '../assets/kurz2024/EXPORT/K01_Animacia VIDEO/Kapitola_01_Animacia_SK_lucenec oprava_01.mp4'
import video1EN from '../assets/kurz2024/EXPORT/K01_Animacia VIDEO/Kapitola_01_Animacia_EN_FINAL_04.mp4'

const chapter1 = {
  url: '/chapter-1',
  name: 'chapter1',
  slides: [
    {
      Component: ChapterStart,
      props: {
        variant: 2,
        title1: {
          sk: '1. kapitola',
          en: '1. chapter',
        },
        title2: {
          sk: (
            <>
              <span>Prečo obnovovať</span> staré <br />
              budovy?
            </>
          ),
          en: (
            <>
              <span>Why renovate</span> old <br />
              buildings?
            </>
          ),
        },
      },
    },
    {
      Component: Video,
      props: {
        video: {
          sk: video1SK,
          en: video1EN,
        },
      },
    },
  ],
}

export default chapter1
