import React from 'react'
import Box from '@mui/material/Box'

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}

export default Wrapper
