import strip1Background from '../assets/kurz2024/EXPORT/K05 pás/3 Grécko/lavrion pas exportAsset 13-100.jpg'

import trigger1voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.3.1.mp3'
import trigger1voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.1.mp3'
import k050104trigger01img1 from '../assets/kurz2024/k050104trigger01img1.png'
import k050104trigger01img2 from '../assets/kurz2024/k050104trigger01img2.png'

import trigger2voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.3.2.mp3'
import trigger2voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.2.mp3'
import k050104trigger02img1 from '../assets/kurz2024/k050104trigger02img1.png'
import k050104trigger02img2 from '../assets/kurz2024/k050104trigger02img2.png'
import k050104trigger02img3 from '../assets/kurz2024/k050104trigger02img3.png'
import k050104trigger02img4 from '../assets/kurz2024/k050104trigger02img4.png'

import trigger3voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.3.3.mp3'
import trigger3voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.3.mp3'
import k050104trigger03img1 from '../assets/kurz2024/k050104trigger03img1.png'
import k050104trigger03img2 from '../assets/kurz2024/k050104trigger03img2.png'
import k050104trigger03img3 from '../assets/kurz2024/k050104trigger03img3.png'

import trigger4voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.3.4.mp3'
import trigger4voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.4.mp3'
import k050104trigger04img1 from '../assets/kurz2024/k050104trigger04img1.png'
import k050104trigger04img2 from '../assets/kurz2024/k050104trigger04img2.png'
import k050104trigger04img3 from '../assets/kurz2024/k050104trigger04img3.png'
import k050104trigger04img4 from '../assets/kurz2024/k050104trigger04img4.png'

import trigger5voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.3.5.mp3'
import trigger5voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.5.mp3'
import k050104trigger05img1 from '../assets/kurz2024/k050104trigger05img1.png'
import k050104trigger05img2 from '../assets/kurz2024/k050104trigger05img2.png'
import k050104trigger05img3 from '../assets/kurz2024/k050104trigger05img3.png'

import trigger6voiceoverSK from '../assets/kurz2024/VOICEOVER/SK/VO/5.3.6.mp3'
import trigger6voiceoverEN from '../assets/kurz2024/VOICEOVER/ENG/voiceover AJ/5.3.6.mp3'
import k050104trigger06img1 from '../assets/kurz2024/k050104trigger06img1.png'
import k050104trigger06img2 from '../assets/kurz2024/k050104trigger06img2.png'
import k050104trigger06img3 from '../assets/kurz2024/k050104trigger06img3.png'
import k050104trigger06img4 from '../assets/kurz2024/k050104trigger06img4.png'

import ModalNav3 from '../components/Modal/ModalNav3'

const stripk0504Images = [
  {
    image: strip1Background,
    sx: {
      zIndex: '3',
      position: 'relative',
    },
  },
]

const stripk0504Triggers = [
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers1',
    top: '52%',
    left: '8.7%',
    images: [
      {
        src: k050104trigger01img1,
        sx: {
          width: '65%',
        },
      },
      {
        src: k050104trigger01img2,
        sx: {
          width: '65%',
        },
      },
    ],
    sources: [
      {
        sk: 'zdroj: www.oryktosploutos.net',
        en: 'source: www.oryktosploutos.net',
      },
      {
        sk: 'zdroj: www.thetravel.com',
        en: 'source: www.thetravel.com',
      },
    ],
    voiceover: {
      sk: trigger1voiceoverSK,
      en: trigger1voiceoverEN,
    },
    text: {
      sk: 'Tento príklad z Grécka sme si vybrali preto, že jeho korene siahajú do hlbokej histórie a je spojený so zaujímavým príbehom. Ide totiž o staré banícke mestečko ešte z obdobia antiky. Mesto patrilo k najvýznamnejším prístavom a ťažiť sa tu začalo už tri tisíc rokov pred Kristom. Z neznámych dôvodov sa po klasickom antickom období prestalo s dolovaním olova a najmä striebra, nasledovali storočia bez jedinej aktivity.',
      en: 'We have chosen this example from Greece because its roots go back deep into history and it is linked to an interesting story. It is an old mining town dating back to antiquity. The town was one of the most important ports and mining began as early as three thousand years before Christ. For unknown reasons, after the classical antique period, lead and especially silver mining ceased, and centuries followed without a single activity.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers2',
    top: '3.5%',
    left: '24.2%',
    images: [
      {
        src: k050104trigger02img1,
        sx: {
          width: '63%',
        },
      },
      {
        isLeftAnimation: true,
        src: k050104trigger02img2,
        sx: {
          width: '50%',
          transform: 'translateX(-70%)',
        },
      },
      {
        src: k050104trigger02img3,
        sx: {
          width: '55%',
        },
      },
      {
        src: k050104trigger02img4,
        sx: {
          width: '58%',
        },
      },
    ],
    source: {
      sk: 'zdroj: www.en.ltcp.ntua.gr',
      en: 'source: www.en.ltcp.ntua.gr',
    },
    voiceover: {
      sk: trigger2voiceoverSK,
      en: trigger2voiceoverEN,
    },
    text: {
      sk: 'Až v šesťdesiatych rokoch 19. storočia sa inžinieri rozhodli preskúmať pozostatky baníckej činnosti a boli úspešní. Ťažba sa obnovila a francúzska spoločnosť, ktorá areál napokon prevzala, sa postarala o ostatné. Baňa sa pod ich vedením rozrástla na komplex s 18 pecami a mnohými budovami na čistenie a spracovanie ocele. V tej dobe sa z Lavria stal obrovský podnik s 1200 zamestnancami. Mesto bolo elektrifikované ako jedno z prvých a dokonca doň viedla vtedy jediná železnica v Grécku, ktorá začínala v Aténach.',
      en: 'It was not until the 1860s that engineers decided to investigate the remains of mining activity and were successful. Mining resumed and the French company that eventually took over the site took care of the rest. Under their management, the mine grew into a complex with 18 furnaces and many buildings for cleaning and processing steel. At that time, Lavrion had become a huge enterprise with 1,200 employees. The town was one of the first to be electrified and even had the only railway in Greece at the time, which started in Athens.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers3',
    top: '25.5%',
    left: '42%',
    images: [
      {
        src: k050104trigger03img1,
        sx: {
          width: '53%',
        },
      },
      {
        src: k050104trigger03img2,
        sx: {
          width: '60%',
          transform: 'translateX(60%)',
        },
      },
      {
        isLeftAnimation: true,
        src: k050104trigger03img3,
        sx: {
          width: '60%',
          transform: 'translateX(-60%)',
        },
      },
    ],
    sources: [
      {
        sk: 'zdroj: www.en.ltcp.ntua.gr',
        en: 'source: www.en.ltcp.ntua.gr',
      },
      {
        sk: 'zdroj: www.freepik.com',
        en: 'source: www.freepik.com',
      },
      {
        sk: 'zdroj: www.freepik.com',
        en: 'source: www.freepik.com',
      },
    ],
    voiceover: {
      sk: trigger3voiceoverSK,
      en: trigger3voiceoverEN,
    },
    text: {
      sk: 'Okolo baní sa prirodzene vytvorili nové robotnícke osady, postavili sa tu školy, nemocnice, kostol a obchody. Lavrion bol s desať tisíc obyvateľmi vo svojom rozkvete. Inak to už bolo s okolitou prírodou. Intenzívna ťažba a priemyselné spracovanie ocele ju položili na kolená. Toto neudržateľné znečisťovanie spolu s nepokojným 20. storočím plným vojen a kríz priniesli nakoniec veľkej spoločnosti zánik. Po vyše sto rokoch prevádzky (1867 – 1989) sa začalo uvažovať čo s opusteným areálom?',
      en: "Naturally, new workers' settlements developed around the mines, and schools, hospitals, a church and shops were built. Lavrion was in its heyday with ten thousand inhabitants. It was a different story with the surrounding countryside. Intensive mining and industrial steel processing had brought it to its knees. This unsustainable pollution, coupled with a turbulent 20th century full of wars and crises, eventually brought about the demise of a great society. After more than a hundred years of operation (1867-1989), the question of what to do with the abandoned site was raised.",
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers4',
    top: '25.5%',
    left: '58%',
    images: [
      {
        src: k050104trigger04img1,
        sx: {
          width: '53%',
        },
      },
      {
        src: k050104trigger04img2,
        sx: {
          width: '52%',
        },
      },
      {
        isLeftAnimation: true,
        src: k050104trigger04img3,
        sx: {
          width: '60%',
          transform: 'translateX(-60%)',
        },
      },
      {
        src: k050104trigger04img4,
        sx: {
          width: '60%',
          transform: 'translateX(55%)',
        },
      },
    ],
    source: {
      sk: 'zdroj: www.en.ltcp.ntua.gr',
      en: 'source: www.en.ltcp.ntua.gr',
    },
    voiceover: {
      sk: trigger4voiceoverSK,
      en: trigger4voiceoverEN,
    },
    text: {
      sk: 'Nakoniec sa rozhodlo, že celý bývalý banícky komplex dajú do rúk Národnej technickej univerzity v Athénach. Študenti pod vedením pedagógov a za silnej podpory pôvodných obyvateľov sa pustili do roboty. Technológie, ktoré v budovách ostali, stavby samotné, celé hektáre zastavanej plochy sa teda stali výzvou pre pracovné tímy, ktoré v roku 1989 začali s obnovou baníckeho industriálneho dedičstva v meste Lavrion.',
      en: 'Eventually it was decided to put the entire former mining complex in the hands of the National Technical University of Athens. The students, under the guidance of teachers and with the strong support of the original inhabitants, set to work. The technology that remained in the buildings, the buildings themselves, and entire hectares of built-up area thus became a challenge for the work teams that in 1989 began the restoration of the mining industrial heritage in Lavrion.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers5',
    top: '48%',
    left: '74%',
    images: [
      {
        src: k050104trigger05img1,
        sx: {
          width: '60%',
          transform: 'translateX(60%)',
        },
      },
      {
        isLeftAnimation: true,
        src: k050104trigger05img2,
        sx: {
          width: '55%',
          transform: 'translateX(-70%)',
        },
      },
      {
        src: k050104trigger05img3,
        sx: {
          width: '65%',
        },
      },
    ],
    source: {
      sk: 'zdroj: www.en.ltcp.ntua.gr',
      en: 'source: www.en.ltcp.ntua.gr',
    },
    voiceover: {
      sk: trigger5voiceoverSK,
      en: trigger5voiceoverEN,
    },
    text: {
      sk: 'Najdôležitejšou úlohou bolo silne znečistené miesto dekontaminovať. Zároveň sa spustil interdisciplinárny výskum – spolupracovali všetky fakulty technickej univerzity. Celkovo 42 budov v areáli bolo preskúmaných do najmenších detailov. Každej bol vytvorený podrobný „občiansky preukaz“, ktorý obsahoval skice, axonometrické detaily nezvyklých konštrukcií, popis stavu, škôd a metódy obnovy.',
      en: 'The most important task was to decontaminate the heavily polluted site. At the same time, interdisciplinary research was launched - all the faculties of the technical university worked together. A total of 42 buildings on the site were examined in minute detail. A detailed "ID card" was created for each, which included sketches, axonometric details of the unusual structures, a description of the condition, the damage, and the methods of restoration.',
    },
  },
  {
    ModalNavComponent: ModalNav3,
    id: 'strip1Triggers6',
    top: '67%',
    left: '92%',
    images: [
      {
        src: k050104trigger06img1,
        sx: {
          width: '65%',
        },
      },
      {
        isLeftAnimation: true,
        src: k050104trigger06img2,
        sx: {
          width: '60%',
          transform: 'translateX(-60%)',
        },
      },
      {
        src: k050104trigger06img3,
        sx: {
          width: '67%',
        },
      },
      {
        src: k050104trigger06img4,
        sx: {
          width: '60%',
          transform: 'translateX(60%)',
        },
      },
    ],
    source: {
      sk: 'zdroj: kinoussis.gr',
      en: 'source: kinoussis.gr',
    },
    voiceover: {
      sk: trigger6voiceoverSK,
      en: trigger6voiceoverEN,
    },
    text: {
      sk: 'Až potom sa pristúpilo k samotnej rekonštrukcii. Tá vo väčšine prípadov zahŕňala prídavnú vnútornú konštrukciu z ocele, akúsi vnútornú kostru, ktorá nesie strechu. V interiéri vytvára zaujímavý kontrast tehlových stien a sivých kovových prvkov. Rekonštrukciou sa do pôvodných priemyselných budov vrátil život a z bývalého industriálneho komplexu sa stalo výskumné, edukačné, obchodné a kultúrne centrum.',
      en: 'Only then did the actual reconstruction proceed. This in most cases involved an additional internal structure of steel, a kind of internal skeleton, supporting the roof. Inside, it creates an interesting contrast between the brick walls and the grey metal elements. The renovation has brought life back into the original industrial buildings and turned the former industrial complex into a research, educational, commercial and cultural centre.',
    },
  },
]

export { stripk0504Images, stripk0504Triggers }
