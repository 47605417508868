import React from 'react'
import Typography from "@mui/material/Typography";
import fonts from "../../styles/fonts";

const Source = ({children, sx}) => {
    return (
        <Typography
            sx={{
                position: 'fixed',
                bottom: '20px',
                left: '20px',
                ...fonts.Rubik['18px']({
                    color: '#3AD3A4',
                }),
                ...sx,
            }}
        >
            FOTO: {children}
        </Typography>
    )
}

export default Source
