import React from 'react'
import Box from '@mui/material/Box'
import { pulseAnimation, wave2 } from '../../styles/animations'
import useSound from 'use-sound'
import hoverSound from '../../assets/sounds/1_Button_Hover_Sound.wav'
import clickSound from '../../assets/sounds/2_Ikon_Click.wav'
import triggerimg from '../../assets/kurz2024/trigger.png'

const StripTrigger = ({ index, top, left, onClick }) => {
  const [playHover] = useSound(hoverSound, {
    interrupt: true,
  })
  const [playClick] = useSound(clickSound, {
    interrupt: true,
  })

  return (
    <Box
      sx={{
        top,
        left,
        position: 'absolute',
        zIndex: '18',
        cursor: 'pointer',
        width: '6vw',
        animation: `${wave2} 6s ease-in-out infinite alternate`,
        animationDelay: `${index * 1000}ms`,
      }}
      onMouseEnter={playHover}
      onClick={(e) => {
        playClick()
        onClick(e)
      }}
    >
      <Box
        component={'img'}
        src={triggerimg}
        sx={{
          width: '100%',
        }}
      />
    </Box>
  )
}

export default StripTrigger
