import React from 'react'
import Box from '@mui/material/Box'
import { wave2 } from '../../styles/animations'

const StripImages = ({
  isSwitched,
  images,
  cloudsRef,
  parallax1,
  parallax2,
}) => {
  const getRef = (image) => {
    if (image.clouds) {
      return cloudsRef
    }

    if (image.parallax1) {
      return parallax1
    }

    if (image.parallax2) {
      return parallax2
    }
  }

  return images.map((image, index) =>
    image.showOnSwitch ? (
      <Box
        ref={getRef(image)}
        key={index}
        component={'img'}
        src={image.image}
        sx={{
          height: '100%',
          position: 'absolute',
          bottom: getRef(image) ? '5%' : '0',
          left: '0',
          opacity: isSwitched ? '1' : '0',
          transition: 'opacity 500ms ease 0s',
          scale: getRef(image) ? '1.1' : '1',
          animation: image.wave
            ? `${wave2} 6s ease-in-out infinite`
            : undefined,
          animationDirection: image.wave ? 'alternate' : undefined,
          ...image.sx,
        }}
      />
    ) : (
      <Box
        ref={getRef(image)}
        key={index}
        component={'img'}
        src={image.image}
        sx={{
          height: '100%',
          position: 'absolute',
          bottom: getRef(image) ? '5%' : '0',
          left: getRef(image) ? '5%' : '0',
          scale: getRef(image) ? '1.1' : '1',
          ...image.sx,
        }}
      />
    )
  )
}

export default StripImages
