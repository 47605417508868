import React, { useEffect, useRef, useState } from 'react'
import SliderWrapper from '../Slider/SliderWrapper'
import Box from '@mui/material/Box'
import StripImages from './StripImages'
import StripTriggers from './StripTriggers'
import Modal from '../Modal2/Modal'
import ModalWrapper from '../Modal2/ModalWrapper'
import useSound from 'use-sound'
import stripesSound from '../../assets/kurz2024/EXPORT/pás hudba v pozadi.mp3'
import magicSound from '../../assets/_Sfx/__Compress/Magic.mp3'
import ButtonLeft from '../../components/Buttons/ButtonLeft'
import ButtonRight from '../../components/Buttons/ButtonRight'
import ButtonMenu from '../../components/Buttons/ButtonMenu'
import header1 from '../../assets/kurz2024/header1.png'
import fonts from '../../styles/fonts'
import Lottie from 'react-lottie'
import explain4 from '../../assets/kurz2024/explain4.png'
import explain4EN from '../../assets/kurz2024/explain4EN.png'

const Strip2 = ({
  isActive,
  language,
  images,
  triggers,
  animations,
  chapter,
  goToPrevSlide,
  goToNextSlide,
  scrollHandler,
  title,
  header,
  animationLottie,
}) => {
  const [isSwitched, setIsSwitched] = useState(false)
  const [isShownModal, setIsShownModal] = useState(false)
  const buttonLeftRef = useRef()
  const buttonRightRef = useRef()
  const cloudsRef = useRef()
  const parallax1 = useRef()
  const parallax2 = useRef()
  const modalRef = useRef()

  const [isLoaded, setIsLoaded] = useState(false)
  const [playVoiceover, { stop }] = useSound(stripesSound, {
    loop: true,
    interrupt: true,
    onload: () => setIsLoaded(true),
    volume: 0.1,
  })

  const [playMagic, { stop: stopMagic }] = useSound(magicSound, {
    interrupt: true,
    volume: 0.1,
  })

  const onPlay = () => {
    playVoiceover()
  }

  const onStop = () => {
    stop()
    stopMagic()

    modalRef.current?.onStop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()

        setIsShownModal(false)

        if (isSwitched) {
          playMagic()
        }
      } else {
        onStop()
        stopMagic()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  useEffect(() => {
    isSwitched ? playMagic() : stopMagic()
  }, [isSwitched])

  const onButtonLeftClick = () => {
    return goToPrevSlide()
  }

  const onButtonRightClick = () => {
    return goToNextSlide()
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <SliderWrapper flexCenter>
      {/*<Logo*/}
      {/*  color={'#112232'}*/}
      {/*  sx={{*/}
      {/*    zIndex: '20',*/}
      {/*  }}*/}
      {/*/>*/}

      <ButtonMenu
        variant={1}
        sx={{
          zIndex: '20',
        }}
      />

      <ButtonLeft
        variant={1}
        buttonRef={buttonLeftRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonLeftClick}
      />

      <ButtonRight
        variant={1}
        buttonRef={buttonRightRef}
        isShown
        sx={{
          zIndex: '20',
        }}
        onClick={onButtonRightClick}
      />

      <Box
        component={'img'}
        src={language === 'sk' ? explain4 : explain4EN}
        sx={{
          position: 'absolute',
          top: '22px',
          right: '22px',
          width: '15.6vw',
          zIndex: '20',
        }}
      />

      {header && (
        <Box
          sx={{
            position: 'absolute',
            top: '10vh',
            left: '50%',
            translate: '-50% 0',
            width: '30vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '19',
          }}
        >
          <Box
            component={'img'}
            src={header1}
            sx={{
              width: '100%',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              ...fonts.Urbanist['36px']({
                color: '#fff',
                fontWeight: '900',
                lineHeight: 40,
              }),
              textAlign: 'center',
              marginTop: '-6px',
            }}
          >
            {header[language]}
          </Box>
        </Box>
      )}

      {/*<StripSwitch*/}
      {/*  chapter={chapter}*/}
      {/*  isSwitched={isSwitched}*/}
      {/*  setIsSwitched={setIsSwitched}*/}
      {/*/>*/}

      <ModalWrapper
        isShownModal={isShownModal}
        sx={{
          backdropFilter: 'blur(8.75px)',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: '#4EF993',
            opacity: '0.3',
          }}
        />

        {Boolean(isShownModal) && (
          <Modal
            key={isShownModal.id}
            modalRef={modalRef}
            language={language}
            chapter={chapter}
            isShownModal={isShownModal}
            setIsShownModal={setIsShownModal}
          />
        )}
      </ModalWrapper>

      {animationLottie && (
        <Box
          sx={{
            pointerEvents: 'none',
            '& div': {
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100% !important',
              height: '100% !important',
            },
            '& svg': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              translate: '-50% -50%',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          }}
        >
          {/*<ReactPlayer url={animation[language]} playing={isActive} loop muted />*/}
          <Lottie
            options={defaultOptions}
            // isStopped={!isActive}
            // isPaused={!isActive}
          />
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          position: 'relative',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {images && (
          <StripImages
            isSwitched={isSwitched}
            images={images}
            cloudsRef={cloudsRef}
            parallax1={parallax1}
            parallax2={parallax2}
          />
        )}

        <StripTriggers
          chapter={chapter}
          triggers={triggers}
          setIsShownModal={setIsShownModal}
        />
      </Box>
    </SliderWrapper>
  )
}

export default Strip2
