import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import ButtonLeft from '../../components/Buttons/ButtonLeft'
import ButtonRight from '../../components/Buttons/ButtonRight'
import ButtonPlay from '../Buttons/ButtonPlay'
import ButtonRead from '../../components/Buttons/ButtonRead'
import SliderWrapper from '../../components/Slider/SliderWrapper'
import ButtonMenu from '../../components/Buttons/ButtonMenu'
import BackgroundAlbum from '../../components/Background/BackgroundAlbum'
import Typography from '@mui/material/Typography'
import fonts from '../../styles/fonts'
import ButtonClose from '../../components/Buttons/ButtonClose'
import ReactPlayer from 'react-player'
import useSound from 'use-sound'
import ButtonPause from '../Buttons/ButtonPause'
import BookNavigation from './BookNavigation'
import Lottie from 'react-lottie'

const Book2 = ({
  animation,
  animation1,
  isActive,
  chapter,
  albumBase,
  bg,
  language,
  goToPrevSlide,
  goToNextSlide,
  voiceover,
  text,
  count,
  activeSlide,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
}) => {
  const [isShownOverlay, setIsShownOverlay] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playVoiceover, { stop, pause }] = useSound(voiceover?.[language], {
    onload: () => {
      setIsLoaded(true)
    },
    onend: () => {
      setIsPlaying(false)
    },
  })
  // const playOnLoad = useRef(false)

  const onPlay = () => {
    // if (!isLoaded) {
    //   playOnLoad.current = true
    //   setIsPlaying(true)
    //
    //   return
    // }
    setIsPlaying(true)
    playVoiceover()
  }

  const onPause = () => {
    setIsPlaying(false)
    pause()
  }

  const onStop = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    if (isLoaded) {
      if (isActive) {
        onPlay()
      } else {
        onStop()
      }
    }

    return () => onStop()
  }, [isLoaded, isActive])

  // useEffect(() => {
  //   if (playOnLoad.current) {
  //     onPlay()
  //
  //     playOnLoad.current = false
  //   }
  // }, [isLoaded])

  useEffect(() => {
    setIsLoaded(false)
    onStop()
  }, [language])

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animation1[language],
  }

  return (
    <SliderWrapper flexCenter>
      <BackgroundAlbum />

      <ButtonMenu
        sx={{
          zIndex: '2',
        }}
      />

      {voiceover && (
        <>
          <ButtonPlay
            chapter={chapter}
            sx={{
              zIndex: '20',
              opacity: !isPlaying ? '1' : '0',
              pointerEvents: isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPlay}
          />

          <ButtonPause
            chapter={chapter}
            sx={{
              zIndex: '20',
              opacity: isPlaying ? '1' : '0',
              pointerEvents: !isPlaying ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={onPause}
          />
        </>
      )}

      {text && (
        <>
          <ButtonRead
            chapter={chapter}
            sx={{
              zIndex: '20',
              opacity: !isShownOverlay ? '1' : '0',
              pointerEvents: isShownOverlay ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={() => setIsShownOverlay(!isShownOverlay)}
          />

          <ButtonClose
            chapter={chapter}
            sx={{
              zIndex: '20',
              opacity: isShownOverlay ? '1' : '0',
              pointerEvents: !isShownOverlay ? 'none' : 'all',
              transition: 'opacity 500ms ease 0s',
            }}
            onClick={() => setIsShownOverlay(!isShownOverlay)}
          />
        </>
      )}

      <ButtonLeft isShown onClick={goToPrevSlide} />

      <ButtonRight isShown onClick={goToNextSlide} />

      <BookNavigation
        chapter={chapter}
        count={count}
        activeSlide={activeSlide}
      />

      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          component={'img'}
          src={albumBase}
          sx={{
            maxWidth: '100vw',
            maxHeight: '100vh',
          }}
        />

        {text1 && (
          <Box
            sx={{
              ...fonts.MarkerFelt['45px'](),
              position: 'absolute',
              top: '18%',
              left: '31%',
              translate: '-50% -50%',
              zIndex: '1',
              background: '#F0F',
              padding: '0 10px',
              textAlign: 'center',
            }}
          >
            {text1[language]}
          </Box>
        )}

        {text2 && (
          <Box
            sx={{
              ...fonts.MarkerFelt['24px'](),
              position: 'absolute',
              top: '18%',
              left: '70%',
              translate: '-50% -50%',
              zIndex: '1',
              textAlign: 'center',
            }}
          >
            {text2[language]}
          </Box>
        )}

        {text3 && (
          <Box
            sx={{
              ...fonts.MarkerFelt['45px'](),
              position: 'absolute',
              top: '25%',
              left: '31%',
              translate: '-50% -50%',
              zIndex: '1',
              background: '#F0F',
              padding: '0 10px',
              textAlign: 'center',
            }}
          >
            {text3[language]}
          </Box>
        )}

        {text4 && (
          <Box
            sx={{
              ...fonts.MarkerFelt['24px'](),
              position: 'absolute',
              top: '64%',
              left: '40%',
              translate: '-50% -50%',
              zIndex: '1',
              textAlign: 'center',
            }}
          >
            {text4[language]}
          </Box>
        )}

        {text5 && (
          <Box
            sx={{
              ...fonts.MarkerFelt['24px'](),
              position: 'absolute',
              top: '58%',
              left: '62%',
              translate: '-50% -50%',
              zIndex: '1',
              textAlign: 'center',
            }}
          >
            {text5[language]}
          </Box>
        )}

        {text6 && (
          <Box
            sx={{
              ...fonts.MarkerFelt['22px'](),
              position: 'absolute',
              top: '39%',
              left: '37%',
              translate: '-50% -50%',
              zIndex: '1',
              textAlign: 'center',
            }}
          >
            {text6[language]}
          </Box>
        )}

        {text7 && (
          <Box
            sx={{
              ...fonts.MarkerFelt['24px'](),
              position: 'absolute',
              top: '65%',
              left: '62%',
              translate: '-50% -50%',
              zIndex: '1',
              textAlign: 'center',
            }}
          >
            {text7[language]}
          </Box>
        )}
      </Box>

      {isActive && (
        <Box
          sx={{
            pointerEvents: 'none',
            '& div': {
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100% !important',
              height: '100% !important',
            },
            '& svg': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              translate: '-50% -50%',
              width: '90% !important',
              height: '100%',
              objectFit: 'contain',
            },
          }}
        >
          {/*<ReactPlayer url={animation[language]} playing={isActive} loop muted />*/}
          <Lottie
            options={defaultOptions}
            isStopped={!isActive}
            isPaused={!isActive}
          />
        </Box>
      )}

      {text && (
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            opacity: isShownOverlay ? '1' : '0',
            pointerEvents: !isShownOverlay ? 'none' : 'all',
            transition: 'opacity 500ms ease 0s',
            background:
              'linear-gradient(76deg, rgba(105, 54, 197, 0.95) 3.42%, rgba(92, 42, 147, 0.95) 97.76%)',
            zIndex: '19',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              ...fonts.Urbanist['48px']({
                color: '#fff',
                fontWeight: '500',
                lineHeight: 57,
              }),
              textAlign: 'center',
              maxWidth: '75%',
              '& b': {
                color: '#fff',
              },
            }}
          >
            {text[language]}
          </Typography>
        </Box>
      )}
    </SliderWrapper>
  )
}

export default Book2
