import React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useAppContext } from '../../contexts/AppContext'
import ButtonLangSK from '../Buttons/ButtonLangSK'
import ButtonLangEN from '../Buttons/ButtonLangEN'
import MenuSection from './MenuSection'
import { useLanguageContext } from '../../contexts/LanguageContext'
import background from '../../assets/kurz2024/bgmenu.png'
import BackgroundWave from '../../v2/components/BackgroundWave/BackgroundWave'
import ButtonMenu from '../Buttons/ButtonMenu'
import Logo from '../../v2/components/Logo/Logo'
import PersonAnimation from '../../v2/components/PersonAnimation/PersonAnimation'
import ButtonHome from '../Buttons/ButtonHome'
import ButtonHome2 from '../Buttons/ButtonHome2'

const Menu = () => {
  const navigate = useNavigate()
  const { isShownMenu, setIsShownMenu } = useAppContext()

  const { language, setLanguage } = useLanguageContext()

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '100',
        opacity: isShownMenu ? '1' : '0',
        pointerEvents: !isShownMenu ? 'none' : 'all',
        transition: 'opacity 500ms ease 0s',
        overflow: 'hidden',
      }}
    >
      <BackgroundWave disabled background={background} />

      <Logo />

      <ButtonMenu type={'close'} />

      <ButtonHome2
        onClick={() => {
          setIsShownMenu(false)
          navigate('chapter-0?slide=0')
        }}
      />

      {/*{language === 'sk' ? (*/}
      {/*  <ButtonLangEN*/}
      {/*    onClick={() => {*/}
      {/*      setIsShownMenu(false)*/}
      {/*      setLanguage('en')*/}
      {/*    }}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <ButtonLangSK*/}
      {/*    onClick={() => {*/}
      {/*      setIsShownMenu(false)*/}
      {/*      setLanguage('sk')*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2% 7%',
          gap: '5vw',
          height: '100%',
        }}
      >
        <MenuSection
          key={'MenuSection1'}
          buttons={[
            {
              id: '1',
              text1: {
                sk: '1. kapitola',
                en: '1. chapter',
              }[language],
              text2: {
                sk: 'Prečo obnovovať staré budovy?',
                en: 'Why renovate old buildings?',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-1?slide=0')
              },
            },
            {
              id: '2',
              text1: {
                sk: '2. kapitola',
                en: '2. chapter',
              }[language],
              text2: {
                sk: 'Vysvetlenie pojmov',
                en: 'Explanation of terms',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-2?slide=0')
              },
            },
            {
              id: '3',
              text1: {
                sk: '3. kapitola',
                en: '3. chapter',
              }[language],
              text2: {
                sk: (
                  <>
                    Kultúrne, architektonické a stavebné <br />
                    dedičstvo Slovenska
                  </>
                ),
                en: (
                  <>
                    Cultural, architectural and building <br /> heritage of
                    Slovakia
                  </>
                ),
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-3?slide=0')
              },
            },
            {
              id: '4',
              text1: {
                sk: '4. kapitola',
                en: '4. chapter',
              }[language],
              text2: {
                sk: (
                  <>
                    Príklady architektonických <br />  konverzií zo Slovenska
                  </>
                ),
                en: (
                  <>
                    Examples of architectural <br /> conversions from Slovakia
                  </>
                ),
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-4_1?slide=0')
              },
            },
          ]}
        />

        <MenuSection
          key={'MenuSection2'}
          buttons={[
            {
              id: '5',
              text1: {
                sk: '5. kapitola',
                en: '5. chapter',
              }[language],
              text2: {
                sk: (
                  <>
                    Príklady architektonických <br />  konverzií zo zahraničia
                  </>
                ),
                en: (
                  <>
                    Examples of architectural <br /> conversions from abroad
                  </>
                ),
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-5?slide=0')
              },
            },
            {
              id: '6',
              text1: {
                sk: '6. kapitola',
                en: '6. chapter',
              }[language],
              text2: {
                sk: 'Veľký kvíz',
                en: 'Big Quiz',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/quiz?slide=0')
              },
            },
            {
              id: '7',
              text2: {
                sk: 'Výtvarná súťaž',
                en: 'Art competition',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-7?slide=0')
              },
            },
            {
              id: '8',
              text2: {
                sk: 'Partneri',
                en: 'Partners',
              }[language],
              onClick: () => {
                setIsShownMenu(false)
                navigate('/chapter-8?slide=0')
              },
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default Menu
